export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  /** Month and year scalar type */
  MonthAndYear: string;
  /** The javascript `Date` as integer. Type represents date and time as number of milliseconds from start of UNIX epoch. */
  Timestamp: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: File | Blob;
  Void: any;
};

export type ActiveUsersInsightMetric = {
  __typename?: 'ActiveUsersInsightMetric';
  id: Scalars['ID'];
  activeUserCount: Scalars['Int'];
};

export type AdminBulkCompleteManualCampaignOfferInputItem = {
  organizationId: Scalars['ID'];
  userId?: Maybe<Scalars['ID']>;
  userExternalEmployeeId?: Maybe<Scalars['String']>;
  userWorkEmail?: Maybe<Scalars['String']>;
  userPersonalEmail?: Maybe<Scalars['String']>;
  campaignId: Scalars['ID'];
  numCompleted: Scalars['Int'];
};

export type AdminBulkCompleteManualCampaignOffersResponse = {
  __typename?: 'AdminBulkCompleteManualCampaignOffersResponse';
  numCompleted: Scalars['Int'];
  errors: Array<Scalars['String']>;
};

export type AdminBulkCreateOrUpdateRewardOfferInputItem = {
  organizationId: Scalars['ID'];
  userId?: Maybe<Scalars['ID']>;
  userExternalEmployeeId?: Maybe<Scalars['String']>;
  userWorkEmail?: Maybe<Scalars['String']>;
  userPersonalEmail?: Maybe<Scalars['String']>;
  externalOfferId: Scalars['String'];
  /**
   * Must be either "yes" or "no".
   *
   * If "yes", the offer will be revoked and the card will no longer be visible to the user.
   */
  revoked?: Maybe<Scalars['String']>;
  /**
   * Available options:
   * - `discrete_progress`
   * - `one_time`
   * - `unlimited`
   */
  structureType: Scalars['String'];
  /**
   * If `structureType` is `discrete_progress`, this must be
   * a comma-delimited list of numbers to award at each step
   */
  structureDiscreteProgressSteps?: Maybe<Scalars['String']>;
  /** If `structureType` is `one_time`, this is required */
  structureOneTimePointValue?: Maybe<Scalars['Int']>;
  /** If `structureType` is `unlimited`, this is required */
  structureUnlimitedPointValue?: Maybe<Scalars['Int']>;
  /** Number of times the offer should be marked as completed. */
  numCompleted?: Maybe<Scalars['Int']>;
  cardTitle: Scalars['String'];
  cardPointsTextOverride?: Maybe<Scalars['String']>;
  cardGraphicKey: RewardOfferCardGraphicKey;
  cardButtonLabel: Scalars['String'];
  /**
   * Available options:
   * - `modal`
   */
  actionType: Scalars['String'];
  /** If action type is 'modal', this is required */
  actionModalContentsHtml?: Maybe<Scalars['String']>;
  /**
   * Rank is used to sort the offers in the UI, and extract ranked communication
   * categories when sending out emails, so we can display most important/relevant
   * reward offer cards first.
   */
  rank?: Maybe<Scalars['Int']>;
  communicationCategory?: Maybe<Scalars['String']>;
};

export type AdminBulkCreateOrUpdateRewardOffersResponse = {
  __typename?: 'AdminBulkCreateOrUpdateRewardOffersResponse';
  numGranted: Scalars['Int'];
  numRevoked: Scalars['Int'];
  numCompleted: Scalars['Int'];
  numCardConfigUpdated: Scalars['Int'];
  numActionUpdated: Scalars['Int'];
  errors: Array<Scalars['String']>;
};

export type AdminBulkCreateOrUpdateVideoOfferInputItem = {
  organizationId: Scalars['ID'];
  userId?: Maybe<Scalars['ID']>;
  userExternalEmployeeId?: Maybe<Scalars['String']>;
  userWorkEmail?: Maybe<Scalars['String']>;
  userPersonalEmail?: Maybe<Scalars['String']>;
  externalId: Scalars['String'];
  videoId: Scalars['ID'];
  /**
   * Must be either "yes" or "no".
   *
   * If "yes", the video offer will be deleted, and they can no longer earn points for watching.
   */
  deleted?: Maybe<Scalars['String']>;
  pointValue: Scalars['Int'];
};

export type AdminBulkCreateOrUpdateVideoOffersResponse = {
  __typename?: 'AdminBulkCreateOrUpdateVideoOffersResponse';
  numCreated: Scalars['Int'];
  numDeleted: Scalars['Int'];
  errors: Array<Scalars['String']>;
};

export type AdminBulkCreateRewardsUserError = {
  __typename?: 'AdminBulkCreateRewardsUserError';
  index: Scalars['Int'];
  error: Scalars['String'];
};

export type AdminBulkCreateRewardsUserPayload = {
  externalEmployeeId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  personalEmail?: Maybe<Scalars['String']>;
  personalPhoneNumber?: Maybe<Scalars['String']>;
  workEmail?: Maybe<Scalars['String']>;
  workPhoneNumber?: Maybe<Scalars['String']>;
  branchId?: Maybe<Scalars['String']>;
  managerId?: Maybe<Scalars['String']>;
};

export type AdminBulkCreateRewardsUserResponse = {
  __typename?: 'AdminBulkCreateRewardsUserResponse';
  results: Array<AdminBulkCreateRewardsUserResult>;
  numCreated: Scalars['Int'];
};

export type AdminBulkCreateRewardsUserResult = AdminBulkCreateRewardsUserError | AdminBulkCreateRewardsUserSuccess;

export type AdminBulkCreateRewardsUserSuccess = {
  __typename?: 'AdminBulkCreateRewardsUserSuccess';
  index: Scalars['Int'];
  userId: Scalars['String'];
};

export type AdminBulkUpdateRewardsUserError = {
  __typename?: 'AdminBulkUpdateRewardsUserError';
  error: Scalars['String'];
  index: Scalars['Int'];
};

export type AdminBulkUpdateRewardsUserPayload = {
  /** Must be provided if `externalEmployeeId` is not */
  userId?: Maybe<Scalars['ID']>;
  /** Must be provided if `userId` is not */
  externalEmployeeId?: Maybe<Scalars['ID']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  personalEmail?: Maybe<Scalars['String']>;
  personalPhoneNumber?: Maybe<Scalars['String']>;
  workEmail?: Maybe<Scalars['String']>;
  workPhoneNumber?: Maybe<Scalars['String']>;
  branchId?: Maybe<Scalars['String']>;
  managerId?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type AdminBulkUpdateRewardsUserResponse = {
  __typename?: 'AdminBulkUpdateRewardsUserResponse';
  results: Array<AdminBulkUpdateRewardsUserError>;
  numUpdated: Scalars['Int'];
};

export type AdminGetRewardOfferStatsNumUsersByOfferCount = {
  __typename?: 'AdminGetRewardOfferStatsNumUsersByOfferCount';
  numNonRevokedOffers: Scalars['Int'];
  numUsers: Scalars['Int'];
};

export type AdminGetRewardOfferStatsResponse = {
  __typename?: 'AdminGetRewardOfferStatsResponse';
  numUsersByOfferCount: Array<AdminGetRewardOfferStatsNumUsersByOfferCount>;
};

export type AdminListRewardOffersResponse = {
  __typename?: 'AdminListRewardOffersResponse';
  total: Scalars['Int'];
  items: Array<RewardVideoOffer>;
};

export type AdminListRewardsOrganizationsResponse = {
  __typename?: 'AdminListRewardsOrganizationsResponse';
  items: Array<RewardsOrganization>;
};

export type AdminlistRewardOffersResponse = {
  __typename?: 'AdminlistRewardOffersResponse';
  total: Scalars['Int'];
  items: Array<RewardOffer>;
};

export type AdminsUsingRecognitionInsightMetric = {
  __typename?: 'AdminsUsingRecognitionInsightMetric';
  id: Scalars['ID'];
  adminsUsingRecognitionCount: Scalars['Int'];
};

export type AlayaCareCaribouUserSync = {
  __typename?: 'AlayaCareCaribouUserSync';
  id: Scalars['ID'];
  enabled: Scalars['Boolean'];
  filters: AlayaCareCaribouUserSyncFilters;
};

export type AlayaCareCaribouUserSyncFilterOption = {
  __typename?: 'AlayaCareCaribouUserSyncFilterOption';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type AlayaCareCaribouUserSyncFilters = {
  __typename?: 'AlayaCareCaribouUserSyncFilters';
  departments: Array<AlayaCareCaribouUserSyncFilterOption>;
  branches: Array<AlayaCareCaribouUserSyncFilterOption>;
  groups: Array<AlayaCareCaribouUserSyncFilterOption>;
  roles: Array<AlayaCareCaribouUserSyncFilterOption>;
  jobTitles: Array<Maybe<Scalars['String']>>;
  emailDomains: Array<Maybe<Scalars['String']>>;
};

export type AlayaCareIntegration = {
  __typename?: 'AlayaCareIntegration';
  id: Scalars['ID'];
  enabled: Scalars['Boolean'];
  userAutoSyncs: Array<AlayaCareCaribouUserSync>;
};

export enum AppName {
  Caribou = 'CARIBOU',
  CaribouPro = 'CARIBOU_PRO'
}

export type ApplicationSubmission = {
  __typename?: 'ApplicationSubmission';
  id: Scalars['ID'];
  submittedAt: Scalars['Timestamp'];
  jobPosting: JobPosting;
  referredByUser: RewardsUser;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  preferredName?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  residingCity?: Maybe<Scalars['String']>;
  hasDriversLicenseAndAccessToCar?: Maybe<JobApplicationDriversLicenseAnswer>;
  yearsOfExperience?: Maybe<JobApplicationYearsOfExperienceAnswer>;
  yesNoAnswers?: Maybe<Array<ApplicationSubmissionYesNoAnswer>>;
};

export type ApplicationSubmissionYesNoAnswer = {
  __typename?: 'ApplicationSubmissionYesNoAnswer';
  fieldId: Scalars['String'];
  question: Scalars['String'];
  answer: JobPostingYesNoFieldAnswer;
};

/** Deprecated - use RecognitionPoints instead */
export type AwardedBonusPoints = {
  __typename?: 'AwardedBonusPoints';
  id: Scalars['ID'];
  rewardsStructureId: Scalars['ID'];
  sentToRewardsUser: RewardsUser;
  /** @deprecated Field no longer supported */
  sentByAdminUser?: Maybe<RewardsAdminUser>;
  pointsAwarded: Scalars['Int'];
  from?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  defaultMessage?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['Timestamp']>;
  status: BonusPointsStatus;
  category?: Maybe<RewardsBonusPointCategory>;
  mustBeClaimed: Scalars['Boolean'];
  claimedAt?: Maybe<Scalars['Timestamp']>;
  reminders: Array<BonusPointsReminder>;
  scheduledToSendOn?: Maybe<Scalars['Date']>;
};

export type BaseDraw = {
  id: Scalars['ID'];
  prizeStructure: BaseDrawPrizeStructure;
  scheduledStartDate: Scalars['Timestamp'];
  scheduledEndDate: Scalars['Timestamp'];
  /**
   * For rewards users to query the number of tickets
   * they have in the draw.
   */
  myNumTicketsInDraw: Scalars['Int'];
};

export type BaseDrawPrize = {
  id: Scalars['ID'];
  numWinners?: Maybe<Scalars['Int']>;
};

export type BaseDrawPrizeStructure = {
  id: Scalars['ID'];
  totalPrizePointValue: Scalars['Int'];
  numWinners: Scalars['Int'];
};

export type BonusPointsReminder = {
  __typename?: 'BonusPointsReminder';
  id: Scalars['ID'];
  sentAt: Scalars['Timestamp'];
};

export enum BonusPointsStatus {
  Scheduled = 'SCHEDULED',
  Cancelled = 'CANCELLED',
  Sent = 'SENT'
}

export type BudgetUsageInsightMetric = {
  __typename?: 'BudgetUsageInsightMetric';
  id: Scalars['ID'];
  pointsSentByAdminsWithBudgets: Scalars['Int'];
  adminBudgetTotal: Scalars['Int'];
};

export type BulkAcknowledgeMyRewardOfferCompletionsData = {
  offerId: Scalars['ID'];
  completionIds: Array<Scalars['ID']>;
};

export type BulkGenerateRedemptionExportInput = {
  organizationId: Scalars['ID'];
  redemptionIds: Array<Scalars['ID']>;
};

export type BulkGenerateRedemptionExportResult = {
  __typename?: 'BulkGenerateRedemptionExportResult';
  items: Array<BulkGenerateRedemptionExportResultItem>;
};

export type BulkGenerateRedemptionExportResultItem = {
  __typename?: 'BulkGenerateRedemptionExportResultItem';
  organizationId: Scalars['ID'];
  id?: Maybe<Scalars['ID']>;
  status: RedemptionExportStatus;
  error?: Maybe<Scalars['String']>;
};

export type BulkImportError = {
  __typename?: 'BulkImportError';
  index: Scalars['Int'];
  error?: Maybe<Scalars['String']>;
};

export enum BulkImportStatus {
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Processed = 'PROCESSED',
  ProcessingFailed = 'PROCESSING_FAILED'
}

export type BulkImportStatusResponse = {
  __typename?: 'BulkImportStatusResponse';
  id: Scalars['ID'];
  status: BulkImportStatus;
  durationMs?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<BulkImportError>>;
  results: Scalars['JSON'];
  uploadedAt: Scalars['String'];
  processingStartedAt?: Maybe<Scalars['String']>;
  processingCompletedAt?: Maybe<Scalars['String']>;
};

export enum BulkImportType {
  CreateOrUpdateRewardOffers = 'CREATE_OR_UPDATE_REWARD_OFFERS',
  CreatePowerHoursOffers = 'CREATE_POWER_HOURS_OFFERS',
  CreateOrUpdateEmployees = 'CREATE_OR_UPDATE_EMPLOYEES',
  CreateAdmins = 'CREATE_ADMINS',
  CompleteManualCampaignOffers = 'COMPLETE_MANUAL_CAMPAIGN_OFFERS',
  SendRecognitionPoints = 'SEND_RECOGNITION_POINTS',
  ScheduleRecognitionPoints = 'SCHEDULE_RECOGNITION_POINTS',
  SetHoursForPowerHoursOffer = 'SET_HOURS_FOR_POWER_HOURS_OFFER',
  SetHoursForPowerHoursCampaigns = 'SET_HOURS_FOR_POWER_HOURS_CAMPAIGNS',
  UpdateUserAssignedSmsFromNumber = 'UPDATE_USER_ASSIGNED_SMS_FROM_NUMBER',
  UpdateSurveyOfferExternalIds = 'UPDATE_SURVEY_OFFER_EXTERNAL_IDS',
  AddOrRemoveUsersToSegment = 'ADD_OR_REMOVE_USERS_TO_SEGMENT',
  UpdateAdminAutomatedRecognitionBudgetsConfig = 'UPDATE_ADMIN_AUTOMATED_RECOGNITION_BUDGETS_CONFIG',
  SetManualManagerRecognitionBudgets = 'SET_MANUAL_MANAGER_RECOGNITION_BUDGETS',
  UpdateEvvCompletionRateCampaignsState = 'UPDATE_EVV_COMPLETION_RATE_CAMPAIGNS_STATE'
}

export type BulkRecognitionUpload = {
  __typename?: 'BulkRecognitionUpload';
  id: Scalars['ID'];
};

export type BulkRecognitionUploadPreviewTotals = {
  recognitions: Scalars['Int'];
  points: Scalars['Int'];
};

export type BulkRewardsUserUpload = {
  __typename?: 'BulkRewardsUserUpload';
  id: Scalars['ID'];
};

export type CaMailingAddress = {
  __typename?: 'CAMailingAddress';
  countryCode: MailingAddressCountryCode;
  provinceCode: CaProvinceCode;
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  postalCode: Scalars['String'];
};

export enum CaProvinceCode {
  Ab = 'AB',
  Bc = 'BC',
  Mb = 'MB',
  Nb = 'NB',
  Nl = 'NL',
  Nt = 'NT',
  Ns = 'NS',
  Nu = 'NU',
  On = 'ON',
  Pe = 'PE',
  Qc = 'QC',
  Sk = 'SK',
  Yt = 'YT'
}

export type Candidate = {
  __typename?: 'Candidate';
  id: Scalars['ID'];
  organization: RewardsOrganization;
  archived: Scalars['Boolean'];
  archivedAt?: Maybe<Scalars['Timestamp']>;
  archiveReason?: Maybe<CandidateArchiveReason>;
  completed: Scalars['Boolean'];
  /** @deprecated to support hours based milestone use `completedStepsV2` instead */
  completedSteps: Array<RecordedCandidateStep>;
  completedStepsV2: Array<RecordedCandidateStepV2>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  preferredName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  jobsAppliedTo: Array<JobPosting>;
  appliedOnDates: Array<Scalars['Date']>;
  referredByUser: RewardsUser;
  pointsEarnedByReferredByUser: Scalars['Int'];
  /**
   * Notes that an admin can write to provide context for a candidate.
   * (e.g. why the candidate didn't pass their interview, or why they were archived)
   */
  adminNotes?: Maybe<Scalars['String']>;
  hasUploadedResume: Scalars['Boolean'];
  temporaryResumeDownloadLink?: Maybe<Scalars['String']>;
  /** @deprecated Use jobsAppliedTo instead */
  jobAppliedTo: JobPosting;
  /** @deprecated Use appliedOnDates instead */
  appliedOnDate: Scalars['Date'];
  applicationSubmissions: Array<ApplicationSubmission>;
  resumeReminders: Array<CandidateResumeReminder>;
  assignedToAdmin?: Maybe<RewardsAdminUser>;
  assignedToJobPosting?: Maybe<JobPosting>;
  linkedEmployee?: Maybe<RewardsUser>;
  /**
   * If present, represents the next step that the candidate is able to complete.
   * Is not present if the candidate has completed all steps, or is archived.
   */
  nextStepToComplete?: Maybe<CandidateStepV2>;
};

export enum CandidateArchiveReason {
  Unknown = 'UNKNOWN',
  Completed = 'COMPLETED',
  JobClosed = 'JOB_CLOSED',
  AutoReject = 'AUTO_REJECT'
}

export type CandidateHourlyRetentionStep = {
  __typename?: 'CandidateHourlyRetentionStep';
  durationHours: Scalars['Int'];
};

export type CandidateMonthlyRetentionStep = {
  __typename?: 'CandidateMonthlyRetentionStep';
  durationMonths: Scalars['Int'];
};

export type CandidateRecruitmentStep = {
  __typename?: 'CandidateRecruitmentStep';
  stepName: CandidateRecruitmentStepName;
};

export enum CandidateRecruitmentStepName {
  ApplicationSubmitted = 'APPLICATION_SUBMITTED',
  Contacted = 'CONTACTED',
  InterviewScheduled = 'INTERVIEW_SCHEDULED',
  InterviewSuccessful = 'INTERVIEW_SUCCESSFUL',
  Hired = 'HIRED',
  StartedWork = 'STARTED_WORK',
  StartedOrientation = 'STARTED_ORIENTATION',
  CompletedOrientation = 'COMPLETED_ORIENTATION',
  CompletedFirstShift = 'COMPLETED_FIRST_SHIFT'
}

export type CandidateResumeReminder = {
  __typename?: 'CandidateResumeReminder';
  id: Scalars['ID'];
  sentAt: Scalars['Timestamp'];
};

export type CandidateRetentionStep = {
  __typename?: 'CandidateRetentionStep';
  durationMonths: Scalars['Int'];
};

/** Deprecated: Use `CandidateStepV2` instead */
export type CandidateStep = CandidateRecruitmentStep | CandidateRetentionStep;

export type CandidateStepV2 = CandidateRecruitmentStep | CandidateMonthlyRetentionStep | CandidateHourlyRetentionStep;

export enum CaribouRewardsPrototypeRecruitmentStep {
  JobPostingShared = 'JOB_POSTING_SHARED',
  JobApplicationSubmitted = 'JOB_APPLICATION_SUBMITTED'
}

/**
 * Represents a draw that is sponsored-by and operated by Caribou,
 * and functions cross-org.
 */
export type CaribouSponsoredDraw = BaseDraw & {
  __typename?: 'CaribouSponsoredDraw';
  id: Scalars['ID'];
  prizeStructure: BaseDrawPrizeStructure;
  scheduledStartDate: Scalars['Timestamp'];
  scheduledEndDate: Scalars['Timestamp'];
  /**
   * For rewards users to query the number of tickets
   * they have in the draw.
   */
  myNumTicketsInDraw: Scalars['Int'];
  linkToExternalMarketingPage: Scalars['String'];
};

export type CognitoMutationResponse = {
  __typename?: 'CognitoMutationResponse';
  success: Scalars['Boolean'];
};

export type CompleteTrainingGoalDetails = {
  __typename?: 'CompleteTrainingGoalDetails';
  /**
   * The ID of the training program that should be completed to achieve this goal.
   * This will always be present on available training goals.
   */
  trainingProgramId: Scalars['ID'];
  trainingProgramName: Scalars['String'];
  trainingProgramDurationSeconds: Scalars['Int'];
};

export type CompleteTrainingGoalTracker = {
  __typename?: 'CompleteTrainingGoalTracker';
  id: Scalars['ID'];
  programDurationCompletedSeconds: Scalars['Int'];
  progressUpdatedAt: Scalars['Timestamp'];
};

export type CostOfReferralsInsightMetric = {
  __typename?: 'CostOfReferralsInsightMetric';
  id: Scalars['ID'];
  pointsForReferrals: Scalars['Int'];
};

export type CountMyJobSharesForRange = {
  __typename?: 'CountMyJobSharesForRange';
  numShares: Scalars['Int'];
};

export type CountMyTicketsInDrawResponse = {
  __typename?: 'CountMyTicketsInDrawResponse';
  numTickets: Scalars['Int'];
};

export type CreateJobShareResponse = {
  __typename?: 'CreateJobShareResponse';
  jobShareId: Scalars['ID'];
  jobShareUrl: Scalars['String'];
};

export type CreatePresignedVideoUploadUrlResponse = {
  __typename?: 'CreatePresignedVideoUploadUrlResponse';
  /** Presigned video upload URL for the newly created video */
  presignedVideoUploadUrl: Scalars['String'];
  video: RewardVideo;
};

export type CreateRewardsGroupInput = {
  name: Scalars['String'];
  tagsConfigV1: RewardsGroupTagsConfigSchemaV1Input;
};

export type CreateRewardsUserSmsLoginChallengeResponse = {
  __typename?: 'CreateRewardsUserSMSLoginChallengeResponse';
  challengeId: Scalars['String'];
};

export type CustomerIoGenerateRewardsUserLoginLinkResponse = {
  __typename?: 'CustomerIOGenerateRewardsUserLoginLinkResponse';
  loginLink: Scalars['String'];
};


export type DeleteRecognitionCategoryResponse = {
  __typename?: 'DeleteRecognitionCategoryResponse';
  deleted: Scalars['Boolean'];
};

export type DependingGoalUnlockCriteria = {
  __typename?: 'DependingGoalUnlockCriteria';
  dependingGoalId: Scalars['ID'];
  /**
   * Loads the goal that must be achieved before this goal
   * is unlocked.
   */
  dependingGoal: Goal;
};

/** Represents a `Draw` that is organization-run. */
export type Draw = BaseDraw & {
  __typename?: 'Draw';
  id: Scalars['ID'];
  /** Represents the draw's status on the schedule */
  scheduleStatus: DrawStatusOnSchedule;
  name?: Maybe<Scalars['String']>;
  prizeStructure: BaseDrawPrizeStructure;
  scheduledStartDate: Scalars['Timestamp'];
  scheduledEndDate: Scalars['Timestamp'];
  announcementVideo?: Maybe<RewardVideo>;
  status: DrawStatus;
  pendingWinnerApproval: Scalars['Boolean'];
  winnersAnnounced: Scalars['Boolean'];
  /**
   * For admins to view the winners, before approving them.
   * If `null`, there are no winners to approve.
   */
  winnersToApprove?: Maybe<Array<DrawWinnerToApprove>>;
  /** @deprecated Use `scheduledStartDate` */
  scheduledOpenDate: Scalars['Timestamp'];
  /** @deprecated Use `scheduledEndDate` */
  scheduledCloseDate: Scalars['Timestamp'];
  myRewardsUserHasTicketsInDraw: Scalars['Boolean'];
  myRewardsUserViewedWinners: Scalars['Boolean'];
  /**
   * The "approved" winners. These are the winners
   * which should be visible in the rewards app,
   * and who can claim their prize.
   */
  winners?: Maybe<Array<DrawWinner>>;
  /**
   * For rewards users to query the number of tickets
   * they have in the draw.
   */
  myNumTicketsInDraw: Scalars['Int'];
};

export type DrawPrize = DrawPrizePoints | DrawTieredPrizePoints;

export type DrawPrizePoints = BaseDrawPrize & {
  __typename?: 'DrawPrizePoints';
  id: Scalars['ID'];
  pointValue: Scalars['Int'];
  numWinners?: Maybe<Scalars['Int']>;
};

export type DrawPrizeStructureInput = {
  numWinners: Scalars['Int'];
  pointValue: Scalars['Int'];
};

export type DrawPrizeStructureSinglePrizeMultipleWinners = BaseDrawPrizeStructure & {
  __typename?: 'DrawPrizeStructureSinglePrizeMultipleWinners';
  id: Scalars['ID'];
  totalPrizePointValue: Scalars['Int'];
  prize: DrawPrize;
  numWinners: Scalars['Int'];
};

export type DrawPrizeStructureTieredPrizesMultipleWinners = BaseDrawPrizeStructure & {
  __typename?: 'DrawPrizeStructureTieredPrizesMultipleWinners';
  id: Scalars['ID'];
  totalPrizePointValue: Scalars['Int'];
  prizes: Array<DrawPrize>;
  numWinners: Scalars['Int'];
};

export enum DrawStatus {
  Scheduled = 'SCHEDULED',
  Open = 'OPEN',
  Closed = 'CLOSED'
}

export enum DrawStatusOnSchedule {
  Scheduled = 'SCHEDULED',
  Live = 'LIVE',
  Completed = 'COMPLETED'
}

export type DrawTieredPrizePoints = BaseDrawPrize & {
  __typename?: 'DrawTieredPrizePoints';
  id: Scalars['ID'];
  pointValue: Scalars['Int'];
  numWinners: Scalars['Int'];
};

export type DrawWayToEarn = {
  id: Scalars['ID'];
  title: Scalars['String'];
  numTickets: Scalars['Int'];
};

export type DrawWayToEarnRewardOffer = DrawWayToEarn & {
  __typename?: 'DrawWayToEarnRewardOffer';
  id: Scalars['ID'];
  title: Scalars['String'];
  numTickets: Scalars['Int'];
  rewardOffer: RewardOffer;
};

export type DrawWayToEarnShareJobs = DrawWayToEarn & {
  __typename?: 'DrawWayToEarnShareJobs';
  id: Scalars['ID'];
  title: Scalars['String'];
  numTickets: Scalars['Int'];
};

export type DrawWinner = {
  __typename?: 'DrawWinner';
  id: Scalars['ID'];
  userDisplayName: Scalars['String'];
  isMe: Scalars['Boolean'];
  prizeTierIndex?: Maybe<Scalars['Int']>;
  claimedPrize: DrawWinnerClaimedPrize;
  user: RewardsUser;
};

export enum DrawWinnerClaimedPrize {
  Unknown = 'UNKNOWN',
  Yes = 'YES',
  No = 'NO'
}

export type DrawWinnerToApprove = {
  __typename?: 'DrawWinnerToApprove';
  id: Scalars['ID'];
  user: RewardsUser;
  prizeTierIndex?: Maybe<Scalars['Int']>;
  prizeTierUpdated?: Maybe<Scalars['Boolean']>;
};

export type EvvCompletionRateLevel = {
  __typename?: 'EVVCompletionRateLevel';
  id: Scalars['ID'];
  /**
   * The index of this level within the offer.
   *
   * It is a 1-based index (the first level is `1`).
   */
  levelNumber: Scalars['Int'];
  /** The total number of evv completion percentage required to finish this level. */
  evvCompletionPercentage: Scalars['Int'];
  /**
   * The total number of draw tickets that the user will earn if this is the level
   * that they end on.
   */
  numDrawTickets: Scalars['Int'];
  /** If `true`, the user has finished this level. */
  finished: Scalars['Boolean'];
};

export type EvvCompletionRateOffer = {
  __typename?: 'EVVCompletionRateOffer';
  id: Scalars['ID'];
  /** All levels for this offer. */
  levels: Array<EvvCompletionRateLevel>;
  /**
   * The "targetLevel" level is the initially configured target.
   * This won't change over the lifetime of the offer.
   */
  targetLevel: EvvCompletionRateLevel;
  /**
   * The "adjustedTargetLevel" level tries to determine a target level to display to the user
   * so that they still have an achievable goal.
   */
  adjustedTargetLevel: EvvCompletionRateLevel;
  /** The start of the range in which visits completed with evv count towards this offer. */
  rangeStart: Scalars['Timestamp'];
  /**
   * The end of the range in which visits completed with evv count towards this offer.
   *
   * This is also the timestamp at which the offer ends.
   */
  rangeEnd: Scalars['Timestamp'];
  /** The total number of scheduled visits the user has during this offer. */
  numCountedVisits: Scalars['Int'];
  /** The total number of completed visits with EVV the user has during this offer. */
  numCountedVisitsWithCompletedEVV: Scalars['Int'];
  /**
   * The number of evv completion rate the user has completed towards this offer.
   * This will be `null` if `numCountedVisits === 0` (there are no counted visits for this offer yet).
   */
  trackedEVVCompletionPercentage?: Maybe<Scalars['Int']>;
  /** The time when user's data has been updated for this offer. */
  lastUpdatedAsOf?: Maybe<Scalars['Timestamp']>;
  /** If `true`, the offer has ended (likely because the `rangeEnd` has elapsed). */
  ended: Scalars['Boolean'];
};

export type EvvRules = {
  __typename?: 'EVVRules';
  minutesLessThanScheduledStart?: Maybe<Scalars['Int']>;
  minutesGreaterThanScheduledStart?: Maybe<Scalars['Int']>;
  minutesLessThanScheduledEnd?: Maybe<Scalars['Int']>;
  minutesGreaterThanScheduledEnd?: Maybe<Scalars['Int']>;
  minutesLessThanScheduledDuration?: Maybe<Scalars['Int']>;
  minutesGreaterThanScheduledDuration?: Maybe<Scalars['Int']>;
  validClockMethods?: Maybe<Array<EvvRulesClockMethod>>;
};

export enum EvvRulesClockMethod {
  MobileApp = 'MOBILE_APP',
  Ivr = 'IVR',
  SystemAutoClock = 'SYSTEM_AUTO_CLOCK',
  Unknown = 'UNKNOWN'
}

export type EvvRulesInput = {
  minutesLessThanScheduledStart?: Maybe<Scalars['Int']>;
  minutesGreaterThanScheduledStart?: Maybe<Scalars['Int']>;
  minutesLessThanScheduledEnd?: Maybe<Scalars['Int']>;
  minutesGreaterThanScheduledEnd?: Maybe<Scalars['Int']>;
  minutesLessThanScheduledDuration?: Maybe<Scalars['Int']>;
  minutesGreaterThanScheduledDuration?: Maybe<Scalars['Int']>;
  validClockMethods?: Maybe<Array<EvvRulesClockMethod>>;
};

export enum EvvStreakGoalTrackedEvvStatus {
  EvvCompliant = 'evv_compliant',
  NotEvvCompliant = 'not_evv_compliant',
  MissedByCaregiver = 'missed_by_caregiver',
  Pending = 'pending'
}

export type EvvStreakGoalTracker = {
  __typename?: 'EVVStreakGoalTracker';
  id: Scalars['ID'];
  targetStreak: Scalars['Int'];
  currentStreak: Scalars['Int'];
  trackedVisits: Array<EvvStreakGoalTrackerVisitDetail>;
};

export type EvvStreakGoalTrackerVisitDetail = {
  __typename?: 'EVVStreakGoalTrackerVisitDetail';
  id: Scalars['ID'];
  rangeStart: Scalars['Timestamp'];
  rangeEnd: Scalars['Timestamp'];
  status: EvvStreakGoalTrackedEvvStatus;
};

export type EligibleReferralHoursWorkedInsightMetric = {
  __typename?: 'EligibleReferralHoursWorkedInsightMetric';
  id: Scalars['ID'];
  numHoursWorked: Scalars['Float'];
};

export type GenerateMyNevvonLoginLinkResponse = {
  __typename?: 'GenerateMyNevvonLoginLinkResponse';
  loginLink?: Maybe<Scalars['String']>;
};

export type GenerateMyRewardsUserLoginLinkResponse = {
  __typename?: 'GenerateMyRewardsUserLoginLinkResponse';
  loginLink: Scalars['String'];
};

export type GenerateRedemptionExportResult = {
  __typename?: 'GenerateRedemptionExportResult';
  /** Redemption export id */
  id: Scalars['ID'];
  downloadLink: Scalars['String'];
};

export type GenerateRewardsUserExportResponse = {
  __typename?: 'GenerateRewardsUserExportResponse';
  downloadLink: Scalars['String'];
};

export type GetAllDrawsByScheduleStatusResponse = {
  __typename?: 'GetAllDrawsByScheduleStatusResponse';
  /** The organization ID */
  id: Scalars['ID'];
  scheduled: Array<Draw>;
  live?: Maybe<Draw>;
  completed: Array<Draw>;
};

export type GetHhaxCaregiversToSyncResponse = {
  __typename?: 'GetHHAXCaregiversToSyncResponse';
  items: Array<HhaxCaregiverToSync>;
  total: Scalars['Int'];
};

export type GetJobShareLinkDisabledForUserResponse = {
  __typename?: 'GetJobShareLinkDisabledForUserResponse';
  disabled: Scalars['Boolean'];
};

export type GetMyDrawWaysToEarnResponse = {
  __typename?: 'GetMyDrawWaysToEarnResponse';
  items: Array<DrawWayToEarn>;
};

export type GetMyManagedEmployeesRecognitionPointsReceivedSummary = {
  __typename?: 'GetMyManagedEmployeesRecognitionPointsReceivedSummary';
  /** null if authenticated manager has no managed employees */
  pointsReceivedTotal?: Maybe<Scalars['Int']>;
  /** null if authenticated manager has no managed employees */
  pointsReceivedFromManager?: Maybe<Scalars['Int']>;
  /** null if authenticated manager has no managed employees */
  pointsReceivedFromOthers?: Maybe<Scalars['Int']>;
  /** null if authenticated manager has no managed employees */
  percentageOfTeamEarnedPoints?: Maybe<Scalars['Int']>;
};

export type GetMyManagedEmployeesRecognitionPointsReceivedSummaryV2Response = {
  __typename?: 'GetMyManagedEmployeesRecognitionPointsReceivedSummaryV2Response';
  /** null if authenticated manager has no managed employees */
  pointsReceivedTotal?: Maybe<Scalars['Int']>;
  /** null if authenticated manager has no managed employees */
  pointsReceivedFromManager?: Maybe<Scalars['Int']>;
  /** null if authenticated manager has no managed employees */
  pointsReceivedFromOthers?: Maybe<Scalars['Int']>;
  /** null if authenticated manager has no managed employees */
  percentageOfTeamEarnedPoints?: Maybe<Scalars['Int']>;
};

export type GetMyRecognitionPointsSentSummaryResponse = {
  __typename?: 'GetMyRecognitionPointsSentSummaryResponse';
  daysLeftToAward?: Maybe<Scalars['Int']>;
  pointsSentTotal: Scalars['Int'];
  pointsSentToManagedEmployees: Scalars['Int'];
  pointsSentToOtherEmployees: Scalars['Int'];
  /** null if no budget */
  pointsAvailableInBudget?: Maybe<Scalars['Int']>;
  /** null if no budget */
  pointsBudget?: Maybe<Scalars['Int']>;
};

export type GetMyRecognitionPointsSentSummaryV2Response = {
  __typename?: 'GetMyRecognitionPointsSentSummaryV2Response';
  daysLeftToAward?: Maybe<Scalars['Int']>;
  pointsSentTotal: Scalars['Int'];
  pointsSentToManagedEmployees: Scalars['Int'];
  pointsSentToOtherEmployees: Scalars['Int'];
  /** null if no budget */
  pointsAvailableInBudget?: Maybe<Scalars['Int']>;
  /** null if no budget */
  pointsBudget?: Maybe<Scalars['Int']>;
};

export type GetMyRewardsAdminMfaStatusResponse = {
  __typename?: 'GetMyRewardsAdminMfaStatusResponse';
  /** The admin's ID, or Rewards Superuser ID. */
  id: Scalars['ID'];
  /** True if the user must enable MFA. */
  smsMFARequired: Scalars['Boolean'];
  /** True if the user has set a verified phone number to use for SMS MFA. */
  smsNumberVerified: Scalars['Boolean'];
};

export type GetMyRewardsOrganizationRecognitionCategoriesFilter = {
  disableSendFromManagers?: Maybe<Scalars['Boolean']>;
  hasDefaultAmount?: Maybe<Scalars['Boolean']>;
};

export type GetMyRewardsUserGoalTrackResponse = {
  __typename?: 'GetMyRewardsUserGoalTrackResponse';
  availableGoals: Array<Goal>;
  lockedGoals: Array<Goal>;
  achievedGoals: Array<Goal>;
  trackProgress: TrackProgress;
};

export type GetMyRewardsUserRedemptionsResponse = {
  __typename?: 'GetMyRewardsUserRedemptionsResponse';
  items: Array<RewardsUserRedemption>;
};

export type GetMyTotalUnclaimedDrawsResponse = {
  __typename?: 'GetMyTotalUnclaimedDrawsResponse';
  totalUnclaimedDraws: Scalars['Int'];
};

export type GetRewardsAdminAuthStatusResponse = {
  __typename?: 'GetRewardsAdminAuthStatusResponse';
  /** The admin's ID, or Rewards Superuser ID. */
  id: Scalars['ID'];
  status: RewardsAdminAuthStatus;
};

export type GetRewardsUserExistsForIdAndOrganizationResponse = {
  __typename?: 'GetRewardsUserExistsForIdAndOrganizationResponse';
  exists: Scalars['Boolean'];
};

export type GetRewardsUserLoginTokenDetailsResponse = {
  __typename?: 'GetRewardsUserLoginTokenDetailsResponse';
  obscuredPhoneNumber: Scalars['String'];
  whiteLabelConfig?: Maybe<RewardsOrganizationWhiteLabelConfig>;
};

export type GetSharableJobPostingFilterCombination = {
  __typename?: 'GetSharableJobPostingFilterCombination';
  id: Scalars['ID'];
  title: Scalars['String'];
  geography: Scalars['String'];
  numJobs: Scalars['Int'];
};

export type GetSharableJobPostingFilterOptions = {
  __typename?: 'GetSharableJobPostingFilterOptions';
  combinations: Array<GetSharableJobPostingFilterCombination>;
};

export type Goal = {
  __typename?: 'Goal';
  id: Scalars['ID'];
  type: GoalType;
  /** Contains information about how this goal can be unlocked. */
  unlockCriteria?: Maybe<GoalUnlockCriteria>;
  /**
   * Contains details about tracking the goal's progress, which is specific to this goal's `type`:
   * - Criteria for how to complete the goal
   * - State for the goal (such as progress)
   */
  goalTracker: GoalTracker;
  /** The current `status` of the goal (e.g. `locked`, `active`, etc) */
  status: GoalStatus;
  /**
   * If a Goal may have an associated `track`, it means that this goal is associated with
   * a greater "sequence" of goals.
   *
   * A "track" may have a sub-page in the app.
   */
  trackName?: Maybe<GoalTrackName>;
  /**
   * The current progress of a goal, as a value out of 100.
   * Some goal types may not have a progress, this includes (at the time of writing): job shares and surveys.
   */
  progress?: Maybe<Scalars['Int']>;
  /** Populated if points are awarded for achieving this goal. */
  numPoints?: Maybe<Scalars['Int']>;
  /**
   * Populated if tickets for the Caribou-sponsored draw are
   * awarded for achieving this goal.
   */
  numCaribouSponsoredDrawTickets?: Maybe<Scalars['Int']>;
  /**
   * Populated if tickets for the Organization's draw are
   * awarded for achieving this goal.
   */
  numOrganizationDrawTickets?: Maybe<Scalars['Int']>;
  /** If defined, indicates the date/time that this goal will expire. */
  expiresAt?: Maybe<Scalars['Timestamp']>;
  /** If defined. indicates the date/time that this goal was achieved */
  achievedAt?: Maybe<Scalars['Timestamp']>;
  /** If defined. indicates the date/time that this goal was activated */
  activatedAt?: Maybe<Scalars['Timestamp']>;
  /** Other details about the goal, specific to the goal's `type`. */
  goalDetails?: Maybe<GoalDetails>;
};

export type GoalDetails = SurveyGoalDetails | WatchVideoGoalDetails | CompleteTrainingGoalDetails;

export enum GoalStatus {
  Locked = 'LOCKED',
  Available = 'AVAILABLE',
  Achieved = 'ACHIEVED',
  ExpiredWaitingForLateArrivingData = 'EXPIRED_WAITING_FOR_LATE_ARRIVING_DATA',
  Failed = 'FAILED',
  Cancelled = 'CANCELLED'
}

export enum GoalTrackName {
  ShareJobTrack = 'SHARE_JOB_TRACK',
  SurveyTrack = 'SURVEY_TRACK',
  EvvTrack = 'EVV_TRACK',
  LastMinuteShiftTrack = 'LAST_MINUTE_SHIFT_TRACK',
  WeekendShiftTrack = 'WEEKEND_SHIFT_TRACK'
}

export type GoalTracker = JobShareGoalTracker | SurveyGoalTracker | EvvStreakGoalTracker | WatchVideoGoalTracker | HhaxLoginGoalTracker | LastMinuteShiftGoalTracker | CompleteTrainingGoalTracker | WeekendShiftGoalTracker;

export enum GoalType {
  ShareJob = 'SHARE_JOB',
  Survey = 'SURVEY',
  EvvStreak = 'EVV_STREAK',
  WatchVideo = 'WATCH_VIDEO',
  HhaxLogin = 'HHAX_LOGIN',
  LastMinuteShift = 'LAST_MINUTE_SHIFT',
  CompleteTraining = 'COMPLETE_TRAINING',
  WeekendShift = 'WEEKEND_SHIFT'
}

export type GoalUnlockCriteria = DependingGoalUnlockCriteria | TimeBasedlUnlockCriteria;

/**
 * Strictly for QA usage, this allows creating a RewardsHHAXCaregiver for a given user.
 * Designed to be used with the `retoolCreateQARewardsUser` mutation and user params from it.
 */
export type HhaxCaregiverInput = {
  caregiverMobileId?: Maybe<Scalars['Int']>;
};

export type HhaxCaregiverToSync = {
  __typename?: 'HHAXCaregiverToSync';
  id: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  personalEmail?: Maybe<Scalars['String']>;
  personalPhone?: Maybe<Scalars['String']>;
  disciplines?: Maybe<Array<Maybe<Scalars['String']>>>;
  branchId?: Maybe<Scalars['String']>;
  officeId?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
  firstWorkedDate?: Maybe<Scalars['String']>;
};

export type HhaxCaribouUserSync = {
  __typename?: 'HHAXCaribouUserSync';
  id: Scalars['ID'];
  hhaxIntegrationId: Scalars['ID'];
  enabled: Scalars['Boolean'];
  filters?: Maybe<HhaxCaribouUserSyncFilters>;
};

export type HhaxCaribouUserSyncBranchFilterOption = {
  __typename?: 'HHAXCaribouUserSyncBranchFilterOption';
  branchId?: Maybe<Scalars['String']>;
};

export type HhaxCaribouUserSyncFilters = {
  __typename?: 'HHAXCaribouUserSyncFilters';
  disciplines: Array<Maybe<Scalars['String']>>;
  branches: Array<HhaxCaribouUserSyncBranchFilterOption>;
  offices: Array<HhaxCaribouUserSyncOfficeFilterOption>;
  teams: Array<HhaxCaribouUserSyncTeamFilterOption>;
  hasCompletedFirstShift: Array<HasCompletedFirstShiftFilterOptions>;
};

export type HhaxCaribouUserSyncFiltersInput = {
  disciplines?: Maybe<Array<Maybe<Scalars['String']>>>;
  branchIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  officeIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  teamIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  hasCompletedFirstShift?: Maybe<Array<HasCompletedFirstShiftFilterOptions>>;
};

export type HhaxCaribouUserSyncOfficeFilterOption = {
  __typename?: 'HHAXCaribouUserSyncOfficeFilterOption';
  officeId?: Maybe<Scalars['String']>;
};

export type HhaxCaribouUserSyncTeamFilterOption = {
  __typename?: 'HHAXCaribouUserSyncTeamFilterOption';
  teamId?: Maybe<Scalars['String']>;
};

export type HhaxIntegration = {
  __typename?: 'HHAXIntegration';
  id: Scalars['ID'];
  status: HhaxIntegrationStatus;
  userAutoSyncs: Array<Maybe<HhaxCaribouUserSync>>;
  /** @deprecated Replaced by `userAutoSyncs` array with `enabled` field */
  autoSyncEnabled: Scalars['Boolean'];
};

export enum HhaxIntegrationStatus {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED'
}

export type HhaxLoginGoalTracker = {
  __typename?: 'HHAXLoginGoalTracker';
  id: Scalars['ID'];
  loginCompletionData?: Maybe<HhaxLoginGoalTrackerLoginCompletionData>;
};

export type HhaxLoginGoalTrackerLoginCompletionData = HhaxLoginGoalTrackerLoginCompletionDataV1;

export type HhaxLoginGoalTrackerLoginCompletionDataV1 = {
  __typename?: 'HHAXLoginGoalTrackerLoginCompletionDataV1';
  type: Scalars['String'];
  mobileId: Scalars['String'];
};

export enum HasCompletedFirstShiftFilterOptions {
  CompletedFirstShift = 'COMPLETED_FIRST_SHIFT',
  HasntCompletedFirstShift = 'HASNT_COMPLETED_FIRST_SHIFT'
}

export type HoursMilestoneGoal = {
  __typename?: 'HoursMilestoneGoal';
  type: Scalars['String'];
  numHours: Scalars['Int'];
};

export type Identity = {
  __typename?: 'Identity';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  roles: Array<IdentityRole>;
};

export type IdentityAssumedRole = {
  __typename?: 'IdentityAssumedRole';
  id: Scalars['ID'];
  roleName: Role;
};

export type IdentityRole = {
  __typename?: 'IdentityRole';
  id: Scalars['ID'];
  roleName: Role;
  email?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  tenantId?: Maybe<Scalars['ID']>;
};

export type IneligibleReferralHoursWorkedInsightMetric = {
  __typename?: 'IneligibleReferralHoursWorkedInsightMetric';
  id: Scalars['ID'];
};

export type InsightDateRanges = LaunchedInsightDateRanges | NotLaunchedInsightDateRanges;

export type InsightsMonthlyDateRange = {
  __typename?: 'InsightsMonthlyDateRange';
  id: Scalars['ID'];
  year: Scalars['Int'];
  startDate: Scalars['Timestamp'];
  endDate: Scalars['Timestamp'];
};

export type InsightsQuarterlyDateRange = {
  __typename?: 'InsightsQuarterlyDateRange';
  id: Scalars['ID'];
  label: InsightsQuarterlyDateRangeLabel;
  year: Scalars['Int'];
  startDate: Scalars['Timestamp'];
  endDate: Scalars['Timestamp'];
};

export enum InsightsQuarterlyDateRangeLabel {
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3 = 'Q3',
  Q4 = 'Q4'
}

export type InterviewBookingLink = {
  url: Scalars['String'];
  mustUploadResumeBeforeBookingInterview?: Maybe<Scalars['Boolean']>;
};



export enum JobApplicationDriversLicenseAnswer {
  Yes = 'YES',
  No = 'NO',
  Unsure = 'UNSURE'
}

export enum JobApplicationFieldName {
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  PreferredName = 'PREFERRED_NAME',
  Email = 'EMAIL',
  PhoneNumber = 'PHONE_NUMBER',
  ResidingCity = 'RESIDING_CITY',
  PostalCode = 'POSTAL_CODE',
  HasDriversLicenseAndAccessToCar = 'HAS_DRIVERS_LICENSE_AND_ACCESS_TO_CAR',
  YearsOfExperienceWithRole = 'YEARS_OF_EXPERIENCE_WITH_ROLE'
}

export enum JobApplicationYearsOfExperienceAnswer {
  LessThanAYear = 'LESS_THAN_A_YEAR',
  OneToThreeYears = 'ONE_TO_THREE_YEARS',
  GreaterThanThreeYears = 'GREATER_THAN_THREE_YEARS'
}

export type JobPosting = {
  __typename?: 'JobPosting';
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
  branch?: Maybe<RewardsOrganizationBranch>;
  title: Scalars['String'];
  ratesOfPay?: Maybe<Scalars['String']>;
  geography: Scalars['String'];
  hoursRequired: Scalars['String'];
  descriptionHTML: Scalars['String'];
  closedForSubmission: Scalars['Boolean'];
  /**
   * All of the application fields that should be displayed to
   * applicants. This includes fields like "FIRST_NAME", etc,
   * in addition to screener questions.
   *
   * Use `screenerQuestions` instead for managing the screener questions (e.g. for Rewards Admins)
   */
  applicationFields: Array<JobPostingApplicationField>;
  /**
   * The screener questions for the job postings.
   *
   * Use `applicationFields` instead for viewing all of the fields that the
   * applicant must fill out (which includes the screener questions)
   */
  screenerQuestions: Array<JobPostingScreenerQuestion>;
  /**
   * The email which receives candidate alerts.
   *
   * This will be either the organization-wide email for candidate alerts,
   * or an override that is specific for this job posting.
   */
  candidateAlertEmail?: Maybe<Scalars['String']>;
  /** @deprecated Use applicationFields instead */
  applicationFieldConfiguration: Array<JobPostingFieldConfiguration>;
  /** @deprecated Use the 'job share' endpoints instead */
  url: Scalars['String'];
  /** @deprecated Use 'hoursRequired' instead */
  hoursRequiredPerWeek: Scalars['String'];
  /** @deprecated Use 'descriptionHTML' instead */
  descriptionText: Scalars['String'];
  /** The rewards organization associated with this job posting. */
  organization: RewardsOrganization;
  activePromotion?: Maybe<JobPostingPromotion>;
  /** For Rewards Admins only. */
  stats: JobPostingStats;
  customizedApplicantSMSMessage?: Maybe<Scalars['String']>;
  customizedApplicantEmailMessage?: Maybe<Scalars['String']>;
  interviewBookingLink?: Maybe<Scalars['String']>;
  mustUploadResumeBeforeBookingInterview?: Maybe<Scalars['Boolean']>;
};

export type JobPostingApplicationField = JobPostingSystemRequiredField | JobPostingSystemMaybeOptionalField | JobPostingYesNoField;

export enum JobPostingAutoRejectResponse {
  Yes = 'YES',
  No = 'NO'
}

export type JobPostingFieldConfiguration = {
  __typename?: 'JobPostingFieldConfiguration';
  /** @deprecated Use type instead */
  field: JobApplicationFieldName;
  optional?: Maybe<Scalars['Boolean']>;
};

export type JobPostingFieldConfigurationInput = {
  type: JobPostingFieldType;
  /** Required if the field type is YES_OR_NO */
  question?: Maybe<Scalars['String']>;
  optional?: Maybe<Scalars['Boolean']>;
};

export type JobPostingFieldInterface = {
  id: Scalars['String'];
  type: JobPostingFieldType;
};

export enum JobPostingFieldType {
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  Email = 'EMAIL',
  PhoneNumber = 'PHONE_NUMBER',
  PreferredName = 'PREFERRED_NAME',
  ResidingCity = 'RESIDING_CITY',
  PostalCode = 'POSTAL_CODE',
  ZipCode = 'ZIP_CODE',
  HasDriversLicenseAndAccessToCar = 'HAS_DRIVERS_LICENSE_AND_ACCESS_TO_CAR',
  YearsOfExperienceWithRole = 'YEARS_OF_EXPERIENCE_WITH_ROLE',
  YesOrNo = 'YES_OR_NO'
}

export type JobPostingFilterInput = {
  /**
   * Allows filtering by organization branch ID.
   * If provided, will only show results from given branch(es)
   * If not provided, will show results from all branches
   * If [null] is provided, will show results that have no branch assigned
   * If [] is provided, will show NO results at all
   */
  branchIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  closed?: Maybe<Scalars['Boolean']>;
  searchQuery?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<JobPostingFilterInputSort>>;
};

export type JobPostingFilterInputSort = {
  property: JobPostingFilterInputSortProperty;
  direction: Sort;
};

export enum JobPostingFilterInputSortProperty {
  Title = 'TITLE',
  CreatedAt = 'CREATED_AT',
  ClosedForSubmission = 'CLOSED_FOR_SUBMISSION',
  Promoted = 'PROMOTED',
  Featured = 'FEATURED'
}

export type JobPostingListResponse = {
  __typename?: 'JobPostingListResponse';
  total: Scalars['Int'];
  items: Array<JobPosting>;
};

export enum JobPostingLocationField {
  PostalCode = 'POSTAL_CODE',
  ZipCode = 'ZIP_CODE',
  ResidingCity = 'RESIDING_CITY'
}

export type JobPostingPromotion = {
  __typename?: 'JobPostingPromotion';
  id: Scalars['ID'];
  bannerMessage?: Maybe<Scalars['String']>;
};

export type JobPostingScreenerQuestion = {
  __typename?: 'JobPostingScreenerQuestion';
  id: Scalars['ID'];
  type: JobPostingScreenerQuestionType;
  /** Only defined if `type` is YES_OR_NO */
  question?: Maybe<Scalars['String']>;
  autoRejectResponses?: Maybe<Array<JobPostingAutoRejectResponse>>;
};

export type JobPostingScreenerQuestionInput = {
  type: JobPostingScreenerQuestionType;
  /** This is required if `type` is `YES_OR_NO` */
  question?: Maybe<Scalars['String']>;
  autoRejectResponses?: Maybe<Array<JobPostingAutoRejectResponse>>;
};

export enum JobPostingScreenerQuestionType {
  HasDriversLicenseAndAccessToCar = 'HAS_DRIVERS_LICENSE_AND_ACCESS_TO_CAR',
  YesOrNo = 'YES_OR_NO'
}

export type JobPostingStats = {
  __typename?: 'JobPostingStats';
  id: Scalars['ID'];
  numShares: Scalars['Int'];
  numCandidates: Scalars['Int'];
  numInterviewsScheduled: Scalars['Int'];
  numHires: Scalars['Int'];
};

export type JobPostingSystemMaybeOptionalField = JobPostingFieldInterface & {
  __typename?: 'JobPostingSystemMaybeOptionalField';
  id: Scalars['String'];
  type: JobPostingFieldType;
  optional?: Maybe<Scalars['Boolean']>;
};

export type JobPostingSystemRequiredField = JobPostingFieldInterface & {
  __typename?: 'JobPostingSystemRequiredField';
  id: Scalars['String'];
  type: JobPostingFieldType;
};

export type JobPostingYesNoField = JobPostingFieldInterface & {
  __typename?: 'JobPostingYesNoField';
  id: Scalars['String'];
  type: JobPostingFieldType;
  optional?: Maybe<Scalars['Boolean']>;
  question: Scalars['String'];
};

export enum JobPostingYesNoFieldAnswer {
  Yes = 'YES',
  No = 'NO'
}

export type JobShareGoalTracker = {
  __typename?: 'JobShareGoalTracker';
  id: Scalars['ID'];
};

export type LastMinuteShiftGoalTracker = {
  __typename?: 'LastMinuteShiftGoalTracker';
  id: Scalars['ID'];
  trackedVisit?: Maybe<LastMinuteShiftGoalTrackerVisitDetail>;
};

export type LastMinuteShiftGoalTrackerVisitDetail = {
  __typename?: 'LastMinuteShiftGoalTrackerVisitDetail';
  id: Scalars['ID'];
  rangeStart: Scalars['Timestamp'];
  rangeEnd: Scalars['Timestamp'];
};

export type LaunchedInsightDateRanges = {
  __typename?: 'LaunchedInsightDateRanges';
  id: Scalars['ID'];
  quarterly: Array<InsightsQuarterlyDateRange>;
  monthly: Array<InsightsMonthlyDateRange>;
};

export type ListAwardedBonusPointsFilter = {
  /**
   * If provided, filters the results to only return
   * bonus points sent to the provided user id.
   */
  sentToUserId?: Maybe<Scalars['ID']>;
  /**
   * Allows filtering by organization branch ID.
   * If provided, will only show results for users from given branch(es)
   * If not provided, will show results for users from all branches
   * If [null] is provided, will show results for users that have no branch assigned
   * If [] is provided, will show NO results at all
   */
  branchIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type ListAwardedBonusPointsResponse = {
  __typename?: 'ListAwardedBonusPointsResponse';
  total: Scalars['Int'];
  items: Array<AwardedBonusPoints>;
};

export type ListCandidateV2FilterStatusInput = {
  recruitmentStep?: Maybe<CandidateRecruitmentStepName>;
  retentionDurationMonths?: Maybe<Scalars['Int']>;
};

export type ListCandidatesFilterInput = {
  /** Search query for name or job title */
  searchQuery?: Maybe<Scalars['String']>;
  /**
   * If true, includes archived results.
   *
   * If not provided, defaults to `false`.
   */
  includeArchived?: Maybe<Scalars['Boolean']>;
  /**
   * For each recruitment step provided, candidates who have
   * reached that step of the recruitment process will be returned.
   *
   * If not provided, defaults to each recruitment status.
   */
  includeRecruitmentStatuses?: Maybe<Array<CandidateRecruitmentStepName>>;
  /**
   * For each number of months provided (e.g. [3, 6]), candidates
   * who have been retained for those lengths of time will be returned.
   *
   * If not provided, defaults to each retention month status.
   */
  includedRetentionMonthStatuses?: Maybe<Array<Scalars['Int']>>;
};

export type ListCandidatesResponse = {
  __typename?: 'ListCandidatesResponse';
  total: Scalars['Int'];
  items: Array<Candidate>;
};

export type ListCandidatesV2FilterInput = {
  /** Search query for name or job title */
  searchQuery?: Maybe<Scalars['String']>;
  /**
   * If true, shows only archived candidates.
   * If false, shows only open candidates.
   */
  archived?: Maybe<Scalars['Boolean']>;
  /**
   * If true, shows only candidates who have uploaded a resume.
   * If false, shows only candidates who haven't uploaded a resume.
   */
  hasUploadedResume?: Maybe<Scalars['Boolean']>;
  /**
   * If provided, only matches candidates with the given status.
   * Exactly one of `recruitmentStep` or `retentionDurationMonths` must be provided.
   */
  statuses?: Maybe<Array<ListCandidateV2FilterStatusInput>>;
  /**
   * If provided, only matches candidates who have applied to at
   * least one of the provided job posting ids.
   */
  assignedToJobPostingIds?: Maybe<Array<Scalars['ID']>>;
  /** @deprecated use `assignedToJobPostingIds` instead */
  appliedToJobPostingIds?: Maybe<Array<Scalars['ID']>>;
};

export enum ListCandidatesV2OrderField {
  MostRecentApplicationDate = 'MOST_RECENT_APPLICATION_DATE',
  LastUpdateDate = 'LAST_UPDATE_DATE'
}

export type ListCandidatesV2OrderInput = {
  field: ListCandidatesV2OrderField;
  direction: Sort;
};

export type ListGiftCardOptionsResponse = {
  __typename?: 'ListGiftCardOptionsResponse';
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  minPointsAllowed: Scalars['Int'];
  maxPointsAllowed: Scalars['Int'];
  brandName: Scalars['String'];
};

export type ListGiftCardOptionsResponseResponse = {
  __typename?: 'ListGiftCardOptionsResponseResponse';
  items: Array<ListGiftCardOptionsResponse>;
};

export type ListMyEvvCompletionRateOffersFilter = {
  /** This determines if the offer has ended */
  ended?: Maybe<Scalars['Boolean']>;
  /**
   * This determines if a rewards user has acknowledged an offer as "ended"
   * And, it is referred to ensure they do not receive the congratulations
   * modal a second time (when querying via `listMyEVVCompletionRateOffers`)
   */
  acknowledgedOfferEnded?: Maybe<Scalars['Boolean']>;
};

export type ListMyManagedEmployeesRecognitionSummaryFilterInput = {
  month: Scalars['MonthAndYear'];
};

export type ListMyManagedEmployeesRecognitionSummaryPageInput = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};

export type ListMyManagedEmployeesRecognitionSummaryResponse = {
  __typename?: 'ListMyManagedEmployeesRecognitionSummaryResponse';
  total: Scalars['Int'];
  items: Array<ListMyManagedEmployeesRecognitionSummaryResponseItem>;
};

export type ListMyManagedEmployeesRecognitionSummaryResponseItem = {
  __typename?: 'ListMyManagedEmployeesRecognitionSummaryResponseItem';
  /** The rewards user id */
  id: Scalars['ID'];
  employee: RewardsUser;
  pointsReceivedInMonth: Scalars['Int'];
  pointsReceivedYTD: Scalars['Int'];
};

export type ListMyManagedEmployeesRecognitionSummaryV2FilterInput = {
  range?: Maybe<ListMyManagedEmployeesRecognitionSummaryV2FilterRangeInput>;
};

export type ListMyManagedEmployeesRecognitionSummaryV2FilterRangeInput = {
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};

export type ListMyManagedEmployeesRecognitionSummaryV2PageInput = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};

export type ListMyManagedEmployeesRecognitionSummaryV2Response = {
  __typename?: 'ListMyManagedEmployeesRecognitionSummaryV2Response';
  total: Scalars['Int'];
  items: Array<ListMyManagedEmployeesRecognitionSummaryV2ResponseItem>;
};

export type ListMyManagedEmployeesRecognitionSummaryV2ResponseItem = {
  __typename?: 'ListMyManagedEmployeesRecognitionSummaryV2ResponseItem';
  /** The rewards user id */
  id: Scalars['ID'];
  employee: RewardsUser;
  /** Null if no range is provided to the query. */
  pointsReceivedInRange?: Maybe<Scalars['Int']>;
  pointsReceivedYTD: Scalars['Int'];
};

export type ListMyManagerScheduledRecognitionPointsFilter = {
  hasMessage?: Maybe<Scalars['Boolean']>;
};

export type ListMyManagerScheduledRecognitionPointsResponse = {
  __typename?: 'ListMyManagerScheduledRecognitionPointsResponse';
  total: Scalars['Int'];
  items: Array<RecognitionPoints>;
};

export type ListMyPowerHoursFilter = {
  ended?: Maybe<Scalars['Boolean']>;
  acknowledgedOfferEnded?: Maybe<Scalars['Boolean']>;
  finishedSomeLevels?: Maybe<Scalars['Boolean']>;
  acknowledgedLatestFinishedLevel?: Maybe<Scalars['Boolean']>;
};

export type ListMyRecognitionBudgetPeriodsResponse = {
  __typename?: 'ListMyRecognitionBudgetPeriodsResponse';
  items: Array<RecognitionBudgetPeriod>;
};

export type ListMyRewardOffersFilter = {
  structureType?: Maybe<RewardOfferStructureType>;
  acknowledgedMostRecentlyAwardedCompletion?: Maybe<Scalars['Boolean']>;
};

/** Input type for pagination when listing visits for a caregiver. */
export type ListMyVisitsAsOfStartDatePageInput = {
  /** Filters the visits where the start date is greater than or equal to this value. */
  startDateGte: Scalars['Timestamp'];
  /** The maximum number of items to return. Default limit is 50. */
  limit?: Maybe<Scalars['Int']>;
};

export type ListMyVisitsAsOfStartDateResponse = {
  __typename?: 'ListMyVisitsAsOfStartDateResponse';
  /** The list of caregiver visits. */
  items: Array<Visit>;
};

export type ListRecognitionCategoriesResponse = {
  __typename?: 'ListRecognitionCategoriesResponse';
  total: Scalars['Int'];
  items: Array<RecognitionCategory>;
};

export type ListRedemptionsByCursorFilterInput = {
  status?: Maybe<RedemptionStatus>;
  redemptionMethod?: Maybe<RedemptionMethod>;
  organizationIds?: Maybe<Array<Scalars['Int']>>;
};

export type ListRedemptionsByCursorResponse = {
  __typename?: 'ListRedemptionsByCursorResponse';
  total: Scalars['Int'];
  items?: Maybe<Array<Maybe<Redemption>>>;
  nextCursor?: Maybe<Scalars['String']>;
};

export type ListRewardsAdminsResponse = {
  __typename?: 'ListRewardsAdminsResponse';
  total: Scalars['Int'];
  items: Array<RewardsAdminUser>;
};

export type ListRewardsGroupsResponse = {
  __typename?: 'ListRewardsGroupsResponse';
  total: Scalars['Int'];
  items: Array<RewardsGroup>;
};

export type ListRewardsTagsResponse = {
  __typename?: 'ListRewardsTagsResponse';
  total: Scalars['Int'];
  items: Array<RewardsTag>;
};

export type ListRewardsUserGroupResponse = {
  __typename?: 'ListRewardsUserGroupResponse';
  items: Array<RewardsUserGroup>;
};

export type ListRewardsUsersFilterInput = {
  active?: Maybe<Scalars['Boolean']>;
  /**
   * Allows filtering by organization branch ID.
   * If provided, will only show results from given branch(es)
   * If not provided, will show results from all branches
   * If [null] is provided, will show results that have no branch assigned
   * If [] is provided, will show NO results at all
   */
  branchIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  providedPersonalInfo?: Maybe<Scalars['Boolean']>;
};

export type ListRewardsUsersResponse = {
  __typename?: 'ListRewardsUsersResponse';
  total: Scalars['Int'];
  items: Array<RewardsUser>;
};

export type ListSchoolsEmployerSurveysResponse = {
  __typename?: 'ListSchoolsEmployerSurveysResponse';
  items: Array<SchoolsEmployerSurvey>;
};

export type ListSentRecognitionPointsFilter = {
  /**
   * If provided, filters the results to only return
   * recognition points sent to the provided employee id.
   */
  employeeId?: Maybe<Scalars['ID']>;
  /**
   * Allows filtering by organization branch ID.
   * If provided, will only show results for employees from given branch(es)
   * If not provided, will show results for employees from all branches
   * If [null] is provided, will show results for employees that have no branch assigned
   * If [] is provided, will show NO results at all
   */
  branchIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  recognitionCategoryIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  froms?: Maybe<Array<Scalars['String']>>;
  claimed?: Maybe<Scalars['Boolean']>;
};

export type ListSentRecognitionPointsResponse = {
  __typename?: 'ListSentRecognitionPointsResponse';
  total: Scalars['Int'];
  items: Array<RecognitionPoints>;
};

export type ListShareableJobPostingsFilters = {
  /** Filters for jobs that match at least one of the provided title values */
  titleIn?: Maybe<Array<Scalars['String']>>;
  /** Filters for jobs that match at least one of the provided geography values */
  geographyIn?: Maybe<Array<Scalars['String']>>;
};

export type ListSurveyCommentsResponse = {
  __typename?: 'ListSurveyCommentsResponse';
  total: Scalars['Int'];
  items: Array<SurveyQuestionResponse>;
};

export type ListUniqueCandidateAlertEmailsResponse = {
  __typename?: 'ListUniqueCandidateAlertEmailsResponse';
  emails: Array<Scalars['String']>;
};

export enum Locale {
  En = 'en',
  Fr = 'fr',
  Ht = 'ht',
  ZhCn = 'zhCN',
  Ru = 'ru',
  Es = 'es',
  KmKh = 'kmKH',
  Uk = 'uk',
  Vi = 'vi'
}

export type LoggedInUsersInsightMetric = {
  __typename?: 'LoggedInUsersInsightMetric';
  id: Scalars['ID'];
  usersWhoLoggedInCount: Scalars['Int'];
  activeUserCount: Scalars['Int'];
};

export type MailingAddress = CaMailingAddress | UsMailingAddress;

export enum MailingAddressCountryCode {
  Ca = 'CA',
  Us = 'US'
}

export type MailingAddressInput = {
  countryCode: MailingAddressCountryCode;
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  /** Required for CA addresses */
  caPostalCode?: Maybe<Scalars['String']>;
  /** Required for CA address */
  caProvinceCode?: Maybe<CaProvinceCode>;
  /** Required for US addresses */
  usZipCode?: Maybe<Scalars['String']>;
  /** Required for US addresses */
  usStateAbbreviation?: Maybe<UsStateAbbreviation>;
};

export type MilestoneGoal = VisitMilestoneGoal | HoursMilestoneGoal;


export type Mutation = {
  __typename?: 'Mutation';
  uploadBulkImport: UploadBulkImportResponse;
  /** Allows an admin to generate their own rewards user's login link */
  generateMyRewardsUserLoginLink: GenerateMyRewardsUserLoginLinkResponse;
  /** Allows superadmin to generate a login link for any user */
  generateEmployeeImpersonationLoginLink: GenerateMyRewardsUserLoginLinkResponse;
  trackCaribouRewardsPrototypeAction: Scalars['String'];
  createRewardsAdmin: RewardsAdminUser;
  updateRewardsAdmin: RewardsAdminUser;
  /**
   * Allows a rewards admin or rewards superuser to enable MFA by providing
   * a phone number that was verified with Cognito.
   */
  enableSmsMfaForMyRewardsAdmin: GetMyRewardsAdminMfaStatusResponse;
  sendLoginLinkToEmployee?: Maybe<Scalars['Void']>;
  sendInvitationLinkToEmployee?: Maybe<Scalars['Void']>;
  /**
   * Allows a rewards admin or rewards superuser to set an autogenerated password for users
   * that have not yet set their password.
   */
  generateAndSendRewardsAdminTemporaryPassword?: Maybe<Scalars['Void']>;
  /**
   * Allows a rewards admin to update the message
   * of scheduled recognition points.
   */
  updateScheduledRecognitionPointsMessage: RecognitionPoints;
  /** Allows an admin to award bonus points to a rewards user. */
  awardUserBonusPoints?: Maybe<AwardedBonusPoints>;
  /** @deprecated Use `awardUserBonusPoints` instead */
  awardBonusPoints?: Maybe<Scalars['Void']>;
  /**
   * Allows a rewards user to claim their bonus points
   * @deprecated Use `bulkClaimRecognitionPoints` instead
   */
  bulkClaimBonusPoints: Array<AwardedBonusPoints>;
  /**
   * Allows a rewards user to claim their recognition points
   * @deprecated Use `bulkClaimRecognitionPoints` instead
   */
  bulkClaimRecognitionPoints: Array<RecognitionPoints>;
  createRecognitionCategory: RecognitionCategory;
  partialUpdateRecognitionCategory: RecognitionCategory;
  /**
   * Deletes a recognition category.
   *
   * If it's already deleted, returns `deleted: false`.
   * Throws an error if the recognition category never existed.
   */
  deleteRecognitionCategory: DeleteRecognitionCategoryResponse;
  /**
   * Allows an admin to upload a bulk recognition file for async processing.
   *
   * This does not send out recognition points directly - it only saves the file
   * and triggers a notification to internal Caribou employees.
   * This is idempotent based on the given requestId.
   */
  uploadBulkRecognitionFile: BulkRecognitionUpload;
  /** Allows a rewards admin to add a candidate manually. */
  addCandidate: Candidate;
  recordCandidateRecruitmentStep: Candidate;
  recordCandidateRetentionStep: Candidate;
  recordCandidateHourlyRetentionStep: Candidate;
  /**
   * Archives a candidate.
   * The admin can optionally update the admin notes for the candidate.
   * This will override any existing notes for the candidate.
   */
  archiveCandidate: Candidate;
  /** Bulk-archives multiple candidates. */
  bulkArchiveCandidates: Array<Candidate>;
  /** A.K.A "un-archive" candidate */
  reopenCandidate: Candidate;
  /** Updates a candidate's admin notes */
  updateCandidateAdminNotes: Candidate;
  /** Assigns the candidate to a different job posting */
  assignCandidateToJobPosting: Candidate;
  /**
   * Merges multiple "from" candidates into a given "to" candidate
   * and resolves the merged candidate.
   */
  mergeCandidates: Candidate;
  /**
   * Sends a reminder to a candidate to upload their resume.
   * Fails if the candidate has already uploaded their resume.
   * Fails if the candidate does not have an email.
   */
  sendCandidateResumeReminder: Candidate;
  /** Sets the "referred by user" of a candidate */
  correctCandidateReferredByUser: Candidate;
  /** Allows a rewards admin to link a hired candidate to an employee. */
  linkCandidateToEmployee: Candidate;
  /**
   * Allows a rewards admin or rewards superuser to upload a resume.
   * Either `applicationSubmissionId` or `candidateId` must be provided
   */
  uploadCandidateResume: UploadCandidateResumeResponse;
  cognitoSendTemporaryPasswordEmail: CognitoMutationResponse;
  cognitoSendPasswordResetCodeEmail: CognitoMutationResponse;
  cognitoSendRewardsAdminSmsVerificationCode: CognitoMutationResponse;
  cognitoTrackLogin: CognitoMutationResponse;
  customerIOGenerateRewardsUserLoginLink: CustomerIoGenerateRewardsUserLoginLinkResponse;
  scheduleDraw: Draw;
  scheduleDrawV2: Draw;
  updateDraw: Draw;
  updateDrawV2: Draw;
  deleteDraw?: Maybe<Scalars['Void']>;
  /**
   * Approves draw winners.
   *
   * If draw winners were already approved, this functions as a no-op.
   */
  approveDrawWinners: Draw;
  /**
   * Marks draw winners as viewed by the user.
   *
   * If draw winners were already viewed, this functions as a no-op.
   */
  markDrawWinnersAsViewed: Draw;
  /**
   * Allows a rewards user to claim their draw prize, if they are a winner.
   *
   * If the user has already claimed their prize, this functions as a no-op.
   */
  claimDrawPrize: Draw;
  /**
   * Allows admins to reject a winner before approving all the winners
   *
   * Then automatically bump everyone in the list and redraw for the empty spots
   */
  redrawWinner?: Maybe<Draw>;
  /**
   * Allows a rewards user to acknowledge an offer as "ended",
   * which will ensure they do not receive the congratulations
   * modal a second time (when querying via `listMyEVVCompletionRateOffers`)
   */
  acknowledgeEVVCompletionRateOfferEnded: EvvCompletionRateOffer;
  /** Marks a goal as achievement acknowledged. */
  acknowledgeGoalAchievement?: Maybe<Goal>;
  /**
   * Given a `goalId` and `videoWatchId`, attempts to
   * evaluate/achieve the goal, and returns the goal.
   */
  evaluateGoal: Goal;
  /** Given a `goalId`, attempts to activate the goal, and returns the goal. */
  activateGoal: Goal;
  /**
   * Generates an authenticated SSO login link to Nevvon for the current user,
   * if they can be mapped to a Nevvon-integrated user account.
   *
   * To be used for Complete Training goals where the training program is in Nevvon.
   * In the case when this link is missing there should be an error
   * raised on the front-end.
   */
  generateMyNevvonLoginLink?: Maybe<GenerateMyNevvonLoginLinkResponse>;
  /** Create a group of tags, with given config */
  createRewardsGroup: Scalars['ID'];
  enableCaribouUserSyncForHHAXIntegration?: Maybe<HhaxIntegration>;
  updateHHAXCaribouUserSyncFilters: HhaxCaribouUserSync;
  /** Strictly for QA purposes, supports updating mobileId only */
  retoolUpdateQAHHAXCaregiver?: Maybe<Scalars['Void']>;
  /** Public mutation which allows someone to submit a job application */
  submitJobApplication: SubmitJobApplicationResponse;
  /**
   * Public mutation which allows someone to upload a resume.
   * Either `applicationSubmissionId` or `candidateId` must be provided
   */
  uploadJobApplicationResume: UploadJobApplicationResumeResponse;
  /** Allows a rewards admin to create and publish a job posting. */
  createJobPosting: JobPosting;
  /** Allows a rewards admin to edit a job posting. */
  updateJobPosting: JobPosting;
  /** Allows a rewards admin to close a job posting. */
  closeJobPosting: JobPosting;
  /**
   * Allows a rewards admin to start a job posting promotion.
   * If there is currently an active promotion for the job,
   * it will stop that promotion before starting this one.
   */
  startJobPostingPromotion: JobPosting;
  /** Allows a rewards admin to stop a job posting promotion. */
  stopJobPostingPromotion: JobPosting;
  /** Creates a job share link. */
  createJobShareLink?: Maybe<CreateJobShareResponse>;
  markJobShared?: Maybe<Scalars['Void']>;
  /**
   * Allows an authenticated Rewards User to bulk acknowledge
   * reward offer completions.
   */
  bulkAcknowledgeMyRewardOfferCompletions: Array<RewardOffer>;
  adminBulkCreateOrUpdateRewardOffers?: Maybe<AdminBulkCreateOrUpdateRewardOffersResponse>;
  adminBulkCompleteManualCampaignOffers?: Maybe<AdminBulkCompleteManualCampaignOffersResponse>;
  partialUpdateMyRewardsOrganization: RewardsOrganization;
  /** Mutation to update the EVV rules for the organization */
  updateOrganizationEVVRules: RewardsOrganization;
  updateMyRewardsOrganizationReferralStructureRewardValues: RewardsOrganization;
  partialUpdateMyRewardsOrganizationRedemptionSettings: RewardsOrganization;
  submitBulkRewardsUserUploadFile: BulkRewardsUserUpload;
  /** Manually records an organization onboarding step as completed. */
  markRewardsOrganizationOnboardingStepCompleted: RewardsOrganization;
  /**
   * (Deprecated)
   * Launches the rewards organization.
   * @deprecated This is no longer supported; the 'onboarding' flow replaces the launch flow
   */
  launchRewardsOrganization: RewardsOrganization;
  /**
   * (Deprecated)
   * Manually records an organization launch step as completed.
   *
   * Note that `JOB_POSTINGS_ADDED` and `REWARDS_USERS_ADDED` cannot be marked as completed.
   * Those steps will be automatically marked as complete when jobs & employees are created.
   * @deprecated This is no longer supported; the 'onboarding' flow replaces the launch flow
   */
  recordCompletedRewardsOrganizationLaunchStep: RewardsOrganization;
  /**
   * Allows a rewards user to acknowledge an offer as "ended",
   * which will ensure they do not receive the congratulations
   * modal a second time (when querying via `listMyPowerHoursOffers`)
   */
  acknowledgePowerHoursOfferEnded: PowerHoursOffer;
  /**
   * Allows a rewards user to acknowledge a level as "finished",
   * which will ensure they do not receive the congratulations
   * modal a second time (when querying via `listMyPowerHoursOffers`)
   */
  acknowledgePowerHoursLevelFinished: PowerHoursOffer;
  /**
   * Marks each redemption ID as sent to payroll. If one of them fails,
   * the returned `Redemption` for that index will be `null`,
   * and response errors will be populated.
   */
  bulkMarkRedemptionsAsSentToPayroll?: Maybe<Scalars['Void']>;
  /**
   * Marks each redemption ID as paid. If one of them fails,
   * the returned `Redemption` for that index will be `null`,
   * and response errors will be populated.
   */
  bulkMarkRedemptionsAsPaid?: Maybe<Scalars['Void']>;
  /** Generates a redemption export and a link that can be used to download it */
  generateRewardsRedemptionExport: GenerateRedemptionExportResult;
  /**
   * Marks each redemption ID as generic gift card sent. If one of them fails,
   * the returned `Redemption` for that index will be `null`,
   * and response errors will be populated.
   */
  bulkMarkRedemptionsAsGenericGiftCardSent?: Maybe<Scalars['Void']>;
  /** Queues RedemptionExports for generation, and returns the ID of the exports */
  retoolBulkGenerateRedemptionExport: BulkGenerateRedemptionExportResult;
  rejectRedemptionAndDeductPoint?: Maybe<Scalars['Void']>;
  /**
   * Allows Rewards Admins and Superusers to submit a request
   * to generate a report.
   *
   * Currently, only CSVs are supported. Each requested dataset
   * will be generated into its own CSV file.
   */
  submitReportRequest: RewardReport;
  /**
   * Allows a rewards user to sync and view their latest shift milestone details.
   * If null is returned, shift milestones are disabled for the user.
   */
  resyncMyShiftMilestones?: Maybe<RewardsUserShiftMilestoneDetails>;
  authenticateRewardsUserLoginToken: RewardsUserLoginResponse;
  /**
   * Creates a login challenge for the given login token ID (when re-authing)
   * an SMS with the challenge verification code.
   *
   * A `loginTokenId` or `userId` can be provided in order to
   * identify the user to be logged in.
   */
  createRewardsUserTokenReauthChallenge: CreateRewardsUserSmsLoginChallengeResponse;
  /**
   * Creates a login challenge for the given phone number and sends
   * an SMS with the challenge verification code.
   */
  createRewardsUserSMSLoginChallenge: CreateRewardsUserSmsLoginChallengeResponse;
  /**
   * Completes a login challenge for the given challenge id
   * and attempted verification code
   */
  completeRewardsUserSMSLoginChallenge: RewardsUserLoginResponse;
  redeemPoints: RewardsUser;
  resendGenericGiftCardLink?: Maybe<Scalars['Void']>;
  setMyRewardsUserLocale: RewardsUser;
  /**
   * Allows a user to "opt in" to Caribou Rewards by providing their personal information.
   * If this succeeds, the user will receive a login link to their personal email and phone number.
   *
   * This is a public query (doesn't require authentication).
   */
  optInWithPersonalInformation?: Maybe<Scalars['Void']>;
  /**
   * Allows a rewards admin to create a rewards user's account.
   *
   * If personal information is provided, both email and phone number are required.
   * If no personal information is provided, only work email is required.
   *
   * If a personal information is provided, the user will be sent an activation email & SMS.
   * If no personal information is provided, the user will be sent an invitation to opt-in (to their work email and phone).
   *
   * Various errors may be thrown, such as if an email or phone number belongs to
   * another user's account.
   */
  createRewardsUser: RewardsUser;
  /** Allows a rewards admin to update a rewards user's contact information */
  updateRewardsUserInformation: RewardsUser;
  adminBulkCreateRewardsUsers?: Maybe<AdminBulkCreateRewardsUserResponse>;
  adminBulkUpdateRewardsUsers?: Maybe<AdminBulkUpdateRewardsUserResponse>;
  /** Generates a rewards users export CSV and returns the download link for it */
  generateRewardsUserExport?: Maybe<GenerateRewardsUserExportResponse>;
  /** Awards points to rewards user for completing a welcome offer */
  completeWelcomeOffer: RewardsUser;
  /** Enables creating a new RewardsUser with previously used email/phone on QA or Demo orgs. */
  retoolCreateQARewardsUser: RewardsUser;
  /** Allows a rewards user to update their personal email address */
  updateMyRewardsUserEmail: RewardsUser;
  markRewardVideoUploaded: RewardVideo;
  markRewardVideoDeleted: RewardVideo;
  createPresignedVideoUploadUrl: CreatePresignedVideoUploadUrlResponse;
  recordVideoWatchByMe: RecordVideoWatchByMeResponse;
  markRewardVideoOfferWatched: RewardVideoOffer;
  adminBulkCreateOrUpdateVideoOffers?: Maybe<AdminBulkCreateOrUpdateVideoOffersResponse>;
  /** Creates a fake visit for a RewardsUser identified by email in a QA/Demo Organization. Useful for testing visit-based features. */
  retoolCreateQAVisit: RetoolCreateQaVisitResult;
  applyToSchoolsEmployer?: Maybe<Scalars['Void']>;
  signUpAsSchoolsStudent: SchoolsStudentLoginResponse;
  authenticateSchoolsStudentLoginToken: SchoolsStudentLoginResponse;
  createSchoolsStudentApplication: SchoolsJobApplication;
};


export type MutationUploadBulkImportArgs = {
  type: BulkImportType;
  items: Array<Scalars['JSONObject']>;
};


export type MutationGenerateEmployeeImpersonationLoginLinkArgs = {
  employeeId: Scalars['ID'];
};


export type MutationTrackCaribouRewardsPrototypeActionArgs = {
  accessCode: Scalars['String'];
  recruitmentStep: CaribouRewardsPrototypeRecruitmentStep;
};


export type MutationCreateRewardsAdminArgs = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  jobTitle?: Maybe<Scalars['String']>;
  permissions: RewardsAdminPermissionsInput;
  branchIds?: Maybe<Array<Scalars['ID']>>;
  role?: Maybe<RewardsAdminRoleType>;
  automaticRecognitionBudgetConfig?: Maybe<RewardsAdminAutomaticRecognitionBudgetConfigInput>;
};


export type MutationUpdateRewardsAdminArgs = {
  userId: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  permissions?: Maybe<RewardsAdminPermissionsInput>;
  branchIds?: Maybe<Array<Scalars['ID']>>;
  role?: Maybe<RewardsAdminRoleType>;
  automaticRecognitionBudgetConfig?: Maybe<RewardsAdminAutomaticRecognitionBudgetConfigInput>;
};


export type MutationSendLoginLinkToEmployeeArgs = {
  employeeId: Scalars['ID'];
};


export type MutationSendInvitationLinkToEmployeeArgs = {
  employeeId: Scalars['ID'];
};


export type MutationGenerateAndSendRewardsAdminTemporaryPasswordArgs = {
  adminId: Scalars['ID'];
};


export type MutationUpdateScheduledRecognitionPointsMessageArgs = {
  recognitionPointsId: Scalars['ID'];
  message: Scalars['String'];
};


export type MutationAwardUserBonusPointsArgs = {
  amount: Scalars['Int'];
  userId: Scalars['ID'];
  messageToUser: Scalars['String'];
  from: Scalars['String'];
  mustBeClaimed?: Maybe<Scalars['Boolean']>;
  categoryId?: Maybe<Scalars['ID']>;
};


export type MutationAwardBonusPointsArgs = {
  amount: Scalars['Int'];
  userId: Scalars['ID'];
  messageToUser: Scalars['String'];
  from: Scalars['String'];
};


export type MutationBulkClaimBonusPointsArgs = {
  bonusPointsIds: Array<Scalars['ID']>;
};


export type MutationBulkClaimRecognitionPointsArgs = {
  recognitionPointsIds: Array<Scalars['ID']>;
};


export type MutationCreateRecognitionCategoryArgs = {
  name: Scalars['String'];
  defaultPointAmount?: Maybe<Scalars['Int']>;
  disableSendFromManagers?: Maybe<Scalars['Boolean']>;
};


export type MutationPartialUpdateRecognitionCategoryArgs = {
  recognitionCategoryId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  defaultPointAmount?: Maybe<Scalars['Int']>;
  disableSendFromManagers?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteRecognitionCategoryArgs = {
  recognitionCategoryId: Scalars['ID'];
};


export type MutationUploadBulkRecognitionFileArgs = {
  bulkRecognitionFile: Scalars['Upload'];
  requestId: Scalars['String'];
  from: Scalars['String'];
  categoryId?: Maybe<Scalars['ID']>;
  notes?: Maybe<Scalars['String']>;
  previewTotals: BulkRecognitionUploadPreviewTotals;
};


export type MutationAddCandidateArgs = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  preferredName?: Maybe<Scalars['String']>;
  referredByUserId: Scalars['ID'];
  appledToJobPostingId: Scalars['ID'];
  appliedOnDate: Scalars['Date'];
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};


export type MutationRecordCandidateRecruitmentStepArgs = {
  candidateId: Scalars['ID'];
  step: CandidateRecruitmentStepName;
};


export type MutationRecordCandidateRetentionStepArgs = {
  candidateId: Scalars['ID'];
  durationMonths: Scalars['Int'];
};


export type MutationRecordCandidateHourlyRetentionStepArgs = {
  candidateId: Scalars['ID'];
  durationHours: Scalars['Int'];
};


export type MutationArchiveCandidateArgs = {
  candidateId: Scalars['ID'];
  updatedAdminNotes?: Maybe<Scalars['String']>;
};


export type MutationBulkArchiveCandidatesArgs = {
  candidateIds: Array<Scalars['ID']>;
  reason?: Maybe<CandidateArchiveReason>;
};


export type MutationReopenCandidateArgs = {
  candidateId: Scalars['ID'];
};


export type MutationUpdateCandidateAdminNotesArgs = {
  candidateId: Scalars['ID'];
  adminNotes: Scalars['String'];
};


export type MutationAssignCandidateToJobPostingArgs = {
  candidateId: Scalars['ID'];
  jobPostingId: Scalars['ID'];
};


export type MutationMergeCandidatesArgs = {
  fromCandidateIds: Array<Scalars['ID']>;
  toCandidateId: Scalars['ID'];
};


export type MutationSendCandidateResumeReminderArgs = {
  candidateId: Scalars['ID'];
};


export type MutationCorrectCandidateReferredByUserArgs = {
  candidateId: Scalars['ID'];
  referredByUserId: Scalars['ID'];
};


export type MutationLinkCandidateToEmployeeArgs = {
  candidateId: Scalars['ID'];
  employeeId: Scalars['ID'];
};


export type MutationUploadCandidateResumeArgs = {
  candidateId: Scalars['ID'];
  resume: Scalars['Upload'];
};


export type MutationCognitoSendTemporaryPasswordEmailArgs = {
  userId: Scalars['ID'];
  encryptedTemporaryPasswordCode: Scalars['String'];
};


export type MutationCognitoSendPasswordResetCodeEmailArgs = {
  userId: Scalars['ID'];
  encryptedCode: Scalars['String'];
};


export type MutationCognitoSendRewardsAdminSmsVerificationCodeArgs = {
  adminId: Scalars['ID'];
  phoneNumber: Scalars['String'];
  encryptedCode: Scalars['String'];
};


export type MutationCognitoTrackLoginArgs = {
  userId: Scalars['ID'];
};


export type MutationCustomerIoGenerateRewardsUserLoginLinkArgs = {
  userId: Scalars['ID'];
  organizationId?: Maybe<Scalars['Int']>;
  currentLoginLink?: Maybe<Scalars['String']>;
  format: RewardsUserLoginLinkFormat;
  shorten?: Maybe<Scalars['Boolean']>;
  path?: Maybe<Scalars['String']>;
};


export type MutationScheduleDrawArgs = {
  prizeStructure: DrawPrizeStructureInput;
  name: Scalars['String'];
  start: Scalars['Timestamp'];
  end: Scalars['Timestamp'];
  announcementVideoId?: Maybe<Scalars['ID']>;
};


export type MutationScheduleDrawV2Args = {
  prizeStructure: Array<DrawPrizeStructureInput>;
  name: Scalars['String'];
  start: Scalars['Timestamp'];
  end: Scalars['Timestamp'];
  announcementVideoId?: Maybe<Scalars['ID']>;
};


export type MutationUpdateDrawArgs = {
  drawId: Scalars['ID'];
  prizeStructure?: Maybe<DrawPrizeStructureInput>;
  name?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Timestamp']>;
  end?: Maybe<Scalars['Timestamp']>;
};


export type MutationUpdateDrawV2Args = {
  drawId: Scalars['ID'];
  prizeStructure?: Maybe<Array<DrawPrizeStructureInput>>;
  name?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Timestamp']>;
  end?: Maybe<Scalars['Timestamp']>;
};


export type MutationDeleteDrawArgs = {
  drawId: Scalars['ID'];
};


export type MutationApproveDrawWinnersArgs = {
  drawId: Scalars['ID'];
  winnerIds: Array<Scalars['ID']>;
  prizeTierIndexes?: Maybe<Array<Scalars['Int']>>;
};


export type MutationMarkDrawWinnersAsViewedArgs = {
  drawId: Scalars['ID'];
};


export type MutationClaimDrawPrizeArgs = {
  drawId: Scalars['ID'];
};


export type MutationRedrawWinnerArgs = {
  drawId: Scalars['ID'];
  winnerId: Scalars['ID'];
};


export type MutationAcknowledgeEvvCompletionRateOfferEndedArgs = {
  evvCompletionRateOfferId: Scalars['ID'];
};


export type MutationAcknowledgeGoalAchievementArgs = {
  goalId: Scalars['ID'];
};


export type MutationEvaluateGoalArgs = {
  goalId: Scalars['ID'];
};


export type MutationActivateGoalArgs = {
  goalId: Scalars['ID'];
};


export type MutationCreateRewardsGroupArgs = {
  input: CreateRewardsGroupInput;
};


export type MutationEnableCaribouUserSyncForHhaxIntegrationArgs = {
  hhaxIntegrationId: Scalars['ID'];
  filters: HhaxCaribouUserSyncFiltersInput;
};


export type MutationUpdateHhaxCaribouUserSyncFiltersArgs = {
  userSyncId: Scalars['ID'];
  filters: HhaxCaribouUserSyncFiltersInput;
};


export type MutationRetoolUpdateQahhaxCaregiverArgs = {
  organizationId: Scalars['ID'];
  email: Scalars['String'];
  savedFields: HhaxCaregiverInput;
};


export type MutationSubmitJobApplicationArgs = {
  jobPostingId?: Maybe<Scalars['ID']>;
  jobShareId?: Maybe<Scalars['ID']>;
  referringUserId?: Maybe<Scalars['ID']>;
  locale?: Maybe<Locale>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  preferredName?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  residingCity?: Maybe<Scalars['String']>;
  hasDriversLicenseAndAccessToCar?: Maybe<JobApplicationDriversLicenseAnswer>;
  yearsOfExperience?: Maybe<JobApplicationYearsOfExperienceAnswer>;
  yesNoAnswers?: Maybe<Array<SubmissionYesNoFieldAnswerInput>>;
};


export type MutationUploadJobApplicationResumeArgs = {
  applicationSubmissionId?: Maybe<Scalars['ID']>;
  candidateId?: Maybe<Scalars['ID']>;
  applicantUploadToken: Scalars['String'];
  resume: Scalars['Upload'];
};


export type MutationCreateJobPostingArgs = {
  branchId?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  hoursRequired: Scalars['String'];
  ratesOfPay?: Maybe<Scalars['String']>;
  geography: Scalars['String'];
  descriptionHTML: Scalars['String'];
  screenerQuestions?: Maybe<Array<JobPostingScreenerQuestionInput>>;
  candidateAlertEmail?: Maybe<Scalars['String']>;
  wasDuplicatedFromJobPostingId?: Maybe<Scalars['ID']>;
  customizedApplicantEmailMessage?: Maybe<Scalars['String']>;
  customizedApplicantSMSMessage?: Maybe<Scalars['String']>;
  interviewBookingLink?: Maybe<InterviewBookingLink>;
  fieldConfiguration?: Maybe<Array<JobPostingFieldConfigurationInput>>;
};


export type MutationUpdateJobPostingArgs = {
  jobPostingId: Scalars['ID'];
  branchId?: Maybe<Scalars['ID']>;
  hoursRequired: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  ratesOfPay?: Maybe<Scalars['String']>;
  geography: Scalars['String'];
  descriptionHTML: Scalars['String'];
  screenerQuestions?: Maybe<Array<JobPostingScreenerQuestionInput>>;
  candidateAlertEmail?: Maybe<Scalars['String']>;
  customizedApplicantEmailMessage?: Maybe<Scalars['String']>;
  customizedApplicantSMSMessage?: Maybe<Scalars['String']>;
  interviewBookingLink?: Maybe<InterviewBookingLink>;
  fieldConfiguration?: Maybe<Array<JobPostingFieldConfigurationInput>>;
};


export type MutationCloseJobPostingArgs = {
  jobPostingId: Scalars['ID'];
};


export type MutationStartJobPostingPromotionArgs = {
  jobPostingId: Scalars['ID'];
};


export type MutationStopJobPostingPromotionArgs = {
  jobPostingId: Scalars['ID'];
};


export type MutationCreateJobShareLinkArgs = {
  jobPostingId: Scalars['ID'];
};


export type MutationMarkJobSharedArgs = {
  jobShareId: Scalars['ID'];
  platform: SharePlatform;
};


export type MutationBulkAcknowledgeMyRewardOfferCompletionsArgs = {
  data: Array<BulkAcknowledgeMyRewardOfferCompletionsData>;
};


export type MutationAdminBulkCreateOrUpdateRewardOffersArgs = {
  data: Array<AdminBulkCreateOrUpdateRewardOfferInputItem>;
};


export type MutationAdminBulkCompleteManualCampaignOffersArgs = {
  data: Array<AdminBulkCompleteManualCampaignOfferInputItem>;
};


export type MutationPartialUpdateMyRewardsOrganizationArgs = {
  timezone?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  longName?: Maybe<Scalars['String']>;
  mailingAddress?: Maybe<MailingAddressInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  emailBannerLogoImage?: Maybe<Scalars['Upload']>;
  workEmailDomain?: Maybe<Scalars['String']>;
  jobPostingThemeColor?: Maybe<Scalars['String']>;
  jobPostingBannerImage?: Maybe<Scalars['Upload']>;
  jobPostingLogoImage?: Maybe<Scalars['Upload']>;
  jobPostingLocationField?: Maybe<JobPostingLocationField>;
  onboardingBusinessType?: Maybe<RewardsOrganizationOnboardingBusinessType>;
  onboardingBusinessRegistrationNumber?: Maybe<Scalars['String']>;
  onboardingCommitmentToSuccess?: Maybe<RewardsOrganizationOnboardingCommitmentToSuccess>;
  onboardingChampions?: Maybe<Array<RewardsOrganizationOnboardingChampionInput>>;
  onboardingWantsAutomatedBirthdayRecognition?: Maybe<Scalars['Boolean']>;
  onboardingWantsAutomatedAnniversaryRecognition?: Maybe<Scalars['Boolean']>;
  onboardingTargetLaunchDate?: Maybe<Scalars['Date']>;
  payrollFrequency?: Maybe<RewardsOrganizationPayrollFrequency>;
  leaderboardEmailContact?: Maybe<RewardsOrganizationLeaderboardEmailContactInput>;
};


export type MutationUpdateOrganizationEvvRulesArgs = {
  newRules: EvvRulesInput;
};


export type MutationUpdateMyRewardsOrganizationReferralStructureRewardValuesArgs = {
  input: UpdateReferralStructureInput;
};


export type MutationPartialUpdateMyRewardsOrganizationRedemptionSettingsArgs = {
  minimumPointsNeededToRedeem?: Maybe<Scalars['Int']>;
  redemptionSummaryNotificationEmails?: Maybe<Array<Scalars['String']>>;
};


export type MutationSubmitBulkRewardsUserUploadFileArgs = {
  file: Scalars['Upload'];
};


export type MutationMarkRewardsOrganizationOnboardingStepCompletedArgs = {
  step: RewardsOrganizationOnboardingStepName;
};


export type MutationRecordCompletedRewardsOrganizationLaunchStepArgs = {
  step: RewardsOrganizationLaunchStep;
};


export type MutationAcknowledgePowerHoursOfferEndedArgs = {
  powerHoursOfferId: Scalars['ID'];
};


export type MutationAcknowledgePowerHoursLevelFinishedArgs = {
  powerHoursOfferId: Scalars['ID'];
  levelNumber: Scalars['Int'];
};


export type MutationBulkMarkRedemptionsAsSentToPayrollArgs = {
  redemptionIds: Array<Scalars['ID']>;
};


export type MutationBulkMarkRedemptionsAsPaidArgs = {
  redemptionIds: Array<Scalars['ID']>;
};


export type MutationGenerateRewardsRedemptionExportArgs = {
  redemptionIds: Array<Scalars['ID']>;
};


export type MutationBulkMarkRedemptionsAsGenericGiftCardSentArgs = {
  redemptionIds: Array<Scalars['ID']>;
};


export type MutationRetoolBulkGenerateRedemptionExportArgs = {
  input: Array<BulkGenerateRedemptionExportInput>;
};


export type MutationRejectRedemptionAndDeductPointArgs = {
  input: RejectRedemptionAndDeductPointInput;
};


export type MutationSubmitReportRequestArgs = {
  dataSets: Array<RewardReportDataSetConfigInput>;
};


export type MutationAuthenticateRewardsUserLoginTokenArgs = {
  emailOrPhoneNumber: Scalars['String'];
  loginTokenId?: Maybe<Scalars['String']>;
  loginToken: Scalars['String'];
  clientId: Scalars['String'];
  impersonating?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateRewardsUserTokenReauthChallengeArgs = {
  loginTokenId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};


export type MutationCreateRewardsUserSmsLoginChallengeArgs = {
  phoneNumber: Scalars['String'];
  organizationId?: Maybe<Scalars['ID']>;
};


export type MutationCompleteRewardsUserSmsLoginChallengeArgs = {
  clientId: Scalars['String'];
  challengeId: Scalars['String'];
  verificationCode: Scalars['String'];
};


export type MutationRedeemPointsArgs = {
  amount: Scalars['Int'];
  redemptionMethod?: Maybe<RedemptionMethod>;
};


export type MutationResendGenericGiftCardLinkArgs = {
  redemptionId: Scalars['String'];
};


export type MutationSetMyRewardsUserLocaleArgs = {
  locale: Locale;
};


export type MutationOptInWithPersonalInformationArgs = {
  workEmail: Scalars['String'];
  personalEmail: Scalars['String'];
  personalPhoneNumber: Scalars['String'];
};


export type MutationCreateRewardsUserArgs = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  workEmail?: Maybe<Scalars['String']>;
  workPhoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  externalEmployeeId?: Maybe<Scalars['String']>;
  assignedToUserGroupIds?: Maybe<Array<Scalars['ID']>>;
  branchId?: Maybe<Scalars['ID']>;
  managerId?: Maybe<Scalars['ID']>;
  reassignDeactivatedWorkPhoneNumber?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateRewardsUserInformationArgs = {
  userId: Scalars['ID'];
  workEmail?: Maybe<Scalars['String']>;
  workPhoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  branchId?: Maybe<Scalars['ID']>;
  managerId?: Maybe<Scalars['ID']>;
  active?: Maybe<Scalars['Boolean']>;
  reassignDeactivatedWorkPhoneNumber?: Maybe<Scalars['Boolean']>;
  referralLinkDisabled?: Maybe<Scalars['Boolean']>;
};


export type MutationAdminBulkCreateRewardsUsersArgs = {
  organizationId: Scalars['ID'];
  data: Array<AdminBulkCreateRewardsUserPayload>;
};


export type MutationAdminBulkUpdateRewardsUsersArgs = {
  organizationId: Scalars['ID'];
  data: Array<AdminBulkUpdateRewardsUserPayload>;
};


export type MutationGenerateRewardsUserExportArgs = {
  searchQuery?: Maybe<Scalars['String']>;
  filter?: Maybe<ListRewardsUsersFilterInput>;
};


export type MutationCompleteWelcomeOfferArgs = {
  userId: Scalars['ID'];
};


export type MutationRetoolCreateQaRewardsUserArgs = {
  organizationId: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  hhaxCaregiver?: Maybe<HhaxCaregiverInput>;
};


export type MutationUpdateMyRewardsUserEmailArgs = {
  email: Scalars['String'];
};


export type MutationMarkRewardVideoUploadedArgs = {
  videoId: Scalars['ID'];
};


export type MutationMarkRewardVideoDeletedArgs = {
  videoId: Scalars['ID'];
};


export type MutationCreatePresignedVideoUploadUrlArgs = {
  originalFilename: Scalars['String'];
};


export type MutationRecordVideoWatchByMeArgs = {
  videoId: Scalars['ID'];
};


export type MutationMarkRewardVideoOfferWatchedArgs = {
  videoOfferId: Scalars['ID'];
};


export type MutationAdminBulkCreateOrUpdateVideoOffersArgs = {
  data: Array<AdminBulkCreateOrUpdateVideoOfferInputItem>;
};


export type MutationRetoolCreateQaVisitArgs = {
  personalEmail: Scalars['String'];
  organizationId: Scalars['ID'];
  visit: RetoolQaVisitInput;
};


export type MutationApplyToSchoolsEmployerArgs = {
  employerId: Scalars['ID'];
};


export type MutationSignUpAsSchoolsStudentArgs = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  graduationDate: Scalars['MonthAndYear'];
};


export type MutationAuthenticateSchoolsStudentLoginTokenArgs = {
  phoneNumber: Scalars['String'];
  loginTokenId: Scalars['String'];
  loginToken: Scalars['String'];
  clientId: Scalars['String'];
};


export type MutationCreateSchoolsStudentApplicationArgs = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  preferredName?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  homePostalCode: Scalars['String'];
  travelCapability: SchoolsJobApplicationTravelCapability;
  previousEmployers: Array<Scalars['String']>;
  desiredHours: SchoolsJobApplicationDesiredHours;
  desiredShiftTimesOfDay: Array<SchoolsJobApplicationDesiredShiftTimeOfDay>;
  desiredShiftDaysOfWeek: Array<SchoolsJobApplicationDesiredShiftDaysOfWeek>;
};

export type NotLaunchedInsightDateRanges = {
  __typename?: 'NotLaunchedInsightDateRanges';
  id: Scalars['ID'];
};

export type NumActiveCandidatesByJobPostingAndStatus = {
  __typename?: 'NumActiveCandidatesByJobPostingAndStatus';
  items: Array<NumActiveCandidatesByJobPostingAndStatusItem>;
};

export type NumActiveCandidatesByJobPostingAndStatusItem = {
  __typename?: 'NumActiveCandidatesByJobPostingAndStatusItem';
  jobPosting: JobPosting;
  total: Scalars['Int'];
  totalsByStatus: Array<NumActiveCandidatesByJobPostingAndStatusItemTotal>;
};

export type NumActiveCandidatesByJobPostingAndStatusItemTotal = NumActiveCandidatesByJobPostingAndStatusItemTotalRecruitment | NumActiveCandidatesByJobPostingAndStatusItemTotalRetention;

export type NumActiveCandidatesByJobPostingAndStatusItemTotalRecruitment = {
  __typename?: 'NumActiveCandidatesByJobPostingAndStatusItemTotalRecruitment';
  numActiveCandidates: Scalars['Int'];
  recruitmentStepName: CandidateRecruitmentStepName;
};

export type NumActiveCandidatesByJobPostingAndStatusItemTotalRetention = {
  __typename?: 'NumActiveCandidatesByJobPostingAndStatusItemTotalRetention';
  numActiveCandidates: Scalars['Int'];
  retentionDurationMonths: Scalars['Int'];
};

export type OpenJobPostingFilterInput = {
  searchQuery?: Maybe<Scalars['String']>;
};

export enum OrganizationCandidateStepRewardType {
  Points = 'POINTS',
  DrawTickets = 'DRAW_TICKETS',
  ManualMonthlyRaffle = 'MANUAL_MONTHLY_RAFFLE'
}

export type PointTransaction = {
  __typename?: 'PointTransaction';
  id: Scalars['ID'];
  signedPointValue: Scalars['Int'];
  unsignedPointValue: Scalars['Int'];
  occurredAt: Scalars['Timestamp'];
  description: Scalars['String'];
  type: PointTransactionType;
  reason: PointTransactionReason;
};

export type PointTransactionAwardedAssignedAsCandidateReferrerReason = PointTransactionReason & {
  __typename?: 'PointTransactionAwardedAssignedAsCandidateReferrerReason';
  id: Scalars['ID'];
};

export type PointTransactionAwardedCustomPointsAwardedReason = PointTransactionReason & {
  __typename?: 'PointTransactionAwardedCustomPointsAwardedReason';
  id: Scalars['ID'];
};

export type PointTransactionAwardedDrawPrizeWinnerReason = PointTransactionReason & {
  __typename?: 'PointTransactionAwardedDrawPrizeWinnerReason';
  id: Scalars['ID'];
};

export type PointTransactionAwardedJobPostingSharedReason = PointTransactionReason & {
  __typename?: 'PointTransactionAwardedJobPostingSharedReason';
  id: Scalars['ID'];
};

export type PointTransactionAwardedOptInReason = PointTransactionReason & {
  __typename?: 'PointTransactionAwardedOptInReason';
  id: Scalars['ID'];
};

export type PointTransactionAwardedRecognitionPointsReason = PointTransactionReason & {
  __typename?: 'PointTransactionAwardedRecognitionPointsReason';
  id: Scalars['ID'];
  recognitionPointsId?: Maybe<Scalars['ID']>;
};

export type PointTransactionAwardedReferralAppliedReason = PointTransactionReason & {
  __typename?: 'PointTransactionAwardedReferralAppliedReason';
  id: Scalars['ID'];
};

export type PointTransactionAwardedReferralCompletedFirstShiftReason = PointTransactionReason & {
  __typename?: 'PointTransactionAwardedReferralCompletedFirstShiftReason';
  id: Scalars['ID'];
};

export type PointTransactionAwardedReferralCompletedOrientationReason = PointTransactionReason & {
  __typename?: 'PointTransactionAwardedReferralCompletedOrientationReason';
  id: Scalars['ID'];
};

export type PointTransactionAwardedReferralHiredReason = PointTransactionReason & {
  __typename?: 'PointTransactionAwardedReferralHiredReason';
  id: Scalars['ID'];
};

export type PointTransactionAwardedReferralPassedInterviewReason = PointTransactionReason & {
  __typename?: 'PointTransactionAwardedReferralPassedInterviewReason';
  id: Scalars['ID'];
};

export type PointTransactionAwardedReferralReachedHourlyRetentionMilestoneReason = PointTransactionReason & {
  __typename?: 'PointTransactionAwardedReferralReachedHourlyRetentionMilestoneReason';
  id: Scalars['ID'];
  durationHours?: Maybe<Scalars['Int']>;
};

export type PointTransactionAwardedReferralReachedRetentionMilestoneReason = PointTransactionReason & {
  __typename?: 'PointTransactionAwardedReferralReachedRetentionMilestoneReason';
  id: Scalars['ID'];
  durationMonths?: Maybe<Scalars['Int']>;
};

export type PointTransactionAwardedReferralStartedOrientationReason = PointTransactionReason & {
  __typename?: 'PointTransactionAwardedReferralStartedOrientationReason';
  id: Scalars['ID'];
};

export type PointTransactionAwardedRewardOfferCompletedReason = PointTransactionReason & {
  __typename?: 'PointTransactionAwardedRewardOfferCompletedReason';
  id: Scalars['ID'];
  rewardOfferId: Scalars['ID'];
};

export type PointTransactionAwardedShiftMilestoneCompletedReason = PointTransactionReason & {
  __typename?: 'PointTransactionAwardedShiftMilestoneCompletedReason';
  id: Scalars['ID'];
};

export type PointTransactionAwardedWelcomeOfferCompletedReason = PointTransactionReason & {
  __typename?: 'PointTransactionAwardedWelcomeOfferCompletedReason';
  id: Scalars['ID'];
};

export type PointTransactionDeductedCustomPointsDeductedReason = PointTransactionReason & {
  __typename?: 'PointTransactionDeductedCustomPointsDeductedReason';
  id: Scalars['ID'];
};

export type PointTransactionDeductedDuplicateReferralReason = PointTransactionReason & {
  __typename?: 'PointTransactionDeductedDuplicateReferralReason';
  id: Scalars['ID'];
};

export type PointTransactionDeductedHourlyRetentionMilestoneReversedReason = PointTransactionReason & {
  __typename?: 'PointTransactionDeductedHourlyRetentionMilestoneReversedReason';
  id: Scalars['ID'];
};

export type PointTransactionDeductedRecruitmentStepReversedReason = PointTransactionReason & {
  __typename?: 'PointTransactionDeductedRecruitmentStepReversedReason';
  id: Scalars['ID'];
};

export type PointTransactionDeductedRetentionMilestoneReversedReason = PointTransactionReason & {
  __typename?: 'PointTransactionDeductedRetentionMilestoneReversedReason';
  id: Scalars['ID'];
};

export type PointTransactionDeductedUnassignedAsCandidateReferrerReason = PointTransactionReason & {
  __typename?: 'PointTransactionDeductedUnassignedAsCandidateReferrerReason';
  id: Scalars['ID'];
};

export type PointTransactionGoalAchievedReason = PointTransactionReason & {
  __typename?: 'PointTransactionGoalAchievedReason';
  id: Scalars['ID'];
};

export type PointTransactionReason = {
  id: Scalars['ID'];
};

export enum PointTransactionType {
  Awarded = 'AWARDED',
  Deducted = 'DEDUCTED'
}

export type PointsAwardedInsightMetric = {
  __typename?: 'PointsAwardedInsightMetric';
  id: Scalars['ID'];
  /**
   * Many point values are non-negative across the app, however this may be zero / negative,
   * for example, if the organization had a net point loss in a given period.
   */
  pointsAwarded: Scalars['Int'];
};

export type PointsRedeemedInsightMetric = {
  __typename?: 'PointsRedeemedInsightMetric';
  id: Scalars['ID'];
  pointsRedeemed: Scalars['Int'];
};

export type PowerHoursHourCondition = PowerHoursWeekendHoursCondition;

export type PowerHoursLevel = {
  __typename?: 'PowerHoursLevel';
  id: Scalars['ID'];
  /**
   * The index of this level within the offer.
   *
   * It is a 1-based index (the first level is `1`).
   */
  levelNumber: Scalars['Int'];
  /** The total number of hours required to finish this level. */
  hours: Scalars['Int'];
  /**
   * The total number of points that the user will earn if this is the level
   * that they end on.
   */
  pointValue: Scalars['Int'];
  /** If `true`, the user has finished this level. */
  finished: Scalars['Boolean'];
  /**
   * This is the current level that the user is trying to complete.
   * If `true`, this is the first level that the user has not finished.
   */
  current: Scalars['Boolean'];
  /**
   * If `true`, the user has acknowledged that they finished this level
   * (via the UI).
   */
  acknowledgedLevelFinished: Scalars['Boolean'];
};

export type PowerHoursOffer = {
  __typename?: 'PowerHoursOffer';
  id: Scalars['ID'];
  hourCondition?: Maybe<PowerHoursHourCondition>;
  /** The start of the range in which hours count towards this offer. */
  rangeStart: Scalars['Timestamp'];
  /**
   * The end of the range in which hours count towards this offer.
   *
   * This is also the timestamp at which the offer ends, and the user
   * earns their reward (if any).
   */
  rangeEnd: Scalars['Timestamp'];
  /** The total number of levels the user can complete for this offer. */
  totalNumLevels: Scalars['Int'];
  /**
   * The "current" level that the user is on.
   * If the user hasn't finished any levels yet, this will be `1`.
   * If `null`, the user has completed all levels.
   */
  currentLevelNumber?: Maybe<Scalars['Int']>;
  /** All levels for this offer. */
  levels: Array<PowerHoursLevel>;
  /** The number of hours the user has completed towards this offer. */
  trackedNumCompletedHours: Scalars['Int'];
  /**
   * If `true`, the offer has ended (likely because the `rangeEnd` has elapsed),
   * and the user should have been awarded their points.
   */
  ended: Scalars['Boolean'];
};

/** @deprecated */
export enum PowerHoursPeriodFrequency {
  Weekly = 'WEEKLY'
}

/** @deprecated */
export type PowerHoursProgress = {
  __typename?: 'PowerHoursProgress';
  userId: Scalars['ID'];
  /** Number between 0 and 100 */
  percentageCompleted: Scalars['Float'];
  hoursWorked: Scalars['Float'];
  hoursLeftToComplete: Scalars['Float'];
  hoursRequired: Scalars['Float'];
  periodFrequency: PowerHoursPeriodFrequency;
  pointsToEarn: Scalars['Int'];
};

export type PowerHoursWeekendHoursCondition = {
  __typename?: 'PowerHoursWeekendHoursCondition';
  id: Scalars['ID'];
};

export type Query = {
  __typename?: 'Query';
  getBulkImportStatus?: Maybe<BulkImportStatusResponse>;
  status?: Maybe<Status>;
  getTimezoneOptions: Array<TimezoneOption>;
  /**
   * Resolves with information about the logged in user's identity.
   * This includes all roles that the identity has.
   */
  getMyIdentity?: Maybe<Identity>;
  /**
   * Resolves with information about the role of the logged in user.
   * If the user is not logged in, resolves with `null`.
   */
  getMyAssumedRole: IdentityAssumedRole;
  /** @deprecated Replaced by `getMyAssumedRole` and `getMyIdentity` */
  getMyUserIdentity?: Maybe<UserIdentity>;
  /**
   * Resolves with the authenticated admin's user.
   * If the authenticated user is not a REWARDS_ADMIN, `null` will be returned.
   */
  getMyRewardsAdminUser?: Maybe<RewardsAdminUser>;
  getRewardsAdminById?: Maybe<RewardsAdminUser>;
  listRewardsAdmins: ListRewardsAdminsResponse;
  /**
   * Allows a rewards admin or rewards superuser to view
   * their MFA status.
   */
  getMyRewardsAdminMfaStatus: GetMyRewardsAdminMfaStatusResponse;
  /** @deprecated This is no longer needed */
  searchForRewardsAdminUsers: Array<RewardsAdminUser>;
  getRewardsAdminAuthStatus: GetRewardsAdminAuthStatusResponse;
  getEnabledAlayaCareIntegrations: Array<AlayaCareIntegration>;
  getCaribouUserSyncFilterOptionsForAlayaCareIntegration?: Maybe<AlayaCareCaribouUserSyncFilters>;
  getRewardsAppBaseUrls: RewardsBaseUrls;
  /** Return a list of all the unique senders (from) */
  getAllRecognitionSendersForOrganization?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**
   * Allows a rewards admin to get bonus points by its id
   * @deprecated Use `getRecognitionPointsById` instead
   */
  getAwardedBonusPointsById?: Maybe<AwardedBonusPoints>;
  /** Allows a rewards admin to get recognition points by its id */
  getRecognitionPointsById?: Maybe<RecognitionPoints>;
  /**
   * Allows a rewards admin to list all bonus points that have been SENT
   * @deprecated Use `listSentRecognitionPoints` instead
   */
  listAwardedBonusPoints?: Maybe<ListAwardedBonusPointsResponse>;
  /** Allows a rewards admin to list all bonus points that have been SENT */
  listSentRecognitionPoints: ListSentRecognitionPointsResponse;
  /**
   * Allows a rewards admin to list all bonus poinst that are SCHEDULED,
   * and are assigned to the admin to add a message.
   */
  listMyManagerScheduledRecognitionPoints: ListMyManagerScheduledRecognitionPointsResponse;
  /**
   * Allows a rewards user to get any currently claimable bonus points
   * @deprecated Use `getMyEmployeeClaimableRecognitionPoints` instead
   */
  getClaimableBonusPoints: Array<AwardedBonusPoints>;
  /** Allows a rewards user to get any currently claimable recognition points */
  getMyEmployeeClaimableRecognitionPoints: Array<RecognitionPoints>;
  /**
   * Allows a rewards user to get any scheduled recognition points
   * that are currently scheduled to be sent, or have already been sent.
   */
  getMyEmployeePendingAndSentScheduledRecognitionPoints: Array<RecognitionPoints>;
  /** @deprecated Use getMyRewardsOrganizationRecognitionCategories instead */
  getMyRewardsOrganizationBonusPointCategories: Array<RewardsBonusPointCategory>;
  getMyRewardsOrganizationRecognitionCategories: Array<RecognitionCategory>;
  listRecognitionCategories: ListRecognitionCategoriesResponse;
  /**
   * Gets a recognition category by ID.
   * Returns `null` if the category doesn't exist.
   */
  getRecognitionCategoryById?: Maybe<RecognitionCategory>;
  /**
   * Allows a rewards admin to get stats for recognition points sent by them,
   * including information about their budget.
   * @deprecated Use `getMyRecognitionPointsSentSummaryV2` instead
   */
  getMyRecognitionPointsSentSummary: GetMyRecognitionPointsSentSummaryResponse;
  /**
   * Allows a rewards admin to get stats for recognition points sent by them,
   * including information about their budget.
   */
  getMyRecognitionPointsSentSummaryV2: GetMyRecognitionPointsSentSummaryV2Response;
  /**
   * Allows a rewards admin to get stats for recognition points received by
   * their managed employees.
   * @deprecated Use `getMyManagedEmployeesRecognitionPointsReceivedSummaryV2` instead
   */
  getMyManagedEmployeesRecognitionPointsReceivedSummary: GetMyManagedEmployeesRecognitionPointsReceivedSummary;
  /**
   * Allows a rewards admin to get stats for recognition points received by
   * their managed employees.
   */
  getMyManagedEmployeesRecognitionPointsReceivedSummaryV2: GetMyManagedEmployeesRecognitionPointsReceivedSummaryV2Response;
  /**
   * Allows a rewards admin to page through recognition stats per employee
   * for their managed employees.
   * @deprecated Use `listMyManagedEmployeesRecognitionSummaryV2` instead
   */
  listMyManagedEmployeesRecognitionSummary: ListMyManagedEmployeesRecognitionSummaryResponse;
  /**
   * Allows a rewards admin to page through recognition stats per employee
   * for their managed employees.
   */
  listMyManagedEmployeesRecognitionSummaryV2: ListMyManagedEmployeesRecognitionSummaryV2Response;
  /**
   * Allows a rewards admin to page through recognition stats per employee
   * for their managed employees.
   */
  listMyRecognitionBudgetPeriods: ListMyRecognitionBudgetPeriodsResponse;
  /** @deprecated Use listCandidatesV2 instead */
  listCandidates: ListCandidatesResponse;
  listCandidatesV2: ListCandidatesResponse;
  /**
   * Allows a rewards admin to get a candidate by its id.
   *
   * Returns null if the candidate does not exist.
   */
  getCandidateById?: Maybe<Candidate>;
  getNumActiveCandidatesByJobPostingAndStatus: NumActiveCandidatesByJobPostingAndStatus;
  /** @deprecated Use getMyReferralsProgressV2 */
  getMyReferralsProgress: ReferralsProgressResponse;
  /** Allows a rewards user to view the progress of all of their active referrals. */
  getMyReferralsProgressV2: ReferralsProgressV2Response;
  /**
   * Gets the "current" draw for the authenticated REWARDS_USER
   * at their organization.
   *
   * The "current draw" is one of the following:
   *   - The running draw
   *   - The next draw that is scheduled
   *   - The last draw that closed (if there are no scheduled draws)
   */
  getMyCurrentDraw?: Maybe<Draw>;
  /**
   * Gets the currently-running Caribou-sponsored draw.
   * This draw runs monthly, across all orgs.
   * As of writing, draws run monthly.
   */
  getMyCurrentCaribouSponsoredDraw?: Maybe<CaribouSponsoredDraw>;
  /** Gets the most recent draw that closed at the organization. */
  getLastDraw?: Maybe<Draw>;
  /**
   * Allows a rewards user to query the number of tickets
   * that they have spent on the provided draw.
   */
  countMyTicketsInDraw: CountMyTicketsInDrawResponse;
  /**
   * Allows a rewards user to query the ways that
   * they are able to earn draw tickets
   */
  getMyDrawWaysToEarn: Array<DrawWayToEarn>;
  /** @deprecated Use `getAllDraws` instead, and transform client-side */
  getAllDrawsByScheduleStatus: GetAllDrawsByScheduleStatusResponse;
  /**
   * Gets a draw by its ID.
   * Resolves with `null` if the draw doesn't exist on the schedule.
   */
  getDrawById?: Maybe<Draw>;
  /** Allows an admin to view all of the draws at their org */
  getAllDraws: Array<Draw>;
  /**
   * Allows a rewards user to view all past draws at their org.
   * This is useful so they can navigate to the winners of past draws.
   */
  getAllPastDraws: Array<Draw>;
  /**
   * Gets the total number of unclaimed draws for the user.
   * This query requires the rewards users to be authenticated.
   */
  getMyTotalUnclaimedDraws: GetMyTotalUnclaimedDrawsResponse;
  /**
   * Gets the latest unclaimed draw for the user.
   * This query requires the rewards users to be authenticated.
   */
  getMyLatestUnclaimedDraw?: Maybe<Draw>;
  getEVVCompletionRateOfferById?: Maybe<EvvCompletionRateOffer>;
  /**
   * Allows a rewards user to list their EVV completion rate offers.
   * This only includes EVV completion rate offers which are not deleted.
   */
  listMyEVVCompletionRateOffers: Array<EvvCompletionRateOffer>;
  /**
   * Resolves all of the `AVAILABLE` goals for the authenticated Rewards User.
   * These can be displayed on the home screen.
   */
  getMyRewardsUserAvailableGoals: Array<Goal>;
  /**
   * Resolves the _next_ `LOCKED` goals for the authenticated Rewards User.
   * There may be _more_ locked goals other than these - but these are
   * the goals that are unlocked soon (based on time, or when an available goal is completed)
   */
  getMyRewardsUserNextLockedGoals: Array<Goal>;
  /**
   * Resolves a subset of achieved goals for the authenticated Rewards User to be displayed on the home page.
   * As of writing, this includes the welcome video.
   */
  getMyRewardsUserAchievedGoalsForHomePage: Array<Goal>;
  /**
   * Resolves with the goal track for the authenticated Rewards User
   * matching the given `trackName`.
   */
  getMyRewardsUserGoalTrack: GetMyRewardsUserGoalTrackResponse;
  /** Resolves all achieved goals for a user that have not yet been acknowledged as achieved. */
  getMyRewardsUserGoalsWithUnacknowledgedAchievement: Array<Goal>;
  /**
   * For Rewards Users to query:
   *
   * Given a `videoId` (the ID for a `Video`),
   * resolves an available `Goal` that will be achieved
   * once this video is watched.
   */
  getMyAvailableGoalForVideoId?: Maybe<Goal>;
  /**
   * Lists rewards tags
   * Tags are generic first-class thing that any resource in Caribou system can have.
   */
  listRewardsTags: ListRewardsTagsResponse;
  /**
   * Lists rewards groups
   * Groups have a config of tags to evaluate membership of entities in the Caribou system.
   */
  listRewardsGroups: ListRewardsGroupsResponse;
  getEnabledHHAXIntegrations: Array<HhaxIntegration>;
  getCaregiversToSyncForHHAXIntegration?: Maybe<GetHhaxCaregiversToSyncResponse>;
  getCaribouUserSyncFilterOptionsForHHAXIntegration?: Maybe<HhaxCaribouUserSyncFilters>;
  getHHAXCaribouUserSyncById: HhaxCaribouUserSync;
  /**
   * Allows admins to view the date ranges for which insights data is available,
   * ordered by most recent (current date) to the oldest (organization launch date).
   */
  getAvailableInsightDateRanges: InsightDateRanges;
  /**
   * Allows admins to get the number of active users at a given date.
   * For metrics over a date range, this is the date range end.
   */
  getActiveUsersInsightMetric: ActiveUsersInsightMetric;
  /**
   * Allows admins to get the number of users who logged in at least once during a given date range.
   * Also returns the total number of active users at the point in time of the given end date, so the two metrics can be compared.
   */
  getLoggedInUsersInsightMetric: LoggedInUsersInsightMetric;
  /** Allows admins to get the total number of points awarded during a given date range. Does not include unclaimed points. */
  getPointsAwardedInsightMetric: PointsAwardedInsightMetric;
  /** Allows admins to get the total number of points redeemed during a given date range. Does not include unclaimed points. */
  getPointsRedeemedInsightMetric: PointsRedeemedInsightMetric;
  /** Allows admins to get the total number of candidates hired during a given date range. */
  getTotalHiresInsightMetric: TotalHiresInsightMetric;
  /**
   * Allows admins to get the cost of referrals worked in a given date range by hired candidates.
   * Candidates do not necessarily need to have been hired during the date range.
   */
  getCostOfReferralsInsightMetric: CostOfReferralsInsightMetric;
  /**
   * Allows admins to get the hours worked in a given date range by hired candidates.
   * Hired candidates include those hired before the start of the date range.
   */
  getReferralHoursWorkedInsightMetric: ReferralHoursWorkedInsightMetric;
  /**
   * Allows admins to get the number of recognized employees during a given date range.
   * A recognized employee is an employee who received recognition bonus points at least once.
   */
  getRecognizedEmployeesInsightMetric: RecognizedEmployeesInsightMetric;
  /** Allows admins to get the total recognition points awarded to employees during a given date range. */
  getRecognitionAwardedInsightMetric: RecognitionPointsInsightMetric;
  /** Allows admins to get bonus points sent by admins with budgets and the admin budget total during a given date range. */
  getBudgetUsageInsightMetric: BudgetUsageInsightMetric;
  /** Allows admins to get the number of admins who have used any of their budget during a given date range. */
  getAdminsUsingRecognitionInsightMetric: AdminsUsingRecognitionInsightMetric;
  /**
   * Public query which allows an applicant to see if a resume has been uploaded
   * Either `applicationSubmissionId` or `candidateId` must be provided
   */
  getApplicantHasUploadedResume: Scalars['Boolean'];
  /**
   * Public query which allows an applicant to see if a referral link is enabled
   * Either `jobShareId` or `userId` and `organizationId` must be provided
   */
  getJobShareLinkDisabledForUser: GetJobShareLinkDisabledForUserResponse;
  /**
   * For Rewards Users only.
   * Lists shareable (open) job postings for the user's organizaiton.
   */
  listShareableJobPostings?: Maybe<JobPostingListResponse>;
  getSharableJobPostingFilterOptions: GetSharableJobPostingFilterOptions;
  /** Public query. Gets a job posting by its id. */
  getJobPostingById?: Maybe<JobPosting>;
  /**
   * Public query.
   * Gets a job posting by a job share id.
   */
  getJobPostingByShareId?: Maybe<JobPosting>;
  /**
   * Public query.
   * Allows an applicant to search for a different job to apply for at the organization.
   */
  listOpenJobPostings: JobPostingListResponse;
  /**
   * Public query.
   * Gets a job posting by an application submission id or candidate id.
   */
  getJobPostingByApplicationSubmission?: Maybe<JobPosting>;
  /**
   * For Rewards Admins only.
   * Lists all job postings (including closed job postings).
   */
  listJobPostings: JobPostingListResponse;
  /** Lists unique candidate alert emails currently assigned to job postings. */
  listUniqueCandidateAlertEmails: ListUniqueCandidateAlertEmailsResponse;
  /** @deprecated Use listJobPostings instead */
  searchForOpenJobPostings: Array<JobPosting>;
  /**
   * For rewards users.
   * Returns the number of jobs shared by the user this month.
   */
  countMyJobSharesThisMonth: CountMyJobSharesForRange;
  /** @deprecated This was used for the draws MVP to count tickets earned from job shares. It has been replaced by the `countMyTicketsInDraw` query, which includes the draw ticket count. This query always resolves with `0`, and is only here for API backwards compatibility. */
  countMyJobSharesInRange: CountMyJobSharesForRange;
  getRewardsLeaderboard: RewardsLeaderboard;
  getMyRewardsUserMilestoneRewards?: Maybe<UserMilestoneRewards>;
  listMyRewardNotifications: Array<RewardNotification>;
  /**
   * Allows a rewards user to list their reward active offers.
   * Revoked offers are not included.
   */
  listMyRewardOffers: Array<RewardOffer>;
  /** Allows a superuser to list reward offers */
  adminListRewardOffers: AdminlistRewardOffersResponse;
  adminGetRewardOfferStats?: Maybe<AdminGetRewardOfferStatsResponse>;
  /** Public query (although many organization fields are private) */
  getRewardsOrganizationById?: Maybe<RewardsOrganization>;
  /** Public query (although many organization fields are private) */
  getRewardsOrganizationsByIds?: Maybe<Array<Maybe<RewardsOrganization>>>;
  getMyRewardsOrganization: RewardsOrganization;
  /**
   * Fetches an opt-in promo for a given organization id and work email (if exists)
   *
   * This is a public query (doesn't require authentication).
   */
  getRewardsOrganizationOptInPromo?: Maybe<RewardsOrganizationOptInPromo>;
  adminListRewardsOrganizations: AdminListRewardsOrganizationsResponse;
  /**
   * Public query to fetch a rewards organization.
   * `applicationSubmissionId` or `candidateId` is required.
   */
  getRewardsOrganizationByApplicationSubmission?: Maybe<RewardsOrganization>;
  /** Public query to fetch a rewards organization for a given work email. */
  getRewardsOrganizationByWorkEmail?: Maybe<RewardsOrganization>;
  /**
   * For rewards admins only.
   * Fetches stats about the organization.
   */
  getRewardsOrganizationStats?: Maybe<RewardsOrganizationStats>;
  /**
   * Query to retrieve the EVV rules for the organization.
   * The `evvRules` field will always return a non-null `RewardsOrganizationEVVRules` object.
   */
  getMyRewardsOrganizationEVVRules: RewardsOrganizationEvvRules;
  /**
   * Returns the default rewards organization referral structure
   * @deprecated Use RewardsOrganization.referralRewardStructure instead
   */
  getDefaultRewardsOrganizationReferralStructure: Array<ReferralRewardStructureItem>;
  getPowerHoursOfferById?: Maybe<PowerHoursOffer>;
  /**
   * Allows a rewards user to list their power hours.
   * This only includes power hours which are not deleted.
   */
  listMyPowerHoursOffers: Array<PowerHoursOffer>;
  /** @deprecated This is no longer used */
  getMyPowerHoursProgress?: Maybe<PowerHoursProgress>;
  /** Lists top gift cards in Tango */
  listGiftCardOptions?: Maybe<ListGiftCardOptionsResponseResponse>;
  /** Lists redemptions ordered by date created (descending) */
  listRedemptions: RedemptionsResponse;
  /**
   * Allows a REWARDS_USER to view their redemptions,
   * ordered by most recently requested
   */
  getMyRewardsUserRedemptions: GetMyRewardsUserRedemptionsResponse;
  /** Lists redemptions with transactions */
  retoolListRedemptionsByCursor: ListRedemptionsByCursorResponse;
  /** Returns redemption exports with given Ids */
  retoolGetRedemptionExportsByIds: RetoolGetRedemptionExportsByIdsResponse;
  /**
   * Allows a rewards user to get their latest shift milestone details.
   * If null is returned, shift mielstones are disabled for this user.
   */
  getMyShiftMilestones?: Maybe<RewardsUserShiftMilestoneDetails>;
  /**
   * Allows admins to view a summary of survey engagement at their organization,
   * for a specific survey type and date range.
   * If no date range is provided, will return all-time engagement data.
   */
  getSurveyEngagement: SurveyEngagementResponse;
  /**
   * Allows admins to view the average overall score (average of all questions)
   * at their organization, for a specific survey type and date range.
   * Includes comparison to the date range previous to the one requested, if available.
   * If no date range is provided, will return all-time average.
   */
  getSurveyAverageScore: SurveyAverageScoreResponse;
  /**
   * Allows admins to view overall results for a specific question and survey type
   * at their organization, for a given date range.
   * Repsonse includes the average score for the question from up to 5 date ranges
   * prior to the requested date range, if available.
   * If no date range is provided, will return all-time average and no comparisons.
   */
  getSurveyQuestionResults: SurveyQuestionResultsResponse;
  /**
   * Allows admins to view all comments (with the associated score) for a given question
   * and survey type at their organization, with an optional filters on score value and/or
   * date range.
   * Responses are paginated and returned by most recent first.
   *
   * Note: NPS is not a supported question type for this query, as there is no associated comment.
   */
  listCommentsForSurveyQuestion: ListSurveyCommentsResponse;
  /**
   * Allows admins to view the number of responses that awarded each score to a specific question,
   * for the given survey type at their organization, in a given date range.
   * Response is ordered by highest to lowest score.
   * If no date range is provided, will return all-time score distribution.
   */
  getScoreDistributionForSurveyQuestion: Array<SurveyQuestionScoreCount>;
  /**
   * Allows admins to view the date ranges for which survey insights data
   * is available, ordered by most recent (current date) to the oldest (organization launch date).
   */
  getAvailableSurveyDateRanges: Array<SurveyInsightsDateRange>;
  /**
   * Allows a rewards user to get their obscured phone number
   * when re-authing (after a login link failed to authenticate them)
   *
   * A `loginTokenId` or `userId` can be provided in order to
   * identify the user to be logged in.
   */
  getRewardsUserLoginTokenDetails: GetRewardsUserLoginTokenDetailsResponse;
  getMyRewardsUser: RewardsUser;
  /** Allows a rewards Admin to search for rewards users in their organization. */
  searchForRewardsUsers: Array<RewardsUser>;
  listRewardsUsers: ListRewardsUsersResponse;
  getRewardsUserById?: Maybe<RewardsUser>;
  /** Determines if the given user id exists in the given organization. */
  getRewardsUserExistsForOrganization?: Maybe<GetRewardsUserExistsForIdAndOrganizationResponse>;
  /** Yields referral-related stats for a given rewards user */
  getReferralStatsForUser: RewardsUserReferralStats;
  listRewardsUserGroups: ListRewardsUserGroupResponse;
  getRewardVideoById?: Maybe<RewardVideo>;
  getRewardVideoOfferById?: Maybe<RewardVideoOffer>;
  /** Allows a superuser to list reward video offers */
  adminListRewardVideoOffers: AdminListRewardOffersResponse;
  /**
   * Lists a reward user's visits starting from a specified date.
   * Supports pagination with a cursor.
   */
  listMyVisitsAsOfStartDate: ListMyVisitsAsOfStartDateResponse;
  getSchoolsEmployerById?: Maybe<SchoolsEmployer>;
  getSchoolsEmployerSurveyById?: Maybe<SchoolsEmployerSurvey>;
  listSchoolsEmployerSurveys: ListSchoolsEmployerSurveysResponse;
  getMySchoolsStudent: SchoolsStudent;
};


export type QueryGetBulkImportStatusArgs = {
  bulkImportId: Scalars['ID'];
};


export type QueryGetRewardsAdminByIdArgs = {
  id: Scalars['ID'];
};


export type QueryListRewardsAdminsArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  filter?: Maybe<RewardsAdminListFilterInput>;
};


export type QuerySearchForRewardsAdminUsersArgs = {
  limit: Scalars['Int'];
};


export type QueryGetRewardsAdminAuthStatusArgs = {
  adminId: Scalars['ID'];
};


export type QueryGetCaribouUserSyncFilterOptionsForAlayaCareIntegrationArgs = {
  alayaCareIntegrationId: Scalars['ID'];
};


export type QueryGetAwardedBonusPointsByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetRecognitionPointsByIdArgs = {
  id: Scalars['ID'];
};


export type QueryListAwardedBonusPointsArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  filter?: Maybe<ListAwardedBonusPointsFilter>;
};


export type QueryListSentRecognitionPointsArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  filter?: Maybe<ListSentRecognitionPointsFilter>;
};


export type QueryListMyManagerScheduledRecognitionPointsArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  filter?: Maybe<ListMyManagerScheduledRecognitionPointsFilter>;
};


export type QueryGetMyRewardsOrganizationRecognitionCategoriesArgs = {
  filter?: Maybe<GetMyRewardsOrganizationRecognitionCategoriesFilter>;
};


export type QueryListRecognitionCategoriesArgs = {
  limit: Scalars['Int'];
  offset?: Maybe<Scalars['Int']>;
};


export type QueryGetRecognitionCategoryByIdArgs = {
  recognitionCategoryId: Scalars['ID'];
};


export type QueryGetMyRecognitionPointsSentSummaryArgs = {
  month: Scalars['MonthAndYear'];
};


export type QueryGetMyRecognitionPointsSentSummaryV2Args = {
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};


export type QueryGetMyManagedEmployeesRecognitionPointsReceivedSummaryArgs = {
  month: Scalars['MonthAndYear'];
};


export type QueryGetMyManagedEmployeesRecognitionPointsReceivedSummaryV2Args = {
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};


export type QueryListMyManagedEmployeesRecognitionSummaryArgs = {
  filter: ListMyManagedEmployeesRecognitionSummaryFilterInput;
  page: ListMyManagedEmployeesRecognitionSummaryPageInput;
};


export type QueryListMyManagedEmployeesRecognitionSummaryV2Args = {
  filter?: Maybe<ListMyManagedEmployeesRecognitionSummaryV2FilterInput>;
  page: ListMyManagedEmployeesRecognitionSummaryV2PageInput;
};


export type QueryListCandidatesArgs = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  filter: ListCandidatesFilterInput;
};


export type QueryListCandidatesV2Args = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  order?: Maybe<ListCandidatesV2OrderInput>;
  filter?: Maybe<ListCandidatesV2FilterInput>;
};


export type QueryGetCandidateByIdArgs = {
  candidateId: Scalars['ID'];
};


export type QueryGetNumActiveCandidatesByJobPostingAndStatusArgs = {
  branchIds?: Maybe<Array<Scalars['ID']>>;
};


export type QueryGetMyReferralsProgressV2Args = {
  limit?: Maybe<Scalars['Int']>;
  statusIn?: Maybe<Array<ReferralStatusV2>>;
};


export type QueryCountMyTicketsInDrawArgs = {
  drawId: Scalars['ID'];
};


export type QueryGetDrawByIdArgs = {
  drawId: Scalars['ID'];
};


export type QueryGetEvvCompletionRateOfferByIdArgs = {
  evvCompletionRateOfferId: Scalars['ID'];
};


export type QueryListMyEvvCompletionRateOffersArgs = {
  filter?: Maybe<ListMyEvvCompletionRateOffersFilter>;
};


export type QueryGetMyRewardsUserGoalTrackArgs = {
  trackName: GoalTrackName;
};


export type QueryGetMyAvailableGoalForVideoIdArgs = {
  videoId: Scalars['ID'];
};


export type QueryListRewardsTagsArgs = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};


export type QueryListRewardsGroupsArgs = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};


export type QueryGetCaregiversToSyncForHhaxIntegrationArgs = {
  hhaxIntegrationId: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  filters: HhaxCaribouUserSyncFiltersInput;
};


export type QueryGetCaribouUserSyncFilterOptionsForHhaxIntegrationArgs = {
  hhaxIntegrationId: Scalars['ID'];
};


export type QueryGetHhaxCaribouUserSyncByIdArgs = {
  userSyncId: Scalars['ID'];
};


export type QueryGetActiveUsersInsightMetricArgs = {
  endDate: Scalars['Timestamp'];
};


export type QueryGetLoggedInUsersInsightMetricArgs = {
  startDate: Scalars['Timestamp'];
  endDate: Scalars['Timestamp'];
};


export type QueryGetPointsAwardedInsightMetricArgs = {
  startDate: Scalars['Timestamp'];
  endDate: Scalars['Timestamp'];
};


export type QueryGetPointsRedeemedInsightMetricArgs = {
  startDate: Scalars['Timestamp'];
  endDate: Scalars['Timestamp'];
};


export type QueryGetTotalHiresInsightMetricArgs = {
  startDate: Scalars['Timestamp'];
  endDate: Scalars['Timestamp'];
};


export type QueryGetCostOfReferralsInsightMetricArgs = {
  startDate: Scalars['Timestamp'];
  endDate: Scalars['Timestamp'];
};


export type QueryGetReferralHoursWorkedInsightMetricArgs = {
  startDate: Scalars['Timestamp'];
  endDate: Scalars['Timestamp'];
};


export type QueryGetRecognizedEmployeesInsightMetricArgs = {
  startDate: Scalars['Timestamp'];
  endDate: Scalars['Timestamp'];
};


export type QueryGetRecognitionAwardedInsightMetricArgs = {
  startDate: Scalars['Timestamp'];
  endDate: Scalars['Timestamp'];
};


export type QueryGetBudgetUsageInsightMetricArgs = {
  startDate: Scalars['Timestamp'];
  endDate: Scalars['Timestamp'];
};


export type QueryGetAdminsUsingRecognitionInsightMetricArgs = {
  startDate: Scalars['Timestamp'];
  endDate: Scalars['Timestamp'];
};


export type QueryGetApplicantHasUploadedResumeArgs = {
  applicationSubmissionId?: Maybe<Scalars['ID']>;
  candidateId?: Maybe<Scalars['ID']>;
  applicantUploadToken: Scalars['String'];
};


export type QueryGetJobShareLinkDisabledForUserArgs = {
  jobShareId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  organizationId?: Maybe<Scalars['ID']>;
};


export type QueryListShareableJobPostingsArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  filters?: Maybe<ListShareableJobPostingsFilters>;
  searchQuery?: Maybe<Scalars['String']>;
};


export type QueryGetJobPostingByIdArgs = {
  jobPostingId: Scalars['ID'];
};


export type QueryGetJobPostingByShareIdArgs = {
  jobShareId: Scalars['ID'];
};


export type QueryListOpenJobPostingsArgs = {
  organizationId: Scalars['ID'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  filter?: Maybe<OpenJobPostingFilterInput>;
};


export type QueryGetJobPostingByApplicationSubmissionArgs = {
  applicationSubmissionId?: Maybe<Scalars['ID']>;
  candidateId?: Maybe<Scalars['ID']>;
};


export type QueryListJobPostingsArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  filter?: Maybe<JobPostingFilterInput>;
  includeFromSiblingOrganizations?: Maybe<Scalars['Boolean']>;
};


export type QuerySearchForOpenJobPostingsArgs = {
  searchQuery?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCountMyJobSharesInRangeArgs = {
  start: Scalars['Timestamp'];
  end: Scalars['Timestamp'];
};


export type QueryListMyRewardOffersArgs = {
  filter?: Maybe<ListMyRewardOffersFilter>;
};


export type QueryAdminListRewardOffersArgs = {
  userId?: Maybe<Scalars['ID']>;
  organizationId?: Maybe<Scalars['ID']>;
  externalOfferId?: Maybe<Scalars['ID']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};


export type QueryAdminGetRewardOfferStatsArgs = {
  organizationId: Scalars['ID'];
};


export type QueryGetRewardsOrganizationByIdArgs = {
  organizationId: Scalars['ID'];
};


export type QueryGetRewardsOrganizationsByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryGetRewardsOrganizationOptInPromoArgs = {
  workEmail: Scalars['String'];
};


export type QueryGetRewardsOrganizationByApplicationSubmissionArgs = {
  applicationSubmissionId?: Maybe<Scalars['ID']>;
  candidateId?: Maybe<Scalars['ID']>;
};


export type QueryGetRewardsOrganizationByWorkEmailArgs = {
  workEmail: Scalars['String'];
};


export type QueryGetPowerHoursOfferByIdArgs = {
  powerHoursOfferId: Scalars['ID'];
};


export type QueryListMyPowerHoursOffersArgs = {
  filter?: Maybe<ListMyPowerHoursFilter>;
};


export type QueryListRedemptionsArgs = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  filter: RedemptionsFilterInput;
};


export type QueryRetoolListRedemptionsByCursorArgs = {
  limit: Scalars['Int'];
  filter?: Maybe<ListRedemptionsByCursorFilterInput>;
  cursor?: Maybe<Scalars['String']>;
};


export type QueryRetoolGetRedemptionExportsByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryGetSurveyEngagementArgs = {
  surveyType: SurveyType;
  dateRange?: Maybe<SurveyInsightsDateRangeInput>;
};


export type QueryGetSurveyAverageScoreArgs = {
  surveyType: SurveyType;
  dateRange?: Maybe<SurveyInsightsDateRangeInput>;
};


export type QueryGetSurveyQuestionResultsArgs = {
  surveyType: SurveyType;
  questionType: SurveyQuestionType;
  dateRange?: Maybe<SurveyInsightsDateRangeInput>;
};


export type QueryListCommentsForSurveyQuestionArgs = {
  surveyType: SurveyType;
  questionType: SurveyQuestionType;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  filter?: Maybe<SurveyCommentsFiltersInput>;
};


export type QueryGetScoreDistributionForSurveyQuestionArgs = {
  surveyType: SurveyType;
  questionType: SurveyQuestionType;
  dateRange?: Maybe<SurveyInsightsDateRangeInput>;
};


export type QueryGetAvailableSurveyDateRangesArgs = {
  surveyType: SurveyType;
};


export type QueryGetRewardsUserLoginTokenDetailsArgs = {
  loginTokenId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};


export type QuerySearchForRewardsUsersArgs = {
  searchQuery?: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
};


export type QueryListRewardsUsersArgs = {
  searchQuery?: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset?: Maybe<Scalars['Int']>;
  filter?: Maybe<ListRewardsUsersFilterInput>;
};


export type QueryGetRewardsUserByIdArgs = {
  userId: Scalars['ID'];
};


export type QueryGetRewardsUserExistsForOrganizationArgs = {
  userId: Scalars['ID'];
  organizationId: Scalars['ID'];
};


export type QueryGetReferralStatsForUserArgs = {
  userId: Scalars['ID'];
};


export type QueryGetRewardVideoByIdArgs = {
  videoId: Scalars['ID'];
};


export type QueryGetRewardVideoOfferByIdArgs = {
  videoOfferId: Scalars['ID'];
};


export type QueryAdminListRewardVideoOffersArgs = {
  userId?: Maybe<Scalars['ID']>;
  organizationId?: Maybe<Scalars['ID']>;
  externalOfferId?: Maybe<Scalars['ID']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};


export type QueryListMyVisitsAsOfStartDateArgs = {
  page: ListMyVisitsAsOfStartDatePageInput;
};


export type QueryGetSchoolsEmployerByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetSchoolsEmployerSurveyByIdArgs = {
  id: Scalars['ID'];
};

export type RecognitionBudgetPeriod = {
  __typename?: 'RecognitionBudgetPeriod';
  id: Scalars['ID'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  current: Scalars['Boolean'];
};

export type RecognitionBudgetUtilizationByAdminParameters = {
  /** The start month is _inclusive_ of the months covered by the report. */
  startMonth: Scalars['MonthAndYear'];
  /** The end month is _exclusive_ of the months covered by the report. */
  endMonth: Scalars['MonthAndYear'];
};

export type RecognitionCategory = {
  __typename?: 'RecognitionCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
  defaultPointAmount?: Maybe<Scalars['Int']>;
  disableSendFromManagers: Scalars['Boolean'];
};

export type RecognitionPoints = {
  __typename?: 'RecognitionPoints';
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
  employee: RewardsUser;
  pointValue: Scalars['Int'];
  from?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  defaultMessage?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['Timestamp']>;
  status: BonusPointsStatus;
  category?: Maybe<RecognitionCategory>;
  mustBeClaimed: Scalars['Boolean'];
  claimed: Scalars['Boolean'];
  claimedAt?: Maybe<Scalars['Timestamp']>;
  reminders: Array<BonusPointsReminder>;
  scheduledToSendOn?: Maybe<Scalars['Date']>;
};

export type RecognitionPointsInsightMetric = {
  __typename?: 'RecognitionPointsInsightMetric';
  id: Scalars['ID'];
  bonusPointsAwarded: Scalars['Int'];
};

export type RecognizedEmployeesInsightMetric = {
  __typename?: 'RecognizedEmployeesInsightMetric';
  id: Scalars['ID'];
  recognizedEmployeeCount: Scalars['Int'];
  activeUserCount: Scalars['Int'];
};

export type RecordVideoWatchByMeResponse = {
  __typename?: 'RecordVideoWatchByMeResponse';
  videoWatchId: Scalars['ID'];
};

export type RecordedCandidateHourlyRetentionStep = {
  __typename?: 'RecordedCandidateHourlyRetentionStep';
  durationHours: Scalars['Int'];
  recordedAt: Scalars['Timestamp'];
};

export type RecordedCandidateMonthlyRetentionStep = {
  __typename?: 'RecordedCandidateMonthlyRetentionStep';
  durationMonths: Scalars['Int'];
  recordedAt: Scalars['Timestamp'];
};

export type RecordedCandidateRecruitmentStep = {
  __typename?: 'RecordedCandidateRecruitmentStep';
  stepName: CandidateRecruitmentStepName;
  recordedAt: Scalars['Timestamp'];
};

export type RecordedCandidateRetentionStep = {
  __typename?: 'RecordedCandidateRetentionStep';
  durationMonths: Scalars['Int'];
  recordedAt: Scalars['Timestamp'];
};

export type RecordedCandidateStep = RecordedCandidateRecruitmentStep | RecordedCandidateRetentionStep;

export type RecordedCandidateStepV2 = RecordedCandidateRecruitmentStep | RecordedCandidateMonthlyRetentionStep | RecordedCandidateHourlyRetentionStep;

export type Redemption = {
  __typename?: 'Redemption';
  id: Scalars['ID'];
  status: RedemptionStatus;
  dollars: Scalars['Float'];
  points: Scalars['Int'];
  user: RewardsUser;
  createdAt: Scalars['Timestamp'];
  markedAsPaidAt?: Maybe<Scalars['Timestamp']>;
  markedAsSentToPayrollAt?: Maybe<Scalars['Timestamp']>;
  markedAsGenericGiftCardSentAt?: Maybe<Scalars['Timestamp']>;
  markedAsGenericGiftCardFulfillmentRequestedAt?: Maybe<Scalars['Timestamp']>;
  markedAsGenericGiftCardFulfillmentFailedAt?: Maybe<Scalars['Timestamp']>;
  pointTransactions: Array<PointTransaction>;
  organization: RewardsOrganization;
};

export type RedemptionExport = {
  __typename?: 'RedemptionExport';
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
  status: RedemptionExportStatus;
};

export enum RedemptionExportStatus {
  Pending = 'PENDING',
  Queued = 'QUEUED',
  Generated = 'GENERATED',
  Failed = 'FAILED',
  Error = 'ERROR'
}

export enum RedemptionMethod {
  GenericGiftCardLink = 'GENERIC_GIFT_CARD_LINK',
  Payroll = 'PAYROLL'
}

export enum RedemptionStatus {
  Requested = 'REQUESTED',
  SentGenericGiftCardLink = 'SENT_GENERIC_GIFT_CARD_LINK',
  SentToPayroll = 'SENT_TO_PAYROLL',
  GenericGiftCardFulfillmentRequested = 'GENERIC_GIFT_CARD_FULFILLMENT_REQUESTED',
  GenericGiftCardFulfillmentFailed = 'GENERIC_GIFT_CARD_FULFILLMENT_FAILED',
  Paid = 'PAID'
}

export type RedemptionsFilterInput = {
  /**
   * If provided, will filter the list to return only redemptions
   * by the provided user (employee) ID.
   */
  userId?: Maybe<Scalars['ID']>;
  /**
   * Allows filtering by organization branch ID.
   * If provided, will only show results from given branch(es)
   * If not provided, will show results from all branches
   * If [null] is provided, will show results that have no branch assigned
   * If [] is provided, will show NO results at all
   */
  branchIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /**
   * This doesn't do anything anymore - it was removed since it wasn't used
   * by the frontend, and was no longer supported architecturally.
   */
  searchQuery?: Maybe<Scalars['String']>;
};

export type RedemptionsResponse = {
  __typename?: 'RedemptionsResponse';
  total: Scalars['Int'];
  items: Array<Redemption>;
};

/**
 * Represents a Candidate from the perspective of a rewards user
 * who referred the Candidate.
 */
export type Referral = {
  __typename?: 'Referral';
  id: Scalars['ID'];
  candidateDisplayName: Scalars['String'];
  candidateEmail?: Maybe<Scalars['String']>;
  pointsAwarded: Scalars['Int'];
  pointsAwardable: Scalars['Int'];
  /** @deprecated Use statusV2 instead */
  status: ReferralStatus;
  statusV2: ReferralStatusV2;
  /** @deprecated Use stepsV2 instead */
  steps?: Maybe<Array<ReferralStep>>;
  stepsV2: Array<ReferralStepV2>;
  /**
   * Indicates if the referral has been hired.
   * If `false`, then they haven't progressed beyond being an applicant.
   */
  wasHired: Scalars['Boolean'];
};

export type ReferralHoursWorkedInsightMetric = EligibleReferralHoursWorkedInsightMetric | IneligibleReferralHoursWorkedInsightMetric;

export type ReferralRewardStructure = {
  __typename?: 'ReferralRewardStructure';
  id: Scalars['ID'];
  items: Array<ReferralRewardStructureItem>;
  jobShareStructure: ReferralRewardStructureJobShareItem;
  referralRecruitmentStructure: Array<ReferralRewardStructureReferralRecruitmentItem>;
  referralRetentionStructure: Array<ReferralRewardStructureReferralRetentionItem>;
};

export type ReferralRewardStructureDrawTicketReward = {
  __typename?: 'ReferralRewardStructureDrawTicketReward';
  numTickets: Scalars['Int'];
};

export type ReferralRewardStructureItem = ReferralRewardStructureJobShareItem | ReferralRewardStructureReferralRecruitmentItem | ReferralRewardStructureReferralRetentionItem;

export type ReferralRewardStructureJobShareItem = {
  __typename?: 'ReferralRewardStructureJobShareItem';
  maxAwardableJobSharesPerMonth: Scalars['Int'];
  reward: ReferralRewardStructureReward;
};

export type ReferralRewardStructureManualMonthlyRaffleReward = {
  __typename?: 'ReferralRewardStructureManualMonthlyRaffleReward';
  numTickets: Scalars['Int'];
  numWinnersSelectedPerMonth: Scalars['Int'];
  prizeText: Scalars['String'];
};

export type ReferralRewardStructurePointReward = {
  __typename?: 'ReferralRewardStructurePointReward';
  pointValue: Scalars['Int'];
};

export type ReferralRewardStructureReferralRecruitmentItem = {
  __typename?: 'ReferralRewardStructureReferralRecruitmentItem';
  step: CandidateRecruitmentStepName;
  reward: ReferralRewardStructureReward;
};

export type ReferralRewardStructureReferralRetentionItem = {
  __typename?: 'ReferralRewardStructureReferralRetentionItem';
  duration: ReferralRewardStructureReferralRetentionItemDuration;
  reward: ReferralRewardStructureReward;
};

export type ReferralRewardStructureReferralRetentionItemDuration = {
  __typename?: 'ReferralRewardStructureReferralRetentionItemDuration';
  unit: ReferralRewardStructureReferralRetentionItemDurationUnit;
  amount: Scalars['Int'];
};

export enum ReferralRewardStructureReferralRetentionItemDurationUnit {
  Months = 'MONTHS',
  Hours = 'HOURS'
}

export type ReferralRewardStructureReward = ReferralRewardStructurePointReward | ReferralRewardStructureDrawTicketReward | ReferralRewardStructureManualMonthlyRaffleReward;

export type ReferralRewardStructureStepItem = ReferralRewardStructureReferralRecruitmentItem | ReferralRewardStructureReferralRetentionItem;

export enum ReferralStatus {
  Active = 'ACTIVE',
  LeftCompany = 'LEFT_COMPANY',
  Completed = 'COMPLETED'
}

/**
 * Candidate status is ACTIVE when they have pending recruitment steps or retention milestones for which their referrer can earn points, after being hired.
 * Candidate status is INACTIVE when they were archived before completing all recruitment steps.
 * Candidate status is COMPLETED when they have completed all recruitment steps, after being hired.
 */
export enum ReferralStatusV2 {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Completed = 'COMPLETED'
}

/** Deprecated: Use ReferralStepV2 instead */
export type ReferralStep = {
  __typename?: 'ReferralStep';
  step: CandidateStep;
  pointValue: Scalars['Int'];
  date?: Maybe<Scalars['String']>;
  completed: Scalars['Boolean'];
};

export type ReferralStepV2 = {
  __typename?: 'ReferralStepV2';
  step: ReferralRewardStructureStepItem;
  pointValue: Scalars['Int'];
  date?: Maybe<Scalars['String']>;
  completed: Scalars['Boolean'];
};

export type ReferralsProgressResponse = {
  __typename?: 'ReferralsProgressResponse';
  id: Scalars['ID'];
  referrals?: Maybe<Array<Referral>>;
};

export type ReferralsProgressV2Response = {
  __typename?: 'ReferralsProgressV2Response';
  referrals?: Maybe<Array<Referral>>;
};

export type RejectRedemptionAndDeductPointInput = {
  redemptionId: Scalars['String'];
  deductPoints?: Maybe<RejectRedemptionDeductPointDetails>;
};

export type RejectRedemptionDeductPointDetails = {
  amount: Scalars['Int'];
  explanationToUser: Scalars['String'];
};

/** Note: visits are included based on the visit "end" date (not the "start" date) */
export type ReportEvvComplianceByCaregiverParameters = {
  dateRangeStart: Scalars['Timestamp'];
  dateRangeEnd: Scalars['Timestamp'];
};

export type ReportEngagementByCaregiverParameters = {
  dateRangeStart: Scalars['Timestamp'];
  dateRangeEnd: Scalars['Timestamp'];
};

export type ReportLoginSummaryByAdminParameters = {
  dateRangeStart: Scalars['Timestamp'];
  dateRangeEnd: Scalars['Timestamp'];
};

export type ReportPointTotalsByCaregiverParameters = {
  dateRangeStart: Scalars['Timestamp'];
  dateRangeEnd: Scalars['Timestamp'];
};

export type RetoolCreateQaVisitResult = {
  __typename?: 'RetoolCreateQAVisitResult';
  externalVisitId: Scalars['String'];
};

export type RetoolGetRedemptionExportsByIdsResponse = {
  __typename?: 'RetoolGetRedemptionExportsByIdsResponse';
  items: Array<RedemptionExport>;
};

export type RetoolQaVisitInput = {
  startDate: Scalars['Timestamp'];
  endDate: Scalars['Timestamp'];
  scheduledStartDate?: Maybe<Scalars['Timestamp']>;
  scheduledEndDate?: Maybe<Scalars['Timestamp']>;
  clockIn?: Maybe<VisitClockDetails>;
  clockOut?: Maybe<VisitClockDetails>;
  result?: Maybe<VistResult>;
  /**
   * Optional field.
   * Some source data may expose the confirmed payroll minutes
   * as a separate field, which may or may not be equal to the
   * difference between `startDate` and `endDate`.
   */
  minutesWorkedForPayroll?: Maybe<Scalars['Int']>;
};

export type RewardNotification = {
  __typename?: 'RewardNotification';
  id: Scalars['ID'];
  userId: Scalars['String'];
  rewardsOrganizationId: Scalars['Int'];
  messageTitle: Scalars['String'];
  messageBody?: Maybe<Scalars['String']>;
  /**
   * If the reward notification is due to the user earning points,
   * this will be popualted with the number of points earned.
   */
  pointsEarned?: Maybe<Scalars['Int']>;
  pointsDeducted?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Timestamp'];
};

export type RewardNotificationListResponse = {
  __typename?: 'RewardNotificationListResponse';
  total: Scalars['Int'];
  items: Array<JobPosting>;
};

export type RewardOffer = {
  __typename?: 'RewardOffer';
  id: Scalars['ID'];
  externalId?: Maybe<Scalars['ID']>;
  completed: Scalars['Boolean'];
  revoked: Scalars['Boolean'];
  numCompletions: Scalars['Int'];
  completions: Array<RewardOfferCompletion>;
  cardTitle: Scalars['String'];
  cardButtonLabel: Scalars['String'];
  cardPointsText?: Maybe<Scalars['String']>;
  cardCompletedPointsText?: Maybe<Scalars['String']>;
  cardGraphicKey: RewardOfferCardGraphicKey;
  /**
   * Details about the next reward that can be earned from
   * this reward offer.
   */
  nextReward?: Maybe<RewardOfferNextReward>;
  structure: RewardOfferStructure;
  action: RewardOfferAction;
  user: RewardsUser;
  organization: RewardsOrganization;
};

export type RewardOfferAction = RewardOfferModalAction | RewardOfferInAppLinkAction | RewardOfferExternalLinkAction;

export enum RewardOfferCardGraphicKey {
  PeopleInClassroom = 'people_in_classroom',
  CirclingDateOnCalendar = 'circling_date_on_calendar',
  PeopleInPhones = 'people_in_phones',
  WomanWalletCelebration = 'woman_wallet_celebration',
  WomenGiftsCelebration = 'women_gifts_celebration',
  PersonOnTrophyGifts = 'person_on_trophy_gifts',
  WomanWalkingOnCoins = 'woman_walking_on_coins',
  Evv = 'evv',
  WomanLoggingIn = 'woman_logging_in',
  ReferAndEarn = 'refer_and_earn',
  PeopleCommunicatingOffice = 'people_communicating_office',
  WomanGivingPresentation = 'woman_giving_presentation',
  PeopleTrophyCelebration = 'people_trophy_celebration',
  PeopleCoinsInJar = 'people_coins_in_jar',
  PlayVideo = 'play_video',
  VacantDesk = 'vacant_desk',
  WomanChecklist = 'woman_checklist',
  ManCirclingDateOnCalendar = 'man_circling_date_on_calendar',
  RecognizingEmployees = 'recognizing_employees',
  PhoneChatApp = 'phone_chat_app',
  ChecklistClipboard = 'checklist_clipboard',
  HealthChecklistClipboard = 'health_checklist_clipboard',
  WomanSchedulingOnPhone = 'woman_scheduling_on_phone',
  MailNotification = 'mail_notification',
  FolksWorkingOutside = 'folks_working_outside'
}

export type RewardOfferCompletion = {
  __typename?: 'RewardOfferCompletion';
  id: Scalars['ID'];
  pointValue?: Maybe<Scalars['Int']>;
  numDrawTickets?: Maybe<Scalars['Int']>;
  awarded: Scalars['Boolean'];
  acknowledged: Scalars['Boolean'];
};

export type RewardOfferExternalLinkAction = {
  __typename?: 'RewardOfferExternalLinkAction';
  id: Scalars['ID'];
  url: Scalars['String'];
};

export type RewardOfferInAppLinkAction = {
  __typename?: 'RewardOfferInAppLinkAction';
  id: Scalars['ID'];
  linkPath: Scalars['String'];
};

export type RewardOfferModalAction = {
  __typename?: 'RewardOfferModalAction';
  id: Scalars['ID'];
  modalContentsHtml: Scalars['String'];
};

export type RewardOfferNextReward = RewardOfferStructureReward & {
  __typename?: 'RewardOfferNextReward';
  id: Scalars['ID'];
  pointValue?: Maybe<Scalars['Int']>;
  numDrawTickets?: Maybe<Scalars['Int']>;
};

export type RewardOfferStructure = RewardOfferStructureUnlimited | RewardOfferStructureOneTime | RewardOfferStructurePeriodic | RewardOfferStructureDiscreteProgress | RewardOfferStructurePunchCard;

export type RewardOfferStructureDiscreteProgress = {
  __typename?: 'RewardOfferStructureDiscreteProgress';
  id: Scalars['ID'];
  steps: Array<RewardOfferStructureDiscreteProgressStep>;
};

export type RewardOfferStructureDiscreteProgressStep = RewardOfferStructureReward & {
  __typename?: 'RewardOfferStructureDiscreteProgressStep';
  pointValue?: Maybe<Scalars['Int']>;
  numDrawTickets?: Maybe<Scalars['Int']>;
};

export type RewardOfferStructureOneTime = RewardOfferStructureReward & {
  __typename?: 'RewardOfferStructureOneTime';
  id: Scalars['ID'];
  pointValue?: Maybe<Scalars['Int']>;
  numDrawTickets?: Maybe<Scalars['Int']>;
};

export type RewardOfferStructurePeriodic = RewardOfferStructureReward & {
  __typename?: 'RewardOfferStructurePeriodic';
  id: Scalars['ID'];
  pointValue?: Maybe<Scalars['Int']>;
  numDrawTickets?: Maybe<Scalars['Int']>;
  frequency: RewardOfferStructurePeriodicFrequency;
};

export enum RewardOfferStructurePeriodicFrequency {
  Weekly = 'WEEKLY'
}

export type RewardOfferStructurePunchCard = RewardOfferStructureReward & {
  __typename?: 'RewardOfferStructurePunchCard';
  id: Scalars['ID'];
  numPunchesPerCard: Scalars['Int'];
  pointValue?: Maybe<Scalars['Int']>;
  numDrawTickets?: Maybe<Scalars['Int']>;
};

export type RewardOfferStructureReward = {
  pointValue?: Maybe<Scalars['Int']>;
  numDrawTickets?: Maybe<Scalars['Int']>;
};

export enum RewardOfferStructureType {
  OneTime = 'one_time',
  Unlimited = 'unlimited',
  PunchCard = 'punch_card'
}

export type RewardOfferStructureUnlimited = RewardOfferStructureReward & {
  __typename?: 'RewardOfferStructureUnlimited';
  id: Scalars['ID'];
  pointValue?: Maybe<Scalars['Int']>;
  numDrawTickets?: Maybe<Scalars['Int']>;
};

export type RewardReport = {
  __typename?: 'RewardReport';
  id: Scalars['ID'];
};

export type RewardReportDataSetConfigInput = {
  /**
   * The type of the dataset.
   *
   * Depending on the type, the corresponding input parameters must be provided.
   */
  type: RewardReportDataSetType;
  engagementByCaregiverParameters?: Maybe<ReportEngagementByCaregiverParameters>;
  evvComplianceByCaregiverParameters?: Maybe<ReportEvvComplianceByCaregiverParameters>;
  loginSummaryByAdminParameters?: Maybe<ReportLoginSummaryByAdminParameters>;
  recognitionBudgetUtilizationByAdminParameters?: Maybe<RecognitionBudgetUtilizationByAdminParameters>;
};

export enum RewardReportDataSetType {
  EngagementByCaregiver = 'ENGAGEMENT_BY_CAREGIVER',
  EvvComplianceByCaregiver = 'EVV_COMPLIANCE_BY_CAREGIVER',
  LoginSummaryByAdmin = 'LOGIN_SUMMARY_BY_ADMIN',
  RecognitionBudgetUtilizationByAdmin = 'RECOGNITION_BUDGET_UTILIZATION_BY_ADMIN'
}

export type RewardVideo = {
  __typename?: 'RewardVideo';
  id: Scalars['ID'];
  title: Scalars['String'];
  file: RewardVideoFile;
  url?: Maybe<Scalars['String']>;
  status: VideoStatus;
};

export type RewardVideoFile = RewardVideoUrlFile | RewardVideoManagedFile;

export type RewardVideoManagedFile = {
  __typename?: 'RewardVideoManagedFile';
  id: Scalars['ID'];
  originalFilename: Scalars['String'];
};

export type RewardVideoOffer = {
  __typename?: 'RewardVideoOffer';
  id: Scalars['ID'];
  externalId?: Maybe<Scalars['ID']>;
  video: RewardVideo;
  pointValue: Scalars['Int'];
  watched: Scalars['Boolean'];
  deleted: Scalars['Boolean'];
  user: RewardsUser;
  organization: RewardsOrganization;
};

export type RewardVideoUrlFile = {
  __typename?: 'RewardVideoUrlFile';
  id: Scalars['ID'];
  /** @deprecated Use 'url' field on RewardVideo instead */
  url: Scalars['String'];
};

export enum RewardsAdminAuthStatus {
  InitialPasswordRequiredByUser = 'INITIAL_PASSWORD_REQUIRED_BY_USER',
  PasswordSetByUser = 'PASSWORD_SET_BY_USER',
  Unknown = 'UNKNOWN'
}

export type RewardsAdminAutomaticRecognitionBudgetConfig = {
  __typename?: 'RewardsAdminAutomaticRecognitionBudgetConfig';
  enabled: Scalars['Boolean'];
  defaultAmount?: Maybe<Scalars['Int']>;
};

export type RewardsAdminAutomaticRecognitionBudgetConfigInput = {
  enabled: Scalars['Boolean'];
  defaultAmount?: Maybe<Scalars['Int']>;
};

export type RewardsAdminFullAccessPermissions = {
  __typename?: 'RewardsAdminFullAccessPermissions';
  type: RewardsAdminPermissionsType;
};

export type RewardsAdminListFilterInput = {
  /** Filters admins by the given search query. */
  searchQuery?: Maybe<Scalars['String']>;
  /**
   * Allows filtering by organization branch ID.
   * It always includes Admins that have no branches assigned to them
   * If provided, will only show results from given branch(es)
   * If not provided, will show all admins
   * If [] is provided, will show NO results at all
   */
  branchIds?: Maybe<Array<Scalars['ID']>>;
};

export type RewardsAdminPermissions = RewardsAdminFullAccessPermissions | RewardsAdminRestrictedAccessPermissions;

export type RewardsAdminPermissionsInput = {
  type: RewardsAdminPermissionsType;
  /** Deprecated - use `RewardsAdminRestrictedAccessPermissionsInputV2` */
  permissions?: Maybe<RewardsAdminRestrictedAccessPermissionsInput>;
  /** This should only be provided if `type` is `FULL_ACCESS` */
  permissionsV2?: Maybe<RewardsAdminRestrictedAccessPermissionsInputV2>;
};

export enum RewardsAdminPermissionsType {
  FullAccess = 'FULL_ACCESS',
  RestrictedAccess = 'RESTRICTED_ACCESS'
}

export type RewardsAdminRestrictedAccessAdministratorsPermissions = {
  __typename?: 'RewardsAdminRestrictedAccessAdministratorsPermissions';
  permissionLevel: RewardsAdminRestrictedAccessPermissionLevel;
};

export type RewardsAdminRestrictedAccessCandidatesPermissions = {
  __typename?: 'RewardsAdminRestrictedAccessCandidatesPermissions';
  permissionLevel: RewardsAdminRestrictedAccessPermissionLevel;
};

export type RewardsAdminRestrictedAccessEmployeesPermissions = {
  __typename?: 'RewardsAdminRestrictedAccessEmployeesPermissions';
  permissionLevel: RewardsAdminRestrictedAccessPermissionLevel;
};

export type RewardsAdminRestrictedAccessJobsPermissions = {
  __typename?: 'RewardsAdminRestrictedAccessJobsPermissions';
  permissionLevel: RewardsAdminRestrictedAccessPermissionLevel;
};

export enum RewardsAdminRestrictedAccessPermissionLevel {
  FullAccess = 'FULL_ACCESS',
  ReadOnly = 'READ_ONLY'
}

export type RewardsAdminRestrictedAccessPermissions = {
  __typename?: 'RewardsAdminRestrictedAccessPermissions';
  type: RewardsAdminPermissionsType;
  /** @deprecated Use `permissionsV2` instead */
  permissions?: Maybe<RewardsAdminRestrictedAccessPermissionsMap>;
  permissionsV2?: Maybe<RewardsAdminRestrictedAccessPermissionsMapV2>;
};

export type RewardsAdminRestrictedAccessPermissionsAdministratorsInput = {
  permissionLevel: RewardsAdminRestrictedAccessPermissionLevel;
};

export type RewardsAdminRestrictedAccessPermissionsCandidatesInput = {
  permissionLevel: RewardsAdminRestrictedAccessPermissionLevel;
};

export type RewardsAdminRestrictedAccessPermissionsEmployeesInput = {
  permissionLevel: RewardsAdminRestrictedAccessPermissionLevel;
};

export type RewardsAdminRestrictedAccessPermissionsInput = {
  jobs?: Maybe<RewardsAdminRestrictedAccessPermissionsValue>;
  candidates?: Maybe<RewardsAdminRestrictedAccessPermissionsValue>;
  redemptions?: Maybe<RewardsAdminRestrictedAccessPermissionsValue>;
  bonusPoints?: Maybe<RewardsAdminRestrictedAccessPermissionsValue>;
  employees?: Maybe<RewardsAdminRestrictedAccessPermissionsValue>;
  administrators?: Maybe<RewardsAdminRestrictedAccessPermissionsValue>;
};

export type RewardsAdminRestrictedAccessPermissionsInputV2 = {
  jobs?: Maybe<RewardsAdminRestrictedAccessPermissionsJobsInput>;
  candidates?: Maybe<RewardsAdminRestrictedAccessPermissionsCandidatesInput>;
  redemptions?: Maybe<RewardsAdminRestrictedAccessPermissionsRedemptionsInput>;
  recognitionPoints?: Maybe<RewardsAdminRestrictedAccessPermissionsRecognitionPointsInput>;
  employees?: Maybe<RewardsAdminRestrictedAccessPermissionsEmployeesInput>;
  administrators?: Maybe<RewardsAdminRestrictedAccessPermissionsAdministratorsInput>;
  surveys?: Maybe<RewardsAdminRestrictedAccessPermissionsSurveysInput>;
};

export type RewardsAdminRestrictedAccessPermissionsJobsInput = {
  permissionLevel: RewardsAdminRestrictedAccessPermissionLevel;
};

/** This is deprecated in favour of `RewardsAdminRestrictedAccessPermissionsV2` */
export type RewardsAdminRestrictedAccessPermissionsMap = {
  __typename?: 'RewardsAdminRestrictedAccessPermissionsMap';
  jobs: RewardsAdminRestrictedAccessPermissionsValue;
  candidates: RewardsAdminRestrictedAccessPermissionsValue;
  redemptions: RewardsAdminRestrictedAccessPermissionsValue;
  bonusPoints: RewardsAdminRestrictedAccessPermissionsValue;
  employees: RewardsAdminRestrictedAccessPermissionsValue;
  administrators: RewardsAdminRestrictedAccessPermissionsValue;
};

export type RewardsAdminRestrictedAccessPermissionsMapV2 = {
  __typename?: 'RewardsAdminRestrictedAccessPermissionsMapV2';
  jobs?: Maybe<RewardsAdminRestrictedAccessJobsPermissions>;
  candidates?: Maybe<RewardsAdminRestrictedAccessCandidatesPermissions>;
  redemptions?: Maybe<RewardsAdminRestrictedAccessRedemptionsPermissions>;
  recognitionPoints?: Maybe<RewardsAdminRestrictedAccessRecognitionPointsPermissions>;
  employees?: Maybe<RewardsAdminRestrictedAccessEmployeesPermissions>;
  administrators?: Maybe<RewardsAdminRestrictedAccessAdministratorsPermissions>;
  surveys?: Maybe<RewardsAdminRestrictedAccessSurveysPermissions>;
};

export type RewardsAdminRestrictedAccessPermissionsRecognitionPointsInput = {
  permissionLevel: RewardsAdminRestrictedAccessPermissionLevel;
  /** These should only be populated if the admin has `FULL_ACCESS` to recognition. */
  canOverrideRecognitionCategoryDefaultAmounts?: Maybe<Scalars['Boolean']>;
  canOverrideBudget?: Maybe<Scalars['Boolean']>;
};

export type RewardsAdminRestrictedAccessPermissionsRedemptionsInput = {
  permissionLevel: RewardsAdminRestrictedAccessPermissionLevel;
};

export type RewardsAdminRestrictedAccessPermissionsSurveysInput = {
  permissionLevel: RewardsAdminRestrictedAccessPermissionLevel;
};

/** This is deprecated in favour of `RewardsAdminRestrictedAccessPermissionLevel` */
export enum RewardsAdminRestrictedAccessPermissionsValue {
  FullAccess = 'FULL_ACCESS',
  ReadOnly = 'READ_ONLY',
  None = 'NONE'
}

export type RewardsAdminRestrictedAccessRecognitionPointsPermissions = {
  __typename?: 'RewardsAdminRestrictedAccessRecognitionPointsPermissions';
  permissionLevel: RewardsAdminRestrictedAccessPermissionLevel;
  /**
   * If `permissionLevel` is `FULL_ACCESS`, this value will be populated.
   * If `true`, the admin can override recognition category default amounts.
   */
  canOverrideRecognitionCategoryDefaultAmounts?: Maybe<Scalars['Boolean']>;
  /**
   * If `permissionLevel` is `FULL_ACCESS`, this value will be populated.
   * If `true`, the admin can override budget.
   */
  canOverrideBudget?: Maybe<Scalars['Boolean']>;
};

export type RewardsAdminRestrictedAccessRedemptionsPermissions = {
  __typename?: 'RewardsAdminRestrictedAccessRedemptionsPermissions';
  permissionLevel: RewardsAdminRestrictedAccessPermissionLevel;
};

export type RewardsAdminRestrictedAccessSurveysPermissions = {
  __typename?: 'RewardsAdminRestrictedAccessSurveysPermissions';
  permissionLevel: RewardsAdminRestrictedAccessPermissionLevel;
};

export enum RewardsAdminRoleType {
  ProgramManager = 'PROGRAM_MANAGER',
  Executive = 'EXECUTIVE',
  Coordinator = 'COORDINATOR',
  Finance = 'FINANCE',
  HumanResources = 'HUMAN_RESOURCES'
}

export type RewardsAdminUser = RewardsUserIdentity & {
  __typename?: 'RewardsAdminUser';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  jobTitle?: Maybe<Scalars['String']>;
  role?: Maybe<RewardsAdminRoleType>;
  permissions: RewardsAdminPermissions;
  active: Scalars['Boolean'];
  branches: Array<RewardsOrganizationBranch>;
  automaticRecognitionBudgetConfig?: Maybe<RewardsAdminAutomaticRecognitionBudgetConfig>;
};

export type RewardsBaseUrls = {
  __typename?: 'RewardsBaseUrls';
  rewardsAppBaseUrl: Scalars['String'];
  rewardsAdminAppBaseUrl: Scalars['String'];
  jobsAppBaseUrl: Scalars['String'];
};

/** Deprecated - use RecognitionCategory instead */
export type RewardsBonusPointCategory = {
  __typename?: 'RewardsBonusPointCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type RewardsGroup = {
  __typename?: 'RewardsGroup';
  id: Scalars['ID'];
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  tagsConfig: RewardsGroupTagsConfig;
};

export type RewardsGroupTagsConfig = RewardsGroupTagsConfigSchemaV1;

export type RewardsGroupTagsConfigSchemaV1 = {
  __typename?: 'RewardsGroupTagsConfigSchemaV1';
  schema: Scalars['String'];
  or: Array<RewardsGroupTagsConfigSchemaV1AndSchema>;
};

export type RewardsGroupTagsConfigSchemaV1AndSchema = {
  __typename?: 'RewardsGroupTagsConfigSchemaV1AndSchema';
  and: Array<RewardsTag>;
};

export type RewardsGroupTagsConfigSchemaV1AndSchemaInput = {
  and: Array<TagIdInput>;
};

export type RewardsGroupTagsConfigSchemaV1Input = {
  or: Array<RewardsGroupTagsConfigSchemaV1AndSchemaInput>;
};

export type RewardsLeaderboard = {
  __typename?: 'RewardsLeaderboard';
  items: Array<RewardsLeaderboardItem>;
  /**
   * 1-based index representing the authenticated user's place on the leaderboard.
   * e.g. if the user is in 1st place, this value will be `1`
   */
  myPlaceOnLeaderboard: Scalars['Int'];
};

export type RewardsLeaderboardItem = {
  __typename?: 'RewardsLeaderboardItem';
  id: Scalars['ID'];
  userDisplayName: Scalars['String'];
  pointsEarned: Scalars['Int'];
  /** 1-based indexing place of user */
  place: Scalars['Int'];
};

export type RewardsOrganization = {
  __typename?: 'RewardsOrganization';
  /** (Public) The rewards organization ID. Same as the identity tenant ID. */
  id: Scalars['ID'];
  branches: Array<RewardsOrganizationBranch>;
  isQA: Scalars['Boolean'];
  isDemo: Scalars['Boolean'];
  /** @deprecated Use `shortName` instead */
  name: Scalars['String'];
  /** (Public) The organization's short name. */
  shortName: Scalars['String'];
  /**
   * The organization's internal name.
   * This should only be exposed to Caribou employees (aka. superusers).
   */
  internalName?: Maybe<Scalars['String']>;
  /**
   * The organization's timezone in IANA format.
   * e.g "America/Toronto"
   */
  timezone: Scalars['String'];
  /** The organization's branch name (if there are other branches) */
  branchName?: Maybe<Scalars['String']>;
  country: RewardsOrganizationCountry;
  /** The organization's long name. Useful for mailing address purposes. */
  longName: Scalars['String'];
  /** The organization's mailing address */
  mailingAddress?: Maybe<MailingAddress>;
  /** The organization's website URL. This is displayed publicly for job postings. */
  websiteUrl?: Maybe<Scalars['String']>;
  /** (Public) The primary colour of the organization. Used for theming purposes. */
  themePrimaryColor?: Maybe<Scalars['String']>;
  /** (Public) The URL of the banner image to display on job postings. */
  themeJobPostingBannerImageUrl?: Maybe<Scalars['String']>;
  /** (Public) The square logo URL of the banner image to display on job postings. */
  themeSquareLogoUrl?: Maybe<Scalars['String']>;
  /**
   * (Admin only) Recruitment & retention steps that the candidate needs to go through.
   * @deprecated Use `candidateStepsV2`
   */
  candidateSteps: Array<CandidateStep>;
  candidateStepsV2: Array<CandidateStepV2>;
  /**
   * (Rewards User/Admin only) True if the organization is configured to use work devices.
   *   This means a user can be created using their work email and phone number,
   *   and then must opt-in with their personal information to receive an account.
   */
  usesWorkDevices: Scalars['Boolean'];
  /** (Rewards User or Admin only) Number of points which equate to a dollar. */
  pointsPerDollar: Scalars['Int'];
  /** (Rewards User or Admin only) Redemption methods an organization offers. */
  allowedRedemptionMethods: Array<RedemptionMethod>;
  /**
   * Redemption summary notification email if the organization has enabled automatic PAYROLL redemption processing via Retool App.
   * @deprecated Use redemptionSummaryNotificationEmails instead
   */
  redemptionSummaryNotificationEmail?: Maybe<Scalars['String']>;
  /** Redemption summary notification emails if the organization has enabled automatic PAYROLL redemption processing via Retool App. */
  redemptionSummaryNotificationEmails: Array<Scalars['String']>;
  /** (Rewards User or Admin only) Minimum points that the user can redeem at once. */
  minimumPointsNeededToRedeem: Scalars['Int'];
  /**
   * Maximum number of job shares per month which can be awarded points for
   * @deprecated Use referralRewardStructure instead
   */
  maxAwardableJobSharesPerMonth: Scalars['Int'];
  /**
   * Maximum number of points that can be earned for a candidate,
   * accounting for the job share, recruitment milestones, and retention milestones
   */
  maxPointsEarnedPerCandidate: Scalars['Int'];
  /**
   * (Rewards User or Admin only)
   * @deprecated Use referralRewardStructure instead
   */
  pointsEarnedForJobPostingShared: Scalars['Int'];
  /**
   * (Rewards User or Admin only)
   * @deprecated Use referralRewardStructure instead
   */
  pointsEarnedForReferralApplied: Scalars['Int'];
  /**
   * (Rewards User or Admin only)
   * @deprecated Use referralRewardStructure instead
   */
  pointsEarnedForReferralPassedInterview: Scalars['Int'];
  /**
   * (Rewards User or Admin only)
   * @deprecated Use referralRewardStructure instead
   */
  pointsEarnedForReferralHired: Scalars['Int'];
  /**
   * (Rewards User or Admin only)
   * @deprecated Use referralRewardStructure instead
   */
  pointsEarnedForReferralStartedOrientation: Scalars['Int'];
  /**
   * (Rewards User or Admin only)
   * @deprecated Use referralRewardStructure instead
   */
  pointsEarnedForReferralCompletedOrientation: Scalars['Int'];
  /**
   * (Rewards User or Admin only)
   * @deprecated Use referralRewardStructure instead
   */
  pointsEarnedForReferralCompletedFirstShift: Scalars['Int'];
  /**
   * (Rewards User or Admin only)
   * @deprecated Use referralRewardStructure instead
   */
  pointsEarnedForReferralReachedTenureMilestone: Array<RewardsStructureTenureMilestone>;
  /** (Rewards User or Admin only) */
  referralRewardStructure: ReferralRewardStructure;
  /** (Rewards User or Admin only) */
  pointsEarnedForEarlyOptIn?: Maybe<Scalars['Int']>;
  /** One time reward offer that can be triggered by the api consumer. See completeWelcomeOffer mutation. */
  pointsEarnedForWelcomeOffer?: Maybe<Scalars['Int']>;
  /** (Rewards User only) */
  jobPostingLocationField: JobPostingLocationField;
  /**
   * (Public) The organization's email banner logo image url
   * @deprecated Use logoImageUrl instead
   */
  emailBannerLogoImageUrl?: Maybe<Scalars['String']>;
  /** (Public) The organization's email banner logo image url */
  logoImageUrl?: Maybe<Scalars['String']>;
  /** (Rewards User only) */
  workEmailDomain?: Maybe<Scalars['String']>;
  defaultCandidateAlertEmail?: Maybe<Scalars['String']>;
  /**
   * (Rewards admins only)
   *
   * True if the organization is integrated with AlayaCare.
   */
  integratedWithAlayaCare: Scalars['Boolean'];
  /** If `false`, the organization has not been 'launched' yet. */
  launched: Scalars['Boolean'];
  /** If the organization has launched, represents the date-time that the org launched. */
  launchedAt?: Maybe<Scalars['Timestamp']>;
  /** The completed launch steps for the organization. */
  completedLaunchSteps: Array<RewardsOrganizationCompletedLaunchStep>;
  payrollFrequency: RewardsOrganizationPayrollFrequency;
  leaderboardEmailContactName?: Maybe<Scalars['String']>;
  leaderboardEmailContactEmail?: Maybe<Scalars['String']>;
  bulkUserUploadIsProcessing: Scalars['Boolean'];
  /**
   * (Public) The white label config.
   *
   * If `null`, white labeling is not enabled for the organization
   */
  whiteLabelConfig?: Maybe<RewardsOrganizationWhiteLabelConfig>;
  /** If `true`, referrals are enabled for the organization */
  referralsEnabled: Scalars['Boolean'];
  /** If `true`, the organization wants to collect resumes from applicants. */
  referralResumesEnabled: Scalars['Boolean'];
  /** (Public) If defined, should be displayed on the opt-in page as a disclaimer */
  optInPageDisclaimer?: Maybe<Scalars['String']>;
  /** If `true`, managers are enabled for the organization. */
  managersEnabled: Scalars['Boolean'];
  /** If `true`, recognition budgets are enabled for the organization. */
  recognitionBudgetsEnabled: Scalars['Boolean'];
  /**
   * A file that contains a recognition rubric (if exists).
   * This rubric will show a rewards admin how they can award points
   */
  recognitionRubricFileUrl?: Maybe<Scalars['String']>;
  /** If `true`, leaderboard is enabled for the organization. */
  leaderboardEnabled: Scalars['Boolean'];
  /**
   * If `true`, the "Draws" feature is enabled for the organization.
   * This means that the organization manages and runs their owns draws.
   */
  drawsEnabled: Scalars['Boolean'];
  /**
   * If `true`, the "Goals" feature is enabled for the organization.
   *
   * This means that users are distributed goals instead of reward offers
   * (which is the legacy system for facilitating incentives).
   */
  goalsEnabled: Scalars['Boolean'];
  /**
   * If `true`, users in this organization can be aware of & earn tickets
   * for one of the Caribou-sponsored draws.
   */
  hasAccessToCaribouSponsoredDraws: Scalars['Boolean'];
  /** If `true`, the organization started as a trial. */
  startedAsTrial: Scalars['Boolean'];
  /** During onboarding, allows an admin to specify Caribou Rewards champions at their org */
  champions: Array<RewardsOrganizationOnboardingChampion>;
  /** The current state of onboarding for this rewards organization. */
  onboardingState: RewardsOrganizationOnboardingState;
  signUpContactInfoPreference: RewardsOrganizationSignUpContactInfoPreference;
  /** This config holds the frequency and related data for organization's recognition budget. */
  automaticRecognitionBudgetConfig: RewardsOrganizationAutomaticRecognitionBudgetConfig;
  /**
   * Returns true if the organization has visit data
   * (at least one visit pipeline has completed, indicating that visit data is accessible).
   */
  hasVisitData: Scalars['Boolean'];
};

export type RewardsOrganizationAutomaticRecognitionBudgetConfig = {
  __typename?: 'RewardsOrganizationAutomaticRecognitionBudgetConfig';
  frequency: RewardsOrganizationAutomaticRecognitionBudgetFrequency;
};

export enum RewardsOrganizationAutomaticRecognitionBudgetFrequency {
  None = 'NONE',
  Monthly = 'MONTHLY'
}

export type RewardsOrganizationBranch = {
  __typename?: 'RewardsOrganizationBranch';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type RewardsOrganizationCompletedLaunchStep = {
  __typename?: 'RewardsOrganizationCompletedLaunchStep';
  stepName: RewardsOrganizationLaunchStep;
  completedAt: Scalars['Timestamp'];
};

export enum RewardsOrganizationCountry {
  Ca = 'CA',
  Us = 'US'
}

export type RewardsOrganizationEvvRules = {
  __typename?: 'RewardsOrganizationEVVRules';
  id: Scalars['Int'];
  rules: EvvRules;
};

export enum RewardsOrganizationLaunchStep {
  LaunchStarted = 'LAUNCH_STARTED',
  LaunchProfileCompleted = 'LAUNCH_PROFILE_COMPLETED',
  JobPostingsAdded = 'JOB_POSTINGS_ADDED',
  RewardsUsersAdded = 'REWARDS_USERS_ADDED',
  RewardStructureConfirmed = 'REWARD_STRUCTURE_CONFIRMED',
  LaunchContestsReviewed = 'LAUNCH_CONTESTS_REVIEWED',
  QuestionnaireSubmitted = 'QUESTIONNAIRE_SUBMITTED'
}

export type RewardsOrganizationLeaderboardEmailContactInput = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export enum RewardsOrganizationOnboardingBusinessType {
  LimitedLiabilityCorporation = 'LIMITED_LIABILITY_CORPORATION',
  SoleProprietorship = 'SOLE_PROPRIETORSHIP',
  Partnership = 'PARTNERSHIP',
  Corporation = 'CORPORATION',
  NonProfitCorporation = 'NON_PROFIT_CORPORATION',
  Cooperative = 'COOPERATIVE'
}

export type RewardsOrganizationOnboardingChampion = {
  __typename?: 'RewardsOrganizationOnboardingChampion';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type RewardsOrganizationOnboardingChampionInput = {
  name?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export enum RewardsOrganizationOnboardingCommitmentToSuccess {
  Ready = 'READY',
  NeedHelp = 'NEED_HELP',
  NotSure = 'NOT_SURE'
}

export type RewardsOrganizationOnboardingState = {
  __typename?: 'RewardsOrganizationOnboardingState';
  id: Scalars['ID'];
  /** Onboarding flow steps that have been marked as "completed" for this organization */
  completedSteps: Array<RewardsOrganizationOnboardingStepName>;
  /** During onboarding, allows an admin to specify the business type */
  businessType?: Maybe<RewardsOrganizationOnboardingBusinessType>;
  /** During onboarding, allows an admin to specify the business registration number */
  businessRegistrationNumber?: Maybe<Scalars['String']>;
  /** During onboarding, allows an admin to specify their level of commitment to success */
  commitmentToSuccess?: Maybe<RewardsOrganizationOnboardingCommitmentToSuccess>;
  /**
   * During onboarding, allows an admin to specify whether they would like to
   * utilize automated birthday recognition rewards
   */
  wantsAutomatedBirthdayRecognition?: Maybe<Scalars['Boolean']>;
  /**
   * During onboarding, allows an admin to specify whether they would like to
   * utilize automated anniversary recognition rewards
   */
  wantsAutomatedAnniversaryRecognition?: Maybe<Scalars['Boolean']>;
  /**
   * During onboarding, allows an admin to indicate a targeted launch date
   * for their organization.
   */
  targetLaunchDate?: Maybe<Scalars['Date']>;
  /**
   * Whether or not onboarding state should be disabled - if true, this
   * should prevent admins from interacting with the onboarding flow in the app
   */
  disabledOnboarding?: Maybe<Scalars['Boolean']>;
};

export enum RewardsOrganizationOnboardingStepName {
  Intro = 'INTRO',
  YourProfile = 'YOUR_PROFILE',
  CaregiverInvites = 'CAREGIVER_INVITES',
  EmployeeAutoSync = 'EMPLOYEE_AUTO_SYNC',
  ConfigureJobs = 'CONFIGURE_JOBS',
  JobSetting = 'JOB_SETTING',
  ReferralStructure = 'REFERRAL_STRUCTURE',
  EvvCompliance = 'EVV_COMPLIANCE',
  BookKickOff = 'BOOK_KICK_OFF',
  Champions = 'CHAMPIONS',
  Recognition = 'RECOGNITION',
  Redemption = 'REDEMPTION'
}

export type RewardsOrganizationOptInPromo = {
  __typename?: 'RewardsOrganizationOptInPromo';
  /**
   * The number of points earned for opting in.
   *
   * If no points will be earned for opting in, this value will be `null`
   */
  pointsEarnedForOptingIn?: Maybe<Scalars['Int']>;
};

export enum RewardsOrganizationPayrollFrequency {
  Weekly = 'WEEKLY',
  BiWeekly = 'BI_WEEKLY',
  Monthly = 'MONTHLY',
  Unknown = 'UNKNOWN'
}

export enum RewardsOrganizationSignUpContactInfoPreference {
  PersonalInfo = 'PERSONAL_INFO',
  WorkOrPersonal = 'WORK_OR_PERSONAL'
}

export type RewardsOrganizationStats = {
  __typename?: 'RewardsOrganizationStats';
  id: Scalars['ID'];
  totalNumberOfActiveUsers: Scalars['Int'];
  totalNumberOfActiveUsersWhoOptedIn: Scalars['Int'];
  totalNumberOfUsersWhoSharedAJob: Scalars['Int'];
  totalNumberOfShares: Scalars['Int'];
  totalNumberOfApplicants: Scalars['Int'];
  totalNumberOfCandidatesContacted: Scalars['Int'];
  totalNumberOfCandidatesScheduledInterview: Scalars['Int'];
  totalNumberOfCandidatesPassedInterview: Scalars['Int'];
  totalNumberOfCandidatesStartedWork: Scalars['Int'];
  totalNumberOfCandidatesHired: Scalars['Int'];
  totalNumberOfRetainedCandidatesAfter3Months: Scalars['Int'];
  totalNumberOfRetainedCandidatesAfter6Months: Scalars['Int'];
  totalPointsRedeemed: Scalars['Int'];
  totalPointsAwarded: Scalars['Int'];
  totalNumberOfCandidatesStartedOrientation: Scalars['Int'];
  totalNumberOfCandidatesCompletedOrientation: Scalars['Int'];
  totalNumberOfCandidatesCompletedFirstShift: Scalars['Int'];
};

export type RewardsOrganizationWhiteLabelConfig = {
  __typename?: 'RewardsOrganizationWhiteLabelConfig';
  /** The organization's id */
  id: Scalars['ID'];
  rewardsProgramShortName: Scalars['String'];
  rewardsProgramLongName: Scalars['String'];
  faviconImageUrl: Scalars['String'];
  optInPageLogoUrl: Scalars['String'];
  rewardsPageTabIconUrl: Scalars['String'];
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  fromEmail: Scalars['String'];
};

export type RewardsShiftMilestone = {
  __typename?: 'RewardsShiftMilestone';
  id: Scalars['ID'];
  totalShiftLengthHours: Scalars['Float'];
  pointValue: Scalars['Int'];
  completed: Scalars['Boolean'];
};

export type RewardsStructureTenureMilestone = {
  __typename?: 'RewardsStructureTenureMilestone';
  durationMonths: Scalars['Int'];
  pointValue: Scalars['Int'];
};

export type RewardsTag = {
  __typename?: 'RewardsTag';
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
  externalId: Scalars['String'];
};

export type RewardsUser = RewardsUserIdentity & {
  __typename?: 'RewardsUser';
  /** The user's ID. Same as the user's identity ID. */
  id: Scalars['ID'];
  branch?: Maybe<RewardsOrganizationBranch>;
  /** External employee ID that the organization uses to associate the user */
  externalEmployeeId?: Maybe<Scalars['String']>;
  /** User groups that the user is assigned to (for categorization) */
  groups: Array<RewardsUserGroup>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  /**
   * User's locale, formatted in `en` or `en-US`.
   * If not defined, a locale has not been explicitly set for this user.
   */
  locale?: Maybe<Locale>;
  /**
   * Indicates whether the user has selected their locale. When a user is first created
   * we may automatically set their locale (ex. by syncing from an external source).
   */
  localeSetByUser: Scalars['Boolean'];
  /** The user's personal email/phone (the info they use to log in, and receive most comms on) */
  personalContactInfo?: Maybe<RewardsUserPersonalContactInfo>;
  workEmail?: Maybe<Scalars['String']>;
  workPhoneNumber?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  /** The user's display name (first name & last initial). */
  displayName: Scalars['String'];
  /** The active point balance (points available to redeem). */
  pointsAvailableToRedeem: Scalars['Int'];
  /** The amount of points that the user has redeemed. */
  pointsRedeemed: Scalars['Int'];
  /** The user's manager, if assigned */
  manager?: Maybe<RewardsAdminUser>;
  welcomeOfferCompleted?: Maybe<Scalars['Boolean']>;
  /**
   * Whether or not referrals are enabled for this user
   * (based on their branch or organization's settings)
   */
  referralsEnabled: Scalars['Boolean'];
  /** Whether or not referral links are disabled for this user. If true, the user is unable to share a job */
  userReferralLinkDisabled: Scalars['Boolean'];
  /** @deprecated Use `personalInformation` instead */
  email?: Maybe<Scalars['String']>;
  /** @deprecated Use `personalInformation` instead */
  phoneNumber?: Maybe<Scalars['String']>;
  /**
   * Scheduling software being used by the caregiver for verifying shifts.
   * Can be null when there's no employeeExternalId or missing mapping.
   */
  schedulingSoftware?: Maybe<SchedulingSoftware>;
};

export type RewardsUserCurrentShiftMilestoneDetails = {
  __typename?: 'RewardsUserCurrentShiftMilestoneDetails';
  id: Scalars['ID'];
  currentMilestone: RewardsShiftMilestone;
  percentageCompleted: Scalars['Float'];
  hoursBetweenPreviousAndCurrentMilestone: Scalars['Float'];
  shiftHoursCompletedTowardCurrentMilestone: Scalars['Float'];
  totalShiftHoursWorked: Scalars['Float'];
};

export type RewardsUserGroup = {
  __typename?: 'RewardsUserGroup';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type RewardsUserIdentity = {
  /** The user's ID. Same as the user's identity ID. */
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
};

export enum RewardsUserLoginLinkFormat {
  Email = 'EMAIL',
  Phone = 'PHONE'
}

export type RewardsUserLoginResponse = {
  __typename?: 'RewardsUserLoginResponse';
  accessToken: Scalars['String'];
};

export type RewardsUserPersonalContactInfo = RewardsUserPersonalContactInfoData | RewardsUserPersonalContactInfoSuppressed;

export type RewardsUserPersonalContactInfoData = {
  __typename?: 'RewardsUserPersonalContactInfoData';
  id: Scalars['ID'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type RewardsUserPersonalContactInfoSuppressed = {
  __typename?: 'RewardsUserPersonalContactInfoSuppressed';
  id: Scalars['ID'];
};

/** Rewards user-facing redemption */
export type RewardsUserRedemption = {
  __typename?: 'RewardsUserRedemption';
  id: Scalars['ID'];
  redemptionMethod: RedemptionMethod;
  status: RewardsUserRedemptionStatus;
  dollars: Scalars['Float'];
  points: Scalars['Int'];
  requestedAt: Scalars['Timestamp'];
};

/** Status displayed to the user for a redemption */
export enum RewardsUserRedemptionStatus {
  Pending = 'PENDING',
  Fulfilled = 'FULFILLED'
}

export type RewardsUserReferralStats = {
  __typename?: 'RewardsUserReferralStats';
  id: Scalars['ID'];
  totalNumberOfShares: Scalars['Int'];
  totalNumberOfApplicants: Scalars['Int'];
  totalNumberOfCandidatesHired: Scalars['Int'];
};

export type RewardsUserShiftMilestoneDetails = {
  __typename?: 'RewardsUserShiftMilestoneDetails';
  id: Scalars['ID'];
  allOrderedMilestones: Array<RewardsShiftMilestone>;
  currentMilestoneDetails?: Maybe<RewardsUserCurrentShiftMilestoneDetails>;
};

export enum Role {
  Client = 'CLIENT',
  Agent = 'AGENT',
  Superuser = 'SUPERUSER',
  Cognito = 'COGNITO',
  CustomerIo = 'CUSTOMER_IO',
  Nevvon = 'NEVVON',
  RewardsUser = 'REWARDS_USER',
  RewardsAdmin = 'REWARDS_ADMIN',
  RewardsSuperuser = 'REWARDS_SUPERUSER',
  SchoolsStudent = 'SCHOOLS_STUDENT'
}

export enum SchedulingSoftware {
  Hhaexchange = 'Hhaexchange',
  Alayacare = 'Alayacare',
  Axiscare = 'Axiscare',
  Wellsky = 'Wellsky',
  Unknown = 'Unknown'
}

export type SchoolsEmployer = {
  __typename?: 'SchoolsEmployer';
  id: Scalars['ID'];
  name: Scalars['String'];
  profileHTML: Scalars['String'];
  /** For schools students only: true if the authenticated student has applied to this employer. */
  applied: Scalars['Boolean'];
};

export type SchoolsEmployerSurvey = {
  __typename?: 'SchoolsEmployerSurvey';
  id: Scalars['ID'];
  question: Scalars['String'];
  answers: Array<SchoolsEmployerSurveyAnswer>;
  results: Array<SchoolsEmployerSurveyResult>;
};

export type SchoolsEmployerSurveyAnswer = {
  __typename?: 'SchoolsEmployerSurveyAnswer';
  id: Scalars['ID'];
  answer: Scalars['String'];
};

export type SchoolsEmployerSurveyResult = {
  __typename?: 'SchoolsEmployerSurveyResult';
  id: Scalars['ID'];
  employer: SchoolsEmployer;
  answers: Array<SchoolsEmployerSurveyResultAnswer>;
};

export type SchoolsEmployerSurveyResultAnswer = {
  __typename?: 'SchoolsEmployerSurveyResultAnswer';
  answer: SchoolsEmployerSurveyAnswer;
  percentage: Scalars['Float'];
};

export type SchoolsJobApplication = {
  __typename?: 'SchoolsJobApplication';
  id: Scalars['ID'];
};

export enum SchoolsJobApplicationDesiredHours {
  FullTime = 'FULL_TIME',
  PartTime = 'PART_TIME'
}

export enum SchoolsJobApplicationDesiredShiftDaysOfWeek {
  MondayToFriday = 'MONDAY_TO_FRIDAY',
  WeekendsOnly = 'WEEKENDS_ONLY'
}

export enum SchoolsJobApplicationDesiredShiftTimeOfDay {
  Mornings = 'MORNINGS',
  WorkDays = 'WORK_DAYS',
  Evenings = 'EVENINGS',
  Nights = 'NIGHTS'
}

export enum SchoolsJobApplicationTravelCapability {
  PublicTransitOnly = 'PUBLIC_TRANSIT_ONLY',
  AbleToDrive = 'ABLE_TO_DRIVE'
}

export type SchoolsStudent = {
  __typename?: 'SchoolsStudent';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  graduationDate: Scalars['MonthAndYear'];
  createdApplication: Scalars['Boolean'];
};

export type SchoolsStudentLoginResponse = {
  __typename?: 'SchoolsStudentLoginResponse';
  accessToken: Scalars['String'];
};

export enum SharePlatform {
  CopiedLink = 'COPIED_LINK',
  Email = 'EMAIL',
  Sms = 'SMS',
  Facebook = 'FACEBOOK',
  Messenger = 'MESSENGER',
  Linkedin = 'LINKEDIN',
  Whatsapp = 'WHATSAPP'
}

export enum Sort {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Status = {
  __typename?: 'Status';
  status: Scalars['String'];
  version: Scalars['String'];
};

export type SubmissionYesNoFieldAnswerInput = {
  fieldId: Scalars['String'];
  value: JobPostingYesNoFieldAnswer;
};

export type SubmitJobApplicationResponse = {
  __typename?: 'SubmitJobApplicationResponse';
  applicantUploadToken: Scalars['ID'];
  applicationSubmissionId: Scalars['ID'];
};

export type SurveyAverageScoreResponse = {
  __typename?: 'SurveyAverageScoreResponse';
  averageScore: Scalars['Float'];
  previousAverageScore?: Maybe<Scalars['Float']>;
};

export type SurveyCommentsFiltersInput = {
  /**
   * When provided, only comments associated with a score equal to this value will be returned.
   * Must be between 1 and 5, where 5 is most positive.
   */
  score?: Maybe<Scalars['Int']>;
  /**
   * When provided, will only consider comments that were submitted within the given date range.
   * If no date range is provided, will return all comments.
   */
  dateRange?: Maybe<SurveyInsightsDateRangeInput>;
};

export type SurveyEngagementResponse = {
  __typename?: 'SurveyEngagementResponse';
  numSent: Scalars['Int'];
  /**
   * The number of surveys sent out that received a response.
   * If a user submitted the same survey more than once, it still counts as a single response here.
   */
  numResponses: Scalars['Int'];
  /** The fraction (between 0 and 1) of the surveys sent out that received a response. */
  responseRate: Scalars['Float'];
  reward: SurveyReward;
};

export type SurveyGoalDetails = {
  __typename?: 'SurveyGoalDetails';
  /**
   * The URL to the survey that should be completed to achieve this goal.
   *
   * Note: this attribute should always be present on available survey
   * goals, but is nullable in case of configuration error.
   * In the case when the URL is missing there should be an error
   * raised on the front-end.
   */
  externalSurveyUrl?: Maybe<Scalars['String']>;
};

export type SurveyGoalTracker = {
  __typename?: 'SurveyGoalTracker';
  id: Scalars['ID'];
  surveyType: SurveyGoalTrackerSurveyType;
};

export enum SurveyGoalTrackerSurveyType {
  PulseCheck = 'PULSE_CHECK'
}

export type SurveyInsightsDateRange = {
  __typename?: 'SurveyInsightsDateRange';
  label: SurveyInsightsDateRangeLabel;
  year: Scalars['Int'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};

export type SurveyInsightsDateRangeInput = {
  label: SurveyInsightsDateRangeLabel;
  year: Scalars['Int'];
};

export enum SurveyInsightsDateRangeLabel {
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3 = 'Q3',
  Q4 = 'Q4',
  TrialWeek_1 = 'TRIAL_WEEK_1',
  TrialWeek_4 = 'TRIAL_WEEK_4'
}

export type SurveyQuestionPreviousResultsResponse = {
  __typename?: 'SurveyQuestionPreviousResultsResponse';
  averageScore: Scalars['Float'];
  dateRange: SurveyInsightsDateRange;
};

export type SurveyQuestionResponse = {
  __typename?: 'SurveyQuestionResponse';
  /** The ID of the survey submission, which belongs to all the question responses from the survey. */
  surveySubmissionId: Scalars['ID'];
  /** The score given to the question between 1 and 5, where 5 is most positive. */
  score: Scalars['Int'];
  /** The comment given to provide detail to the score. */
  comment: Scalars['String'];
};

export type SurveyQuestionResultsResponse = {
  __typename?: 'SurveyQuestionResultsResponse';
  averageScore: Scalars['Float'];
  numComments: Scalars['Int'];
  /**
   * Average score for the question from up to 4 date ranges prior to the requested date range.
   * Ordered from newest to oldest.
   */
  previousAverageScores?: Maybe<Array<SurveyQuestionPreviousResultsResponse>>;
};

export type SurveyQuestionScoreCount = {
  __typename?: 'SurveyQuestionScoreCount';
  score: Scalars['Int'];
  count: Scalars['Int'];
};

export enum SurveyQuestionType {
  MeaningfulRecognition = 'MEANINGFUL_RECOGNITION',
  ManageableWorkload = 'MANAGEABLE_WORKLOAD',
  ReliableTransportation = 'RELIABLE_TRANSPORTATION',
  LeadershipSupport = 'LEADERSHIP_SUPPORT',
  Resources = 'RESOURCES',
  Nps = 'NPS'
}

export type SurveyReward = {
  __typename?: 'SurveyReward';
  numDrawTickets?: Maybe<Scalars['Int']>;
  numPoints?: Maybe<Scalars['Int']>;
};

export enum SurveyType {
  PulseCheck = 'PULSE_CHECK'
}

export type TagIdInput = {
  tagId: Scalars['ID'];
};

export type TimeBasedlUnlockCriteria = {
  __typename?: 'TimeBasedlUnlockCriteria';
  unlocksAt: Scalars['Timestamp'];
};


export type TimezoneOption = {
  __typename?: 'TimezoneOption';
  id: Scalars['String'];
  name: Scalars['String'];
  alternativeName: Scalars['String'];
  mainCities: Array<Scalars['String']>;
  currentTimeFormat: Scalars['String'];
};

export type TotalHiresInsightMetric = {
  __typename?: 'TotalHiresInsightMetric';
  id: Scalars['ID'];
  hiredCandidateCount: Scalars['Int'];
};

/**
 * The current progress of a track, where `totalEarnablePointsForTrack` is the point sum of available, locked, and achieved goals within a track,
 * and `pointsEarnedSoFar` is the point sum of achieved goals within a track.
 */
export type TrackProgress = {
  __typename?: 'TrackProgress';
  pointsEarnedSoFar: Scalars['Int'];
  totalEarnablePointsForTrack: Scalars['Int'];
};

export type UsMailingAddress = {
  __typename?: 'USMailingAddress';
  countryCode: MailingAddressCountryCode;
  stateAbbreviation: UsStateAbbreviation;
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  zipCode: Scalars['String'];
};

export enum UsStateAbbreviation {
  Al = 'AL',
  Ak = 'AK',
  As = 'AS',
  Az = 'AZ',
  Ar = 'AR',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  De = 'DE',
  Dc = 'DC',
  Fm = 'FM',
  Fl = 'FL',
  Ga = 'GA',
  Gu = 'GU',
  Hi = 'HI',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ia = 'IA',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Me = 'ME',
  Mh = 'MH',
  Md = 'MD',
  Ma = 'MA',
  Mi = 'MI',
  Mn = 'MN',
  Ms = 'MS',
  Mo = 'MO',
  Mt = 'MT',
  Ne = 'NE',
  Nv = 'NV',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Ny = 'NY',
  Nc = 'NC',
  Nd = 'ND',
  Mp = 'MP',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pw = 'PW',
  Pa = 'PA',
  Pr = 'PR',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Ut = 'UT',
  Vt = 'VT',
  Vi = 'VI',
  Va = 'VA',
  Wa = 'WA',
  Wv = 'WV',
  Wi = 'WI',
  Wy = 'WY'
}

export type UpdateReferralStructureInput = {
  retentionMilestones: Array<UpdateReferralStructureRetentionMilestoneInput>;
  recruitmentSteps: Array<UpdateReferralStructureRecruitmentStepInput>;
  jobShareStructure?: Maybe<UpdateReferralStructureJobShareStructureInput>;
};

export type UpdateReferralStructureJobShareStructureInput = {
  reward: UpdateReferralStructureJobStructureRewardItemsInput;
  maxAwardableSharesPerMonth: Scalars['Int'];
};

export type UpdateReferralStructureJobStructureDrawTicketInput = {
  numTicketsPerJobShare: Scalars['Int'];
};

export type UpdateReferralStructureJobStructureManualMonthlyRaffleInput = {
  numTicketsPerJobShare: Scalars['Int'];
  numWinnersSelectedPerMonth: Scalars['Int'];
  prizeText: Scalars['String'];
};

export type UpdateReferralStructureJobStructurePointsInput = {
  pointValue: Scalars['Int'];
};

export type UpdateReferralStructureJobStructureRewardItemsInput = {
  type: OrganizationCandidateStepRewardType;
  jobStructurePointReward?: Maybe<UpdateReferralStructureJobStructurePointsInput>;
  jobStructureDrawTicketReward?: Maybe<UpdateReferralStructureJobStructureDrawTicketInput>;
  jobStructureManualMonthlyRaffleReward?: Maybe<UpdateReferralStructureJobStructureManualMonthlyRaffleInput>;
};

export type UpdateReferralStructureRecruitmentStepInput = {
  step: CandidateRecruitmentStepName;
  pointValue: Scalars['Int'];
};

export type UpdateReferralStructureRetentionMilestoneInput = {
  unit: Scalars['String'];
  pointValue: Scalars['Int'];
  durationHours: Scalars['Int'];
};


export type UploadBulkImportResponse = {
  __typename?: 'UploadBulkImportResponse';
  bulkImportId: Scalars['ID'];
};

export type UploadCandidateResumeResponse = {
  __typename?: 'UploadCandidateResumeResponse';
  resumeId: Scalars['ID'];
};

export type UploadJobApplicationResumeResponse = {
  __typename?: 'UploadJobApplicationResumeResponse';
  resumeId: Scalars['ID'];
};

export type UserIdentity = {
  __typename?: 'UserIdentity';
  id: Scalars['String'];
  role: Role;
  name: Scalars['String'];
};

export type UserMilestoneRewardStep = {
  __typename?: 'UserMilestoneRewardStep';
  id: Scalars['ID'];
  isComplete: Scalars['Boolean'];
  goal: MilestoneGoal;
  /** The # points to be earned by completing this milestone. */
  pointValue: Scalars['Int'];
  /** Progress is in # hours or # visits, depending on the goal. */
  progress: Scalars['Int'];
};

export type UserMilestoneRewards = {
  __typename?: 'UserMilestoneRewards';
  id: Scalars['ID'];
  organizationId: Scalars['Int'];
  milestoneSteps: Array<UserMilestoneRewardStep>;
  mustCompleteEVV: Scalars['Boolean'];
  /** Whether all milestones for this user have been completed or not. */
  allMilestonesComplete: Scalars['Boolean'];
  /** Current in-progress milestone step. Null if all milestones complete. */
  currentMilestoneStep?: Maybe<UserMilestoneRewardStep>;
  /**
   * Next milestone step (not yet in-progress).
   * Null if current milestone is the last one, or if all milestones complete.
   */
  nextMilestoneStep?: Maybe<UserMilestoneRewardStep>;
  stopped: Scalars['Boolean'];
};

export enum VideoStatus {
  PendingUpload = 'PENDING_UPLOAD',
  Uploaded = 'UPLOADED',
  Deleted = 'DELETED'
}

export type Visit = {
  __typename?: 'Visit';
  id: Scalars['ID'];
  startAt: Scalars['Timestamp'];
  endAt: Scalars['Timestamp'];
};

export type VisitClockDetails = {
  clockedAt: Scalars['Timestamp'];
  method: VisitClockMethod;
};

export enum VisitClockMethod {
  MobileApp = 'MOBILE_APP',
  Ivr = 'IVR',
  SystemAutoClock = 'SYSTEM_AUTO_CLOCK',
  Unknown = 'UNKNOWN'
}

export type VisitMilestoneGoal = {
  __typename?: 'VisitMilestoneGoal';
  type: Scalars['String'];
  visitNumber: Scalars['Int'];
};

export enum VisitRemovalReason {
  Cancelled = 'CANCELLED'
}

export enum VistResult {
  Confirmed = 'CONFIRMED',
  MissedByCaregiver = 'MISSED_BY_CAREGIVER'
}


export type WatchVideoGoalDetails = {
  __typename?: 'WatchVideoGoalDetails';
  /**
   * The ID of the video that should be completed to achieve this goal.
   *
   * Note: this attribute should always be present on available video
   * goals, but is nullable in case of configuration error.
   * In the case when the video ID is missing there should be an error
   * raised on the front-end.
   */
  videoId?: Maybe<Scalars['ID']>;
};

export type WatchVideoGoalTracker = {
  __typename?: 'WatchVideoGoalTracker';
  id: Scalars['ID'];
  videoType: WatchVideoGoalTrackerVideoType;
};

export enum WatchVideoGoalTrackerVideoType {
  WelcomeVideo = 'WELCOME_VIDEO'
}

export type WeekendShiftGoalTracker = {
  __typename?: 'WeekendShiftGoalTracker';
  id: Scalars['ID'];
  trackedVisit?: Maybe<WeekendShiftGoalTrackerVisitDetail>;
};

export type WeekendShiftGoalTrackerVisitDetail = {
  __typename?: 'WeekendShiftGoalTrackerVisitDetail';
  id: Scalars['ID'];
  rangeStart: Scalars['Timestamp'];
  rangeEnd: Scalars['Timestamp'];
};
